// import {lazy} from 'react';

export const UsersListRoute =
    {
        exact: true,
        path: '/users/list',
        //   component: lazy(() => import('../../views/'))
    };

export const UsersCreateRoute =
    {
        exact: true,
        path: '/users/create',
        //   component: lazy(() => import('../../views/'))
    };
export const UsersViewRoute =
    {
        exact: true,
        path: '/users/:id',
        //   component: lazy(() => import('../../views/'))
    };
export const UsersEditRoute =
    {
        exact: true,
        path: '/users/edit/:id',
        //   component: lazy(() => import('../../views/'))
    };


export default {};
