import React, {createRef} from 'react';

import "./textfield.css"

const TextfieldNumberSPF = (props) => {

    const refTextfield = createRef();

    function EventChange(refPassed, value) {
        // console.log("my vall " +  value)
        props.functionSetValeurs(props.index, value) // launch handleChange in parent simu
    }

    return (
        <input type={props.type} className={"input_field"} defaultValue="0" ref={refTextfield} placeholder={props.placeholder}
               onChange={((e) => {
                   EventChange(refTextfield, e.target.value)
                   //props.editable ? EventChange(refTextfield, e.target.value) : <></>
               })}
        />

    );
};

export default TextfieldNumberSPF;
