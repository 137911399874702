import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import SelectCommon from "../../../components/selects/select.common";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// simulateur Id 71

function Simulateur_ASF12(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [codicilles, setCodicilles] = useState(0);
  const [nombre_codicilles, setNombreCodicilles] = useState(0);
  const [nombre_copie_authentique, setCopieAuthentique] = useState(0);
  const [nombre_copie_libre, setCopieLibre] = useState(0);
  const [nombre_archivage, setArchivage] = useState(0);
  const [envoi_possession, setEnvoiPossession] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { codicilles: parseInt(codicilles) });
        Object.assign(object["inputs"], { nombre_codicilles: parseInt(nombre_codicilles) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { envoi_possession: parseInt(envoi_possession) });
        object.departementid = parseInt(value);
      }

      if (setter === setCodicilles) {
        Object.assign(object["inputs"], { codicilles: parseInt(value) });
        Object.assign(object["inputs"], { nombre_codicilles: parseInt(nombre_codicilles) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { envoi_possession: parseInt(envoi_possession) });
        object.departementid = parseInt(departement);
      }

      if (setter === setNombreCodicilles) {
        Object.assign(object["inputs"], { codicilles: parseInt(codicilles) });
        Object.assign(object["inputs"], { nombre_codicilles: parseInt(value) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { envoi_possession: parseInt(envoi_possession) });
        object.departementid = parseInt(departement);
      }

      if (setter === setCopieAuthentique) {
        Object.assign(object["inputs"], { codicilles: parseInt(codicilles) });
        Object.assign(object["inputs"], { nombre_codicilles: parseInt(nombre_codicilles) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(value) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { envoi_possession: parseInt(envoi_possession) });
        object.departementid = parseInt(departement);
      }

      if (setter === setCopieLibre) {
        Object.assign(object["inputs"], { codicilles: parseInt(codicilles) });
        Object.assign(object["inputs"], { nombre_codicilles: parseInt(nombre_codicilles) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(value) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { envoi_possession: parseInt(envoi_possession) });
        object.departementid = parseInt(departement);
      }

      if (setter === setArchivage) {
        Object.assign(object["inputs"], { codicilles: parseInt(codicilles) });
        Object.assign(object["inputs"], { nombre_codicilles: parseInt(nombre_codicilles) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(value) });
        Object.assign(object["inputs"], { envoi_possession: parseInt(envoi_possession) });
        object.departementid = parseInt(departement);
      }

      if (setter === setEnvoiPossession) {
        Object.assign(object["inputs"], { codicilles: parseInt(codicilles) });
        Object.assign(object["inputs"], { nombre_codicilles: parseInt(nombre_codicilles) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { envoi_possession: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div className="cardSimulateur">
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 5 (Art A444-60)</p>

        {props?.isParent === true && props.departements !== "" ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            En plus du testament, y a-t-il eu un ou plusieurs codicille(s) déposé(s) conservé(s) à l’étude ?
          </div>
          <ButtonYesno handleChange={handleChange} setter={setCodicilles} defaultValue={0} />
        </div>

        {parseInt(codicilles) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Combien ont-été déposés ?</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le nombre"}
              setter={setNombreCodicilles}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Nombre de page(s) « copie authentique » ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre de pages"}
            setter={setCopieAuthentique}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Nombre de page(s) « copie libre » ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre de pages"}
            setter={setCopieLibre}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Nombre de page(s) « archivage numérisé » ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre de pages"}
            setter={setArchivage}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>L’envoi en possession est-il nécessaire ?</div>
          <ButtonYesno handleChange={handleChange} setter={setEnvoiPossession} defaultValue={0} />
        </div>
      </div>
    </div>
  );
}

export default Simulateur_ASF12;
