import React, {createRef, useEffect, useRef, useState} from 'react';
import './button.css'
import {findDOMNode} from "react-dom";
import useIsMountedRef from "../../utils/mountedRef";


const ButtonTVA = (props) => {

    const isMountedRef = useIsMountedRef();

    const refYes = createRef();
    const refNo = createRef();
    const refUndefined = createRef();
    let [currentRef, setCurrentRef] = useState(null)

    function EventClick(refPassed, value) {

        refPassed.current.classList.remove("item_no_selected");

        if (refPassed !== refYes) {
            refYes.current.classList.remove('item_selected')
            refYes.current.classList.add('item_no_selected')
        }

        if (refPassed !== refNo) {
            refNo.current.classList.remove('item_selected')
            refNo.current.classList.add('item_no_selected')
        }

        if (props.fieldUndefined && refPassed !== refUndefined) {
            refUndefined.current.classList.remove('item_selected')
            refUndefined.current.classList.add('item_no_selected')
        }

        refPassed.current.classList.add('item_selected')

        setCurrentRef(refPassed);
        if (value === 0){
            props.handleChange(props.setter, 0.055)
        } else if (value === 1) {
            props.handleChange(props.setter, 0.2)
        }
         // launch handleChange in parent simu
    }


    useEffect(() => {
        switch (props.defaultValue) {
            case 1:
                EventClick(refYes, 1);
                break;
            case 0:
                EventClick(refNo, 0);
                break;
            default:
                break;
        }
    }, [isMountedRef]);

    return (
        <div className={"item_ctn"}>
            <div className={"droitpartage item_no_selected"} ref={refYes} onClick={((e) => {
                EventClick(refYes, 1)
            })}
            >
                20%
            </div>

            <div className={"droitpartage item_no_selected"} ref={refNo} onClick={((e) => {
                EventClick(refNo, 0)
            })}>
                5,5%
            </div>
            
        </div>
    );
};

export default ButtonTVA;
