import React, { useEffect, useState } from "react";
import "./breadcrumb.css";

const Breadcrumb = () => {
  const [setCurrentHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentHash(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [setCurrentHash]);

  let test = window.location.href.split("/");

  test.reverse();
  test.pop();
  test.pop();
  test.pop();
  test.reverse();

  let latest = [];
  for (let i = 0; i < test.length; i++) {
    const cleanPart = test[i].split("#")[0];
    if (isNumeric(cleanPart)) {
      latest[latest.length - 1] += " " + cleanPart;
    } else {
      latest.push(cleanPart);
    }
  }

  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function isValid(string) {
    if (string.length > 1) return string;
  }

  return (
    <div className={"containerBreadcrumb"}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21.826" viewBox="0 0 16.369 21.826">
        <path
          id="Icon_awesome-map-marker-alt"
          data-name="Icon awesome-map-marker-alt"
          d="M7.343,21.385C1.15,12.406,0,11.485,0,8.185a8.185,8.185,0,0,1,16.369,0c0,3.3-1.15,4.221-7.343,13.2a1.024,1.024,0,0,1-1.682,0Zm.841-9.79a3.41,3.41,0,1,0-3.41-3.41A3.41,3.41,0,0,0,8.185,11.595Z"
          fill="#ffffff"
        />
      </svg>
      <div className="testBreadcrumb">
        <div className="home">
          <a href={`/`} className="urlLinkHome">
            Accueil
          </a>
        </div>

        {isValid(latest[0]) ? (
          <>
            <div className={"slash"}>/</div>

            {window.location.pathname === "/moncompte" ? (
              <div>Mon Compte</div>
            ) : (
              <div className={"home"}>
                {latest[0] === "fiches" ? (
                  <a href={"/fiches"} className="urlLink">
                    <div>Fiches Pratiques</div>
                  </a>
                ) : latest[0] === "categorie" ? (
                  latest[1].includes("#") ? (
                    <a href={"/categorie/" + latest[1].substring(0, latest[1].indexOf("#"))} className="urlLink">
                      {latest[1].substring(0, latest[1].indexOf("#")) === "prets" ? (
                        <div>Prêts, garanties et mainlevées</div>
                      ) : (
                        capitalizeFirstLetter(latest[1].substring(0, latest[1].indexOf("#")))
                      )}
                    </a>
                  ) : (
                    <a href={"/categorie/" + latest[1]} className="urlLink">
                      {latest[1] === "prets" ? (
                        <div>Prêts, garanties et mainlevées</div>
                      ) : (
                        capitalizeFirstLetter(latest[1])
                      )}
                    </a>
                  )
                ) : latest[0] === "avis" ? (
                  <a href={"/avis"} className="urlLink">
                    <div>Avis d'Experts</div>
                  </a>
                ) : latest[0].includes("#") ? (
                  <a href={"/" + latest[0].substring(0, latest[0].indexOf("#"))} className="urlLink">
                    {capitalizeFirstLetter(latest[0].substring(0, latest[0].indexOf("#")))}
                  </a>
                ) : latest[0] === "pratiques" ? (
                  <a href={"/pratiques"} className="urlLink">
                    <div>Manuels PRATIQUES</div>
                  </a>
                ) : latest[0] === "SchemaFiscalite" ? (
                  <a href={"/SchemaFiscalite"} className="urlLink">
                    <div>La fiscalité immobilière</div>
                  </a>
                ) : latest[0] === "AnalysePatrimoniale" ? (
                  <a href={"/AnalysePatrimoniale"} className="urlLink">
                    <div>Analyse patrimoniale</div>
                  </a>
                ) : latest[0] === "tarif_des_notaires" ? (
                  <a href={"/tarif_des_notaires"} className="urlLink">
                    <div>L’essentiel de la taxe des actes</div>
                  </a>
                ) : (
                  <a href={"/" + latest[0]} className="urlLink">
                    {capitalizeFirstLetter(latest[0])}
                  </a>
                )}
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
