import React, { useState, useEffect } from "react";
import "./essentiel.css";
import FiscaModal from "./modal.fisca";

const groupByNActe = (items) => {
  return items.reduce((acc, item) => {
    const key = item.n_acte;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
};

const FISCA = ({ selectedFiscaItems }) => {
  const [showModal, setShowModal] = useState(false);
  const [groupedItems, setGroupedItems] = useState({});

  useEffect(() => {
    const grouped = groupByNActe(selectedFiscaItems);
    setGroupedItems(grouped);
  }, [selectedFiscaItems]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const sanitizeHTML = (htmlString) => {
    // Remplacer les espaces standards avant % et € par des espaces insécables
    return htmlString.replace(/(\d+)\s*(%|€)/g, "$1\u00A0$2");
  };

  return (
    <div className="emol_global_card">
      {Object.keys(groupedItems).map((n_acte) => {
        const items = groupedItems[n_acte];

        // console.log(items);
        return (
          <div key={n_acte}>
            {/* Titre commun pour chaque groupe de 'n_acte' */}
            <div className="emol_global_title">
              <span dangerouslySetInnerHTML={{ __html: items[0].actes_mef }} />
            </div>

            {/* Boucle pour afficher chaque item avec une information et un tableau distincts */}
            {items.map((item, index) => {

              return (
                <div key={index}>
                  {/* Information spécifique */}
                  {item.information && <span className="fisca_info">{item.information}</span>}

                  {/* Tableau spécifique pour cet item */}
                  <div className="emol_prop_tab">
                    <table className="emol_prop_table">
                      <thead className="emol_fisca_thead">
                        <tr className="emol_prop_tr">
                          <th className="emol_prop_th" scope="col">
                            Droit d'enregistrement
                          </th>
                          <th className="emol_prop_th" scope="col">
                            TPF
                          </th>
                          <th className="emol_prop_th" scope="col">
                            CSI
                          </th>
                        </tr>
                      </thead>
                      <tbody className="emol_prop_tbody">
                        <tr className="emol_prop_tr">
                          {item.formalite_fusionnee ? (
                            <>
                              <td className="fisca_td" colSpan={2}>
                                Formalité fusionnée <br />
                                {item.form_fusionnee_mef ? (
                                  <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.form_fusionnee_mef) }} />
                                ) : (
                                  <>
                                    {item.formalite_fusionnee && !isNaN(parseFloat(item.formalite_fusionnee))
                                      ? `${(parseFloat(item.formalite_fusionnee) * 100).toFixed(5)}\u00A0%`
                                      : item.formalite_fusionnee.replace(/(\d+)\s*(%|€)/g, "$1\u00A0$2")}
                                  </>
                                )}
                              </td>
                              <td className="fisca_td no-border-right">
                                {item.csi_mef ? (
                                  <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.csi_mef) }} />
                                ) : (
                                  <>
                                    {item.csi && !isNaN(parseFloat(item.csi))
                                      ? `${(parseFloat(item.csi) * 100).toFixed(2)}\u00A0%`
                                      : item.csi.replace(/(\d+)\s*(%|€)/g, "$1\u00A0$2")}
                                  </>
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                                <td className="fisca_td" rowSpan={2}>
                                  {item.droit_enr_mef ? (
                                    <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.droit_enr_mef) }} />
                                  ) : (
                                    <>
                                      {item.droit_enregistrement && !isNaN(parseFloat(item.droit_enregistrement))
                                        ? `${(parseFloat(item.droit_enregistrement) * 100).toFixed(5)}\u00A0%`
                                        : item.droit_enregistrement.replace(/(\d+)\s*(%|€)/g, "$1\u00A0$2")}
                                    </>
                                  )}
                                </td>


                              {/* TPF */}
                              <td className="fisca_td">
                                {item.tpf_mef ? (
                                  <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.tpf_mef) }} />
                                ) : (
                                  <>
                                    {item.tpf && /^-?\d+(\.\d+)?$/.test(item.tpf.trim())
                                      ? `${(parseFloat(item.tpf) * 100).toFixed(5)}\u00A0%`
                                      : item.tpf.replace(/(\d+)\s*(%|€)/g, "$1\u00A0$2")}
                                  </>
                                )}
                              </td>

                              {/* CSI */}
                              <td className="fisca_td no-border-right">
                                {item.csi_mef ? (
                                  <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.csi_mef) }} />
                                ) : (
                                  <>
                                    {item.csi && /^-?\d+(\.\d+)?$/.test(item.csi.trim())
                                      ? `${(parseFloat(item.csi) * 100).toFixed(2)}\u00A0%`
                                      : item.csi.replace(/(\d+)\s*(%|€)/g, "$1\u00A0$2")}
                                  </>
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* Loupe pour plus d'informations */}
                  {item.loupe && (
                    <div className="fisca_loupe">
                      Pour en savoir plus
                      <svg
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 99.97 100.02"
                        width="30"
                        height="39.9"
                        onClick={handleOpenModal}
                        fill="#306a36"
                      >
                        <path d="M11.1,11.1c14.8-14.8,38.8-14.8,53.6,0s14.8,38.8,0,53.6-38.8,14.8-53.6,0S-3.7,25.9,11.1,11.1ZM15.3,60.5c12.5,12.5,32.7,12.5,45.2,0,12.5-12.5,12.5-32.7,0-45.2S27.8,2.8,15.3,15.3s-12.4,32.7,0,45.2Z" />
                        <rect
                          x="75.62"
                          y="61.73"
                          width="12"
                          height="39.9"
                          transform="translate(-33.85 81.64) rotate(-45)"
                        />
                      </svg>
                      {/* Afficher la modal */}
                      <FiscaModal show={showModal} handleClose={handleCloseModal} content={item.loupe} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default FISCA;
