import produce from 'immer';

import {
    UPDATESIMULATEUROBJECT
} from '../actions/simulateur.action';

const initialState = {};

const SimulateurReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATESIMULATEUROBJECT: {
            return {
                ...state,
                inputs: action.payload.inputs,
                simulateurid: action.payload.simulateurid,
                departementid: action.payload.departementid,
                dependances: action.payload.dependances,
                DMTG: action.payload.DMTG
            };
        }

        default: {
            return state;
        }
    }
};

export default SimulateurReducer;
