import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import fontRobotoRegular from "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import fontRobotoLight from "../../assets/fonts/Roboto/Roboto-Light.ttf";
import fontRobotoBold from "../../assets/fonts/Roboto/Roboto-Bold.ttf";
import fontRobotoMedium from "../../assets/fonts/Roboto/Roboto-Medium.ttf";
import fontRobotoItalic from "../../assets/fonts/Roboto/Roboto-MediumItalic.ttf";

Font.register({ family: "RobotoRegular", src: fontRobotoRegular });
Font.register({ family: "RobotoLight", src: fontRobotoLight });
Font.register({ family: "RobotoMedium", src: fontRobotoMedium });
Font.register({ family: "RobotoBold", src: fontRobotoBold });
Font.register({ family: "RobotoItalic", src: fontRobotoItalic });
Font.registerHyphenationCallback((word) => [word]);

let newDate = new Date();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let day = newDate.getDate();

if (month < 10) {
  month = "0" + month;
}

if (day < 10) {
  day = "0" + day;
}
const currentDate = `${day}/${month}/${year}`;

// Styles pour le document PDF
const styles = StyleSheet.create({
  bodyFooterCtn: {
    width: "100%",
    marginLeft: "7%",
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    objectPosition: "bottom",
  },
  bodyFooterLight: {
    fontFamily: "RobotoLight",
    fontSize: 6,
    marginTop: 5,
  },
  bodyFooterMedium: {
    fontFamily: "RobotoMedium",
    fontSize: 8,
    marginTop: 10,
    textAlign: "justify",
  },
  choice: {
    fontFamily: "RobotoRegular",
    fontSize: 12,
    color: "#297F89",
  },
  headerAdress: {
    fontSize: 10,
    fontFamily: "RobotoRegular",
  },
  headerDate: {
    marginTop: 10,
    fontSize: 9.5,
    fontFamily: "RobotoRegular",
  },
  headerRight: {
    marginLeft: "50%",
    marginTop: 5,
    fontSize: 12,
    textAlign: "right",
    lineHeight: 1.5,
    wordBreak: "keep-all",
  },
  headerName: {
    fontSize: 11,
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  page: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 40,
    paddingRight: 40,
    marginLeft: "auto",
    marginRight: "auto",
  },
  question: {
    fontFamily: "RobotoBold",
    fontSize: 12,
  },
  refSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignContent: "space-around",
    alignItems: "flex-start",
    gap: 20,
  },
  ref1: {
    width: "40%",
    padding: 20,
    color: "#ffffff",
    backgroundColor: "#3990A3",
    borderRadius: 9,
    fontSize: 12,
    textAlign: "center",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    padding: 5,
  },
  sectionResult: {
    display: "flex",
    flexDirection: "column",
  },
  sectionResult1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    padding: 5,
  },
  sectionResult1last: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
  },
  subTitle: {
    textTransform: "uppercase",
    marginBottom: 20,
    fontSize: 12,
    fontFamily: "RobotoBold",
  },
  tableColumnL: {
    width: "40%",
    padding: 5,
    fontSize: 12,
  },
  tableColumnR: {
    width: "60%",
    padding: 5,
    fontSize: 12,
  },
  tableauRecap: {
    marginBottom: 50,
    borderWidth: 1,
    borderColor: "#ccc",
    borderStyle: "solid",
    borderRadius: 5,
    padding: 10,
  },
  title: {
    fontSize: 13,
    textAlign: "center",
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
    position: "relative",
    marginTop: 40,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 40,
    width: "100%",
    maxHeight: 50,
    overflow: "hidden",
  },
});

const ProcessPDF = ({ title, selectedChoices, result }) => {
  let currUser = JSON.parse(localStorage.getItem("user"));

  const formatTextWithLineBreaks = (text) => {
    return text.split(/<br\s*\/?>/gi).map((line, index) => (
      <Text key={index} style={styles.choice}>
        {line}
      </Text>
    ));  };

  const renderResult = (resultObj) => {
    const resultTitles = [resultObj.resultTitle1, resultObj.resultTitle2, resultObj.resultTitle3];
    const resultValues = [resultObj.result1, resultObj.result2, (resultObj.result3 + "\n" + resultObj.ref1)];
    return resultTitles.map(
      (title, index) =>
        title && (
          <View
            key={index}
            style={index === resultTitles.length - 1 ? styles.sectionResult1last : styles.sectionResult1} // Pas de bordure pour le dernier
          >
            <View style={styles.tableColumnL}>
              <Text style={styles.question}>{title}</Text>
            </View>
            <View style={styles.tableColumnR}>
              {resultValues[index] ? (
                <View>
                  {formatTextWithLineBreaks(resultValues[index])} {/* Applique les sauts de ligne */}
                </View>
              ) : (
                <Text style={styles.choice}>{resultValues[index]}</Text>
              )}
            </View>
          </View>
        )
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* En-tête */}
        <View style={styles.headerRight}>
          <Text style={styles.headerName}>{currUser.societe}</Text>
          <Text style={styles.headerAdress}>{currUser.societe_address}</Text>
          <Text style={styles.headerAdress}>{currUser.societe_zip + " " + currUser.societe_town}</Text>
          <Text style={styles.headerDate}>Dossier suivi par : {currUser.firstname + " " + currUser.lastname}</Text>
          <Text style={styles.headerDate}>Le : {currentDate}</Text>
        </View>

        {/* Titre */}
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subTitle}>Synthèse récapitulative de vos choix :</Text>

        {/* Tableau récapitulatif */}
        <View style={styles.tableauRecap}>
          {selectedChoices.map((choiceObj, index) => (
            <View key={index} style={styles.section}>
              <View style={styles.tableColumnL}>
                <Text style={styles.question}>{choiceObj.question.questionPdf}</Text>
              </View>
              <View style={styles.tableColumnR}>
                <Text style={styles.choice}>{choiceObj.choice.choice}</Text>
              </View>
            </View>
          ))}

          {/* Résultats dynamiques */}
          {result && renderResult(result)}
        </View>

        {/* Références */}
        {/* {result && (
          <>
            <Text style={styles.subTitle}>Références :</Text>
            <View style={styles.refSection}>
              {result.ref1 && (
                <Text style={styles.ref1}>
                  {result.refTitle1} {"\n"} {"\n"} {formatTextWithLineBreaks(result.ref1)}
                </Text>
              )}
              {result.ref2 && (
                <Text style={styles.ref1}>
                  {result.refTitle2} {"\n"} {"\n"} {formatTextWithLineBreaks(result.ref2)}
                </Text>
              )}
              {result.ref3 && (
                <Text style={styles.ref1}>
                  {result.refTitle3} {"\n"} {"\n"} {formatTextWithLineBreaks(result.ref3)}
                </Text>
              )}
            </View>
          </>
        )} */}

        {/* Pied de page */}
        <View style={styles.bodyFooterCtn} fixed>
          <Text style={styles.bodyFooterMedium}>
            IMPORTANT : Les informations et les résultats présentés ci-dessus sont donnés à titre indicatif et ne
            peuvent en aucun cas constituer un document à caractère contractuel.
          </Text>
          <Text style={styles.bodyFooterLight}>Sources : Éditions Langloÿs - Le Barème Rapide® {year}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ProcessPDF;
