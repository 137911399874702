import React, {createRef, useEffect, useRef, useState} from 'react';
import './button.css'
import {findDOMNode} from "react-dom";
import useIsMountedRef from "../../utils/mountedRef";


// example of using the component: <ButtonYesno handleChange={handleChange} setter={setActeEnMain} onlyTrueFalse={true}/>

const Button12 = (props) => {

    const isMountedRef = useIsMountedRef();

    const ref1 = createRef();
    const ref2 = createRef();
    let [currentRef, setCurrentRef] = useState(null)

    function EventClick(refPassed, value) {

        refPassed.current.classList.remove("item_no_selected");

        if (refPassed !== ref1) {
            ref1.current.classList.remove('item_selected')
            ref1.current.classList.add('item_no_selected')
        }

        if (refPassed !== ref2) {
            ref2.current.classList.remove('item_selected')
            ref2.current.classList.add('item_no_selected')
        }

        refPassed.current.classList.add('item_selected')

        setCurrentRef(refPassed);
        props.handleChange(props.setter, value) // launch handleChange in parent simu
    }


    useEffect(() => {
        switch (props.defaultValue) {
            case 2:
                EventClick(ref2, 2);
                break;
            case 1:
                EventClick(ref1, 1);
                break;
            default:
                break;
        }
    }, [isMountedRef]);

    return (
        <div className={"item_ctn"}>
            <div className={"droitpartage item_no_selected"} ref={ref1} onClick={((e) => {
                EventClick(ref1, 1)
            })}
            >
                Un donateur
            </div>

            <div className={"droitpartage item_no_selected"} ref={ref2} onClick={((e) => {
                EventClick(ref2, 2)
            })}>
                Deux donateurs
            </div>
        </div>
    );
};

export default Button12;
