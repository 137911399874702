import React from "react";
import logo from "../../assets/images/logo_blanc.png";
import "./header.css";

const Header = () => {
  return (
    <div className={"containerMain"}>
      <div className={"overlayStyle"}>
        <div className={"containerHeader"}>
          <div className={"containerImage"}>
            <a href="/">
              <img src={`${logo}`} className={"image"} alt="logoEDL" />
            </a>
          </div>

          <div className={"containerTitle"}>
            <div className={"containerTitles"}>
              <h1 className={"title"}>Le Barème Rapide® Langloÿs</h1>
              <h2 className={"title2"}>Partenaire historique des notaires depuis 1956</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
