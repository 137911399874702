import React, { useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Partage partiel société construction
// Id simulateur 80

function Simulateur_80(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [valeur_biens, setBiens] = useState(0);
  const [nombre_part_partage, setPartage] = useState(0);
  const [nombre_parts_capital, setCapital] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { valeur_biens: parseInt(valeur_biens) });
        Object.assign(object["inputs"], { nombre_part_partage: parseInt(nombre_part_partage) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        object.departementid = parseInt(value);
      }

      if (setter === setBiens) {
        Object.assign(object["inputs"], { valeur_biens: parseInt(value) });
        Object.assign(object["inputs"], { nombre_part_partage: parseInt(nombre_part_partage) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        object.departementid = parseInt(departement);
      }

      if (setter === setPartage) {
        Object.assign(object["inputs"], { valeur_biens: parseInt(valeur_biens) });
        Object.assign(object["inputs"], { nombre_part_partage: parseInt(value) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        object.departementid = parseInt(departement);
      }

      if (setter === setCapital) {
        Object.assign(object["inputs"], { valeur_biens: parseInt(valeur_biens) });
        Object.assign(object["inputs"], { nombre_part_partage: parseInt(nombre_part_partage) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div className="cardSimulateur">
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>
          Acte tarifé au titre du Tableau 5, n° 100 (Art A444-120). Le montant des émoluments supportés par chaque
          copartageant sortant de la société ne pourra être supérieur au montant de ceux dont il aurait été redevable si
          le partage avait été total.
        </p>

        {props?.isParent === true && props.departements !== "" ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Quelle est la valeur totale des biens immobiliers objet du partage partiel de société construction ?
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer la valeur"}
            setter={setBiens}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Combien de parts sociales font l’objet du partage partiel ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre"}
            setter={setPartage}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Quel est le nombre total de parts sociales composant le capital social initial de la société de construction
            ?
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre"}
            setter={setCapital}
          />
        </div>
      </div>
    </div>
  );
}

export default Simulateur_80;
