import React, { useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import SelectCommon from "../../../components/selects/select.common";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Négociation immobilière
// Id simulateur 42

function Simulateur_42(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    const currentIdSimu = 42
    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [type_calcul, setCalcul] = useState(0)
    const [montant_forfait, setForfait] = useState(0)
    const [montant_demande, setDemande] = useState(0)
    const [prix_vente, setVente] = useState(0)
    const [taux_remuneration, setRemuneration] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "inputs": {}
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'type_calcul': parseInt(type_calcul) })
                Object.assign(object['inputs'], { 'montant_forfait': parseInt(montant_forfait) })
                Object.assign(object['inputs'], { 'montant_demande': parseInt(montant_demande) })
                Object.assign(object['inputs'], { 'prix_vente': parseInt(prix_vente) })
                Object.assign(object['inputs'], { 'taux_remuneration': parseFloat(taux_remuneration) })
                object.departementid = parseInt(value)
            }

            if (setter === setCalcul) {
                Object.assign(object['inputs'], { 'type_calcul': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_forfait': parseInt(montant_forfait) })
                Object.assign(object['inputs'], { 'montant_demande': parseInt(montant_demande) })
                Object.assign(object['inputs'], { 'prix_vente': parseInt(prix_vente) })
                Object.assign(object['inputs'], { 'taux_remuneration': parseFloat(taux_remuneration) })
                object.departementid = parseInt(departement)
            }

            if (setter === setForfait) {
                Object.assign(object['inputs'], { 'type_calcul': parseInt(type_calcul) })
                Object.assign(object['inputs'], { 'montant_forfait': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_demande': parseInt(montant_demande) })
                Object.assign(object['inputs'], { 'prix_vente': parseInt(prix_vente) })
                Object.assign(object['inputs'], { 'taux_remuneration': parseFloat(taux_remuneration) })
                object.departementid = parseInt(departement)
            }

            if (setter === setDemande) {
                Object.assign(object['inputs'], { 'type_calcul': parseInt(type_calcul) })
                Object.assign(object['inputs'], { 'montant_forfait': parseInt(montant_forfait) })
                Object.assign(object['inputs'], { 'montant_demande': parseInt(value) })
                Object.assign(object['inputs'], { 'prix_vente': parseInt(prix_vente) })
                Object.assign(object['inputs'], { 'taux_remuneration': parseFloat(taux_remuneration) })
                object.departementid = parseInt(departement)
            }

            if (setter === setVente) {
                Object.assign(object['inputs'], { 'type_calcul': parseInt(type_calcul) })
                Object.assign(object['inputs'], { 'montant_forfait': parseInt(montant_forfait) })
                Object.assign(object['inputs'], { 'montant_demande': parseInt(montant_demande) })
                Object.assign(object['inputs'], { 'prix_vente': parseInt(value) })
                Object.assign(object['inputs'], { 'taux_remuneration': parseFloat(taux_remuneration) })
                object.departementid = parseInt(departement)
            }

            if (setter === setRemuneration) {
                Object.assign(object['inputs'], { 'type_calcul': parseInt(type_calcul) })
                Object.assign(object['inputs'], { 'montant_forfait': parseInt(montant_forfait) })
                Object.assign(object['inputs'], { 'montant_demande': parseInt(montant_demande) })
                Object.assign(object['inputs'], { 'prix_vente': parseInt(prix_vente) })
                Object.assign(object['inputs'], { 'taux_remuneration': parseFloat(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    const baseCalcul = [
        {
            id: 0,
            name: "Depuis la base demandée par le vendeur"
        },
        {
            id: 1,
            name: "Depuis le prix comprenant les honoraires de négociation"
        }]

    return (
        <div style={styles.container}>
            <div className="cardSimulateur">
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                {props?.isParent === true && props.departements !== "" ?
                    <div className="cardRow">
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div className="cardRow">
                    <div style={styles.cardElemVerticalyCenter}>
                        Le calcul est-il :
                    </div>
                    <SelectCommon items={baseCalcul} defaultValue={0} nameField={"name"} optionValue={"id"}
                        handleChange={handleChange} setter={setCalcul} />
                </div>

                <div className="cardRow">
                    <div style={styles.cardElemVerticalyCenter}>
                        Le montant forfaitaire minimum est la rémunération minimale prévue en €
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setForfait}
                    />
                </div>

                <div className="cardRow">
                    {
                        type_calcul == 1 ? <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le prix de vente y compris les honoraires de négociation en €
                        </div>
                            : <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant demandé par le vendeur en €
                            </div>
                    }
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setDemande}
                    />
                </div>

                <div className="cardRow">
                    <div style={styles.cardElemVerticalyCenter}>
                        Indiquer la rémunération souhaitée en % TTC
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setRemuneration}
                    />
                </div>

            </div>

        </div>
    )
}

export default Simulateur_42;
