import React from 'react';
import Colors from "../../useclass/colors"


const TextBig = (props) => {
    const styles = {
        container: {
            marginLeft: "8%",
            marginRight: "8%",
            marginTop: 25,
            marginBottom: 10
        },
        containerText: {
            borderLeft: `solid 2px ${Colors.primaryBlue}`,
            height: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

        },
        title: {
            color: Colors.primaryBlue,
            fontSize: 26,
            paddingLeft: 30
        },
        subtitle: {
            color: Colors.black,
            fontSize: 22,
            paddingLeft: 30
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.containerText}>
                <div style={styles.title}>
                    {props.title}
                </div>
                <div style={styles.subtitle}>
                    {props.subtitle}

                </div>
            </div>

        </div>
    );
};

export default TextBig;
