import React from 'react';
import Colors from "../../useclass/colors"


const TextSmall = (props) => {
    const styles = {
        container: {
            height: 100,
            marginLeft: "8%",
            marginRight: "8%",
            marginTop: 20
        },
        containerText: {
            borderLeft: `solid 2px ${Colors.primaryBlue}`,
            height: "30%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

        },
        text: {
            color: Colors.black,
            fontSize: 18,
            paddingLeft: 30,
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.containerText}>
                <div style={styles.text}>
                    {props.text}
                </div>
            </div>

        </div>
    );
};

export default TextSmall;
