import * as React from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
//import { faSpinner, faFile } from "@fortawesome/free-solid-svg-icons";

export interface PdfDocumentProps {
    title: string;
    document: JSX.Element;
}

const DocumentPDF: React.FC<PdfDocumentProps> = ({ title, document }) => {
    const { useState, useEffect } = React;
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setReady(true);
        }, 0);
    }, []);

    if (!ready) {
        return null;
    } else {
        return (
            <PDFDownloadLink document={document} fileName={title} >
                {({ url, loading, error }) => {
                    if (loading) {
                        return (
                            <span>
                            Génération en cours...
                            </span>
                        );
                    }
                    if (!loading && url) {
                        window.open(url, "_blank")
                        // ligne pour ouvrir le pdf comme image (utile en démo sur la tv)
                        // window.open('/FramedPDF/'+ encodeURIComponent(url)+'', "_blank")
                        return (
                            <span>
                            Votre document est prêt
                            </span>
                        );
                    }
                    if (error) {
                        // console.error(error);
                        return <p>Une erreur est survenue</p>;
                    }
                    return null;
                }}
            </PDFDownloadLink>
        );
    }
};

export default DocumentPDF;
