import React from 'react';
import Colors from "../../useclass/colors"
import ImgPc from "../../assets/images/2025_digital.png"

const CardHome = (props) => {
    const styles = {
        container: {
            width: "90%",
            height: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            backgroundColor: Colors.white,
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            borderRadius: 7,
        },
        containerItems: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            height: "100%",
            alignItems: "center"
        },
        containerImg: {
            width: 550
        },
        containerText: {
            flex: 1,
            minWidth: 180,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
        },
        imagePc: {
            width: "95%",
            marginLeft: "5%",
        },
        buttonDiscovery: {
            paddingTop: 10,
            paddingBottom: 10,
            width: "95%",
            marginBottom: 20,
            marginTop: 15,
            fontSize: 18,
            fontWeight: "bold",
            backgroundColor: "#0c5573",
            borderRadius: 50,
            color: Colors.white,
            border: "none",
            cursor: "pointer",
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
        },
        textNoClient: {
            fontSize: 24,
            marginBottom: 20
        },
        textDiscovery: {
            fontSize: 24,
            marginTop: 0,
            marginBottom: 15
        },
        textDiscoveryColor: {
            color: "#0c5573"
        },
        textDiscoveryBold: {
            fontWeight: "bold"
        },
        containerItemRight: {
            width: "70%",
            marginLeft: "auto",
            marginRight: "auto",
        }


    };

    return (
        <div style={styles.container}>
            <div style={styles.containerItems}>
                <div style={styles.containerImg}>
                    <img alt={"solution digitale"}
                         src={ImgPc}
                         style={styles.imagePc}
                    />
                </div>
                <div style={styles.containerText}>
                    <div style={styles.containerItemRight}>
                        <p style={styles.textNoClient}>
                            Vous n’êtes pas encore client ?
                        </p>
                    </div>
                    <div style={styles.containerItemRight}>
                        <p style={styles.textDiscovery}>
                           <span style={styles.textDiscoveryColor}>
                               Découvrez nos offres digitales
                           </span>
                        </p>
                    </div>
                    <div style={styles.containerItemRight}>
                        <button style={styles.buttonDiscovery}
                        onClick={() => window.open("https://editionslangloys.com/la-solution-digitale/", "_blank")}>
                            Je découvre
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardHome;
