import React, { useState, useEffect } from "react";
import "./lateralMenu.css";

const CollapseMenu = ({ title, icon, children, isParentCollapsed, isLateralMenuCollapsed }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (isParentCollapsed) {
      setIsCollapsed(true);
    }
  }, [isParentCollapsed]);

  return (
    <div className="collapsibleCard">
      <span className="collapsibleCardFlex">
      <div className="collapsibleCardIcon">{icon}</div>
      <div className="collapsibleCardHeader" onClick={toggleCollapse}>
        <div className={`collapsibleCardTitle ${isParentCollapsed ? "collapsed" : ""}`}>{title}</div>
        <div className={`collapsibleCardToggleIcon ${isParentCollapsed ? "collapsed" : ""}`}>
          {isCollapsed ? "▼" : "▲"}
        </div>
      </div>
      </span>
      {!isCollapsed && <div className="collapsibleCardBody">{children}</div>}
    </div>
  );
};

export default CollapseMenu;
