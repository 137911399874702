import React, {createRef, useEffect} from 'react';

import "./select.css"
import useIsMountedRef from "../../utils/mountedRef";

const SelectCommon = (props) => {

    const refSelect = createRef();
    const isMountedRef = useIsMountedRef();


    function EventChange(refPassed, value) {
        props.handleChange(props.setter, value) // launch handleChange in parent simu
    }

    useEffect(() => {
        EventChange(refSelect, props.defaultValue);
    }, [isMountedRef]);

    return (
        <select className={"select_custom"} defaultValue={props.defaultValue || 'Selection'} ref={refSelect} onChange={(e) => {
            EventChange(refSelect, e.target.value);
        }}>
            {/*<option></option>*/}
            {
                props.items.map((item) =>
                    <option value={item[props.optionValue]}>{item[props.nameField]}</option>
                )
            }
        </select>
    );
};

export default SelectCommon;
