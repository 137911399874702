import React from 'react';
import './essentiel.css'; 
import ReactDOM from 'react-dom';

const FiscaModal = ({ show, handleClose, content }) => {
  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal-backdrop" onClick={handleClose}>
      <div className="modal-content">
        <button className="close-btn" onClick={handleClose}>X</button>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>,
    document.body
  );
};

export default FiscaModal;
