export const Colors = {
    primaryBlue: '#5E91B6',
    white: '#FFF',
    backgroundWhite: '#F6F8FA',
    black: '#000000',
    lightGrey: '#E8E6E9',
    ultraLightGrey: '#F8F8F8',
    titleBlue: "#0D373B",
    textBlack: "#222529",
    buttonGreen: "#5cb85c"
}

export default Colors;
