import React, { useState } from "react";
import "./menuPatrimoine.css";
import LateralMenu from "../../layouts/lateralMenu/lateralMenu";

const lightenColor = (hex, percent) => {
  let num = parseInt(hex.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

const blocksData = [
  {
    title: "Droit patrimonial de la famille",
    color: "#287f89",
    collapses: [
      {
        letter: "A",
        title: "Régimes matrimoniaux et PACS",
        questions: [
          {
            question:
              "Quelles sont les conséquences du dénouement par décès d’un contrat d’assurance-vie sur la liquidation de la communauté ?",
          },
          {
            question:
              "Changement de régime matrimonial : dans quels cas est-il impératif de liquider le régime matrimonial au préalable ?",
          },
          { question: "Régime matrimonial du chef d’entreprise : quelles clauses prévoir impérativement ?" },
          {
            question: "Régimes matrimoniaux internationaux : quelle est la loi applicable au régime matrimonial ?",
          },
        ],
      },
      {
        letter: "B",
        title: "Successions et libéralités",
        questions: [
          { question: "Comparatif entre donation entre époux et testament" },
          {
            question:
              "Donation entre époux de biens à venir ou testament : comment optimiser la faculté de cantonnement ?",
          },
          { question: "Comment léguer un droit d’usufruit à un partenaire de PACS ?" },
          {
            question: "Comment liquider une succession en présence d’un legs en usufruit au partenaire de Pacs ?",
          },
          {
            question: "Comment liquider une succession en présence d’un legs en usufruit au conjoint survivant ?",
          },
          { question: "Comment liquider une succession en présence d’un contrat d’assurance-vie non dénoué ?" },
          {
            question: "Donation d’un bien commun : pouvoirs des époux, récompenses, rapport et réunion fictive",
          },
          { question: "Organiser la mise à disposition gratuite d’un logement à un proche" },
          { question: "Organiser l’aide financière d’un proche" },
          { question: "Adoption : la fiscalité en ligne directe est-elle applicable à mon cas ?" },
        ],
      },
      {
        letter: "C",
        title: "Personnes vulnérables",
        questions: [
          {
            question:
              "Enfant mineur : l’opération projetée (vente, donation…) nécessite-t-elle l’accord préalable du juge ?",
          },
          {
            question:
              "Majeur vulnérable et bien immobilier : l’opération projetée (vente, donation…) nécessite-t-elle l’accord préalable du juge ?",
          },
          {
            question:
              "Majeur vulnérable et placement immobilier : l’opération projetée (vente, donation…) nécessite-t-elle l’accord préalable du juge ?",
          },
          {
            question:
              "Enfant mineur et bien immobilier : l’opération projetée (vente, donation…) nécessite-t-elle l’accord des deux parents ou d’un seul ?",
          },
          {
            question:
              "Enfant mineur et placement financier : l’opération projetée (rachat, arbitrage…) nécessite-t-elle l’accord des deux parents ou d’un seul ?",
          },
          {
            question:
              "Enfant mineur et société : l’opération projetée (apport, cession…) nécessite-t-elle l’accord préalable du juge ?",
          },
        ],
      },
    ],
  },
  {
    title: "Démembrement de propriété",
    color: "#207180",
    collapses: [
      {
        title: "Usufruit et nue-propriété",
        questions: [
          {
            question:
              "Quasi-usufruit : la dette de restitution est-elle déductible fiscalement de la succession du quasi-usufruitier ?",
          },
          { question: "L’article 13, 5 du CGI est-il applicable à l’aliénation du droit démembré envisagée ?" },
          {
            question: "Quelles sont les modalités d’imposition en cas de cession de droits sociaux démembrés ?",
          },
          {
            question:
              "Mettre fin à un démembrement de propriété : échange, dation en paiement ou cession de droits successifs ?",
          },
          { question: "Vente de nue-propriété : entre-t-on dans le champ de l’article 918 du CGI ?" },
          {
            question: "L’article 751 du CGI est-il applicable à l’opération projetée sur des droits démembrés ?",
          },
          { question: "Quelle évaluation de l’usufruit utiliser : économique ou fiscale ?" },
          { question: "Comment mettre en place un usufruit successif en cas de démembrement préexistant ?" },
          { question: "Usufruit successif : quelles clauses prévoir dans l’acte constitutif ?" },
          { question: "Usufruit successif : quelles conséquences en cas de vente du bien démembré ?" },
        ],
      },
    ],
  },
  {
    title: "Droit de l’indivision",
    color: "#186377",
    collapses: [
      {
        title: "Indivision et gestion des biens",
        questions: [
          { question: "Partage, licitation ou vente en cas d’indivision portant sur un bien unique", id: 1 },
          { question: "Divorce : quand réaliser la vente d’un bien immobilier indivis ?" },
          {
            question:
              "Divorce et séparation : quelle temporalité respecter pour bénéficier d’un droit de partage au taux réduit ?",
          },
          { question: "Comment vendre un bien indivis en cas de blocage entre les indivisaires ?" },
        ],
      },
    ],
  },
  {
    title: "Immobilier",
    color: "#10566e",
    collapses: [
      {
        title: "Achat immobilier",
        questions: [
          { question: "La fiscalité immobilière, quel taux et quel droit appliquer ?", id: 3 },
          {
            question:
              "La société doit-elle vendre l’immeuble social ou les associés doivent-ils vendre les titres (analyse fiscale) ?",
          },
          { question: "Vente d’un immeuble par une SCI : pouvoirs du gérant ou des associés ?" },
          { question: "Vente d’un bien par une société : comment percevoir le prix de vente ?" },
          { question: "Faut-il constituer une SCI ou acquérir un immeuble en indivision ?" },
          { question: "Faut-il louer un immeuble en location nue ou en location meublée ?" },
          { question: "Location meublée : LMNP ou LMNP au titre de l’impôt sur le revenu ?" },
          { question: "Location meublée : en direct ou par l’intermédiaire d’une SARL de famille ?" },
          { question: "Location meublée : à titre de résidence principale ou en location saisonnière ?" },
          { question: "Choisir entre vente à soi-même (OBO) et apport à société" },
          {
            question: "Location meublée et SARL de famille : comment percevoir les revenus provenant de la location ?",
          },
          {
            question:
              "Location immobilière : quel est le régime fiscal applicable aux revenus perçus selon le type de location ?",
          },
        ],
      },
    ],
  },
  {
    title: "Placements",
    color: "#084865",
    collapses: [
      {
        title: "Placements financiers",
        questions: [
          { question: "Faut-il acquérir des parts de SCPI en direct ou par l’intermédiaire d’une SC ?" },
          {
            question: "Assurance-vie : comment transmettre des capitaux-décès dans le cadre d’une famille recomposée ?",
          },
          {
            question:
              "Assurance-vie : quelles sont les conséquences d’une souscription conjointe par deux époux communs en biens ?",
            id: 2,
          },
          {
            question:
              "Plan d’épargne retraite (PER) : quelles sont les conséquences sur le divorce du souscripteur commun en biens ?",
          },
          {
            question:
              "Plan d’épargne retraite (PER) : quelles sont les conséquences sur la succession du souscripteur ?",
          },
        ],
      },
    ],
  },
  {
    title: "Droit des affaires",
    color: "#003a5c",
    collapses: [
      {
        title: "Création d’entreprise",
        questions: [
          { question: "Faut-il voter la distribution de dividendes ou procéder à une réduction de capital ?" },
          { question: "Droits sociaux démembrés et vente d’immeuble" },
          {
            question: "Cession d’entreprise : faut-il céder les titres de société ou le fonds détenu par celle-ci ?",
          },
        ],
      },
    ],
  },
];

const MenuPatrimoine = () => {
  const [showCollapses, setShowCollapses] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [openCollapseIndexes, setOpenCollapseIndexes] = useState({});

  const handleBlockClick = (blockIndex) => {
    setSelectedBlock(blockIndex);
    setShowCollapses(true);
  };

  // const handleBackToMenu = () => {
  //   setShowCollapses(false);
  //   setSelectedBlock(null);
  //   setOpenCollapseIndexes({});
  // };

  const toggleCollapse = (index) => {
    setOpenCollapseIndexes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleQuestionClick = (id) => {
    window.location.href = `/AnalysePatrimoniale/ProcessPatrimoine/${id}`;
  };

  return (
    <div className="page">
      <LateralMenu />
      <div className="MenuPatrimoine-container">
        {!showCollapses && (
          <div className="MenuPatrimoine-menu">
            {blocksData.map((block, index) => (
              <div
                key={index}
                className="MenuPatrimoine-block"
                onClick={() => handleBlockClick(index)}
                style={{ backgroundColor: block.color }}
              >
                {block.title}
              </div>
            ))}
          </div>
        )}

        {showCollapses && selectedBlock !== null && (
          <>
            {/* <button onClick={handleBackToMenu} className="MenuPatrimoine-button-return">Retour au menu</button> */}
            <div className="MenuPatrimoine-collapse-page">
              <h1 style={{ color: blocksData[selectedBlock].color }}>{blocksData[selectedBlock].title}</h1>
              {blocksData[selectedBlock].collapses.map((collapse, index) => (
                <div key={index} className="MenuPatrimoine-collapse">
                  <div
                    className="MenuPatrimoine-collapse-header"
                    onClick={() => toggleCollapse(index)}
                    style={{ backgroundColor: blocksData[selectedBlock].color }}
                  >
                    <h2>
                      {collapse.letter ? (
                        <span className="MenuPatrimoine-collapse-letter">{collapse.letter}</span>
                      ) : null}
                      {collapse.title}
                    </h2>
                    <span className="MenuPatrimoine-chevron">{openCollapseIndexes[index] ? "▲" : "▼"}</span>
                  </div>
                  {openCollapseIndexes[index] && (
                    <ul
                      className="MenuPatrimoine-collapse2"
                      style={{
                        backgroundColor: lightenColor(blocksData[selectedBlock].color, 7),
                      }}
                    >
                      {collapse.questions.map((item, idx) => (
                        <li key={idx} onClick={() => handleQuestionClick(item.id)}>
                          {item.question}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MenuPatrimoine;
