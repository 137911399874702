import * as React from "react";
import PdfDocument from "./document.PDF";
import GeneratePdfFile from "./generate.PDF";
import GenerateMiniPdfFile from "./generate.mini.PDF";
import Colors from "../../useclass/colors";
import ReactGA from "react-ga4";

const LinkPDF = (props) => {
  const { useState } = React;
  const [ready, setReady] = useState(false);

  let requestSimu = JSON.parse(localStorage.getItem("requestSimu"));

  let simuSimple = [43, 44, 45, 46, 47, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75];

  const styles = {
    button: {
      width: "calc(100% + 10px)",
      height: 35,
      borderRadius: 5,
      fontSize: 15,
      border: "none",
      backgroundColor: Colors.buttonGreen,
      color: Colors.white,
      marginTop: 10,
      cursor: "pointer",
      minWidth: 200,
      padding: 5,
    },
  };

  return (
    <>
      {!props.ready ? (
        <button
          style={styles.button}
          onClick={(e) => {
            setReady(true);
            props.setReadyMain(true);
            ReactGA.event({
              category: "Result",
              action: "Clic sur télécharger synthèse",
              label: "Bouton télécharger synthèse",
            });
          }}
        >
          Télécharger votre synthèse
        </button>
      ) : (
        <></>
      )}

      {ready && props.ready ? (
        <PdfDocument
          title="Synthèse de votre simulation"
          fileName={"Synthèse"}
          document={
            simuSimple.includes(requestSimu?.simulateurid) ? (
              <GenerateMiniPdfFile simulateurObject={props.simulateurObject} />
            ) : (
              <GeneratePdfFile
                simulateurObject={props.simulateurObject}
                articles={props.articles}
                TblArticleValue={props.TblArticleValue}
                DepsArticles={props.DepsArticles}
              />
            )
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default LinkPDF;
