import React, { createRef, useEffect, useRef, useState } from "react";
import "./button.css";
import { findDOMNode } from "react-dom";
import useIsMountedRef from "../../utils/mountedRef";

// example of using the component: <ButtonYesno handleChange={handleChange} setter={setActeEnMain} onlyTrueFalse={true}/>

const ButtonPretsPro = (props) => {
  const isMountedRef = useIsMountedRef();

  const refYes = createRef();
  const refNo = createRef();
  let [currentRef, setCurrentRef] = useState(null);

  function EventClick(refPassed, value) {
    refPassed.current.classList.remove("item_no_selected");

    if (refPassed !== refYes) {
      refYes.current.classList.remove("item_selected");
      refYes.current.classList.add("item_no_selected");
    }

    if (refPassed !== refNo) {
      refNo.current.classList.remove("item_selected");
      refNo.current.classList.add("item_no_selected");
    }

    refPassed.current.classList.add("item_selected");

    setCurrentRef(refPassed);
    props.handleChange(props.setter, value); // launch handleChange in parent simu
  }

  useEffect(() => {
    switch (props.defaultValue) {
      case 1:
        EventClick(refYes, 1);
        break;
      case 0:
        EventClick(refNo, 0);
        break;
      default:
        break;
    }
  }, [isMountedRef, props.defaultValue]);

  return (
    <div className={"item_ctn"}>
      <div
        className={"droitpartage item_no_selected"}
        ref={refNo}
        onClick={(e) => {
          EventClick(refNo, 0);
        }}
      >
        Particuliers
      </div>

      <div
        className={"droitpartage item_no_selected"}
        ref={refYes}
        onClick={(e) => {
          EventClick(refYes, 1);
        }}
      >
        Professionnels
      </div>
    </div>
  );
};

export default ButtonPretsPro;
