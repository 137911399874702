import { useEffect, useState, useRef } from "react";
import "./essentiel.css";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import roundDecimal from "../../utils/roundDecimal";
import useIsMountedRef from "../../utils/mountedRef";
import { useSnackbar } from "notistack";

const EMOL_FORM = () => {
  const [emolForm, setEmolForm] = useState([]);
  const isMountedRefEmol = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [bottomPosition, setBottomPosition] = useState(20);
  const fixedElementRef = useRef(null);

  const adjustFixedElement = () => {
    if (fixedElementRef.current) {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const fixedElementHeight = fixedElementRef.current.offsetHeight;

      const distanceToBottom = documentHeight - (scrollTop + windowHeight);

      if (distanceToBottom < fixedElementHeight + 180) {
        setBottomPosition(fixedElementHeight + 180 - distanceToBottom);
      } else {
        setBottomPosition(20);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", adjustFixedElement);
    window.addEventListener("resize", adjustFixedElement);

    adjustFixedElement();

    return () => {
      window.removeEventListener("scroll", adjustFixedElement);
      window.removeEventListener("resize", adjustFixedElement);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const jwt = user?.jwt;

        const response = await axios.get(`${API_BASE_URL}/emol_form`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
          },
        });

        const data = response.data;
        if (Array.isArray(data)) {
          const sortedData = data
            .map((item) => ({
              ...item,
              nombre: 0,
              montant2: 0,
            }))
            .sort((a, b) => a.formalite.localeCompare(b.formalite));

          setEmolForm(sortedData);
        }
      } catch (error) {
        console.error("Error fetching data", error);
        enqueueSnackbar("Une erreur est survenue", {
          variant: "error",
        });
      }
    };

    fetchData();
  }, [enqueueSnackbar, isMountedRefEmol]);

  const handleInputChange = (id, value) => {
    setEmolForm((prevForm) =>
      prevForm.map((item) => (item.id === id ? { ...item, nombre: value, montant2: item.montant * value } : item))
    );
  };

  const calculateTotal = () => {
    return roundDecimal(
      emolForm.reduce((acc, item) => acc + item.montant2, 0),
      2
    );
  };

  return (
    <>
      <div class="container_global_emol_form">
        <div className="total_emolform"   ref={fixedElementRef}
  style={{bottom: `${bottomPosition}px`}}
        >
          Total émoluments de formalités (HT)
          <span className="total_nb_emolform"> {calculateTotal()} €</span>
        </div>

        <div className="container_emol_form">
          <table className="table2_emolform">
            <thead className="thead_emolform">
              <tr className="tr_emolform">
                <th className="th_emolform1">Nature des formalités</th>
                <th className="th_emolform2">N° du tableau 5</th>
                <th className="th_emolform3">Coût de la formalité (HT)</th>
                <th className="th_emolform4">Nombre de formalité</th>
                <th className="th_emolform5">Coût total (HT)</th>
              </tr>
            </thead>
            <tbody>
              {emolForm.map((item, index) => (
                <tr
                  key={item.id}
                  className="tr_emolform"
                  style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "#d8d3c984" }}
                >
                  <td className="td1formTitle_emolform" dangerouslySetInnerHTML={{ __html: item.formalite_mef }}/>
                  <td className="td1formArt_emolform" dangerouslySetInnerHTML={{ __html: item.article_mef }}/>
                  <td className="td1form_emolform" style={{ fontWeight: "bold" }}>{item.montant} €</td>
                  <td className="td1form_emolform">
                    <input
                      className="input_emolform"
                      type="number"
                      placeholder="Nombre"
                      value={item.nombre}
                      onChange={(e) => handleInputChange(item.id, parseFloat(e.target.value) || 0)}
                    />
                  </td>
                  <td className="td1form_emolform">{roundDecimal(item.montant2, 2)} €</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default EMOL_FORM;
