import React, {createRef, useEffect, useRef, useState} from 'react';
import './button.css'
import {findDOMNode} from "react-dom";
import useIsMountedRef from "../../utils/mountedRef";


const ButtonCessionFDC2 = (props) => {

    const isMountedRef = useIsMountedRef();

    const refYes = createRef();
    const refNo = createRef();
    const refUndefined = createRef();
    let [currentRef, setCurrentRef] = useState(null)

    function EventClick(refPassed, value) {

        refPassed.current.classList.remove("item_no_selected");

        if (refPassed !== refYes) {
            refYes.current.classList.remove('item_selected')
            refYes.current.classList.add('item_no_selected')
        }

        if (refPassed !== refNo) {
            refNo.current.classList.remove('item_selected')
            refNo.current.classList.add('item_no_selected')
        }

        refPassed.current.classList.add('item_selected')

        setCurrentRef(refPassed);
        props.handleChange(props.setter, value) // launch handleChange in parent simu
    }


    useEffect(() => {
        switch (props.defaultValue) {
            case 0:
                EventClick(refYes, 0);
                break;
            case 1:
                EventClick(refNo, 1);
                break;
            default:
                break;
        }
    }, [isMountedRef]);

    return (
        <div className={"item_ctn"}>
            <div className={"droitpartage item_no_selected"} ref={refYes} onClick={((e) => {
                EventClick(refYes, 0)
            })}
            >
                Avec le fonds
            </div>

            <div className={"droitpartage item_no_selected"} ref={refNo} onClick={((e) => {
                EventClick(refNo, 1)
            })}>
                Seul
            </div>
        </div>
    );
};

export default ButtonCessionFDC2;
