import React from 'react';
import Colors from "../../useclass/colors"
import LogoYellow from "../../assets/images/logo_yellow.png"

const Footer = () => {
    const styles = {
        footer: {
          position: "absolute !important",
            width: "100%",
            bottom: 0,
        },
        container: {
            backgroundColor: "#012E40",
            width: "100%",
            height: 40,
            // position: "fixed !important",
            position: "absolute !important",
            bottom: 0,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            color: Colors.white,
            padding: 0,
            margin: 0
        },
        copyright: {
            padding: 0,
            margin: 0,
            fontSize: 14,
        },
        containerYellowLogo: {
            position: "absolute !important",
            right: 50,
            height: 40,
            bottom: 15
        },
        yellowLogo: {
            height: "120%"
        },

        link: {
            cursor:"pointer",
            textDecoration:"underline"
        }
    };

    let newDate = new Date()
    let year = newDate.getFullYear();
      const currentDate = `${year}`

    return (
        <footer style={styles.footer}>
            <div style={styles.container}>
                <div style={styles.containerText}>
                    <p style={styles.copyright}>
                        © {currentDate} Copyright : editionslangloys.com | <a style={styles.link} onClick={() => window.open("https://editionslangloys.com/cgv-et-cgu/", "_blank")}> Conditions Générales </a>
                        </p>

                </div>

            </div>
            {/*<div style={styles.containerYellowLogo}>*/}
            {/*    <img src={`${LogoYellow}`} style={styles.yellowLogo}/>*/}
            {/*</div>*/}
        </footer>

    );
};

export default Footer;
