import React, {createRef} from 'react';

import "./textfield.css"

const TextfieldNumber = (props) => {

    const refTextfield = createRef();

    function EventChange(refPassed, value) {
        // console.log("my vall " +  value)
        props.handleChange(props.setter, value) // launch handleChange in parent simu
    }

    return (
        <input type={props.type}
               className={"input_field"}
               defaultValue={props.defaultValue}
               ref={refTextfield}
               placeholder={props.placeholder}
               min="0"
               max={props.max}
               onChange={((e) => {
                   EventChange(refTextfield, e.target.value)
                   //props.editable ? EventChange(refTextfield, e.target.value) : <></>
               })}
        />

    );
};

export default TextfieldNumber;
