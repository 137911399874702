import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import "./essentiel.css";

const alphabet = "ABCDEILMNOPQRSTVW".split("");

export default function MENU_ESSENTIEL({ setActiveItem, setSelectedItems, setSimulateurResponses }) {
  const [menuItems, setMenuItems] = useState([]);
  const [activeLetter, setActiveLetter] = useState("A"); // "A" est actif par défaut
  const [activeItem, setActiveItemLocal] = useState(null);
  const menuRef = useRef(null); // Référence pour le menu défilant
  const alphabetRef = useRef(null); // Référence pour la colonne des lettres

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const jwt = user?.jwt;

        const response = await axios.get(`${API_BASE_URL}/essentiel_emol`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
          },
        });

        const data = response.data;
        if (Array.isArray(data)) {
          setMenuItems(data);

          if (!activeItem && data.length > 0) {
            setActiveItemLocal(data[0], data[1]);
            setActiveItem(data[0], data[1]);
            setSelectedItems([data[0], data[1]]);
          }
        }
      } catch (error) {
        console.error("Error fetching data", error);
        if (error.response.status === 401 && error.response.data != null) {
          if (error.response.data.error == "Other_user_connected") {
            localStorage.removeItem("user");
            localStorage.setItem("other_user_connected", "other_user_connected");
            window.location.href = "/login";
          }
          if (error.response.data.error == "Never connected" || error.response.data.error == "Unauthorized") {
            localStorage.removeItem("user");
            window.location.href = "/login";
          }
        }
      }
    };

    fetchData();
  }, [setActiveItem, setSelectedItems, activeItem]);

  const uniqueMenuItems = Array.from(new Set(menuItems.map((item) => item.n_acte))).map((n_acte) => {
    return menuItems.find((item) => item.n_acte === n_acte);
  });

  const handleMenuItemClick = (item) => {
    setActiveItemLocal(item);
    setActiveItem(item);
    setSimulateurResponses({});

    // Mettre à jour la lettre active
    setActiveLetter(item.letter);

    // Faire défiler la colonne des lettres pour centrer la lettre correspondante
    const letterIndex = alphabet.indexOf(item.letter);
    if (letterIndex !== -1) {
      const alphabetContainer = alphabetRef.current;
      const letterElement = alphabetContainer.children[letterIndex];
      if (letterElement) {
        const letterTopPosition = letterElement.offsetTop;
        alphabetContainer.scrollTo({
          top: letterTopPosition - alphabetContainer.clientHeight / 2,
          behavior: "smooth",
        });
      }
    }

    const filteredItems = menuItems.filter((menuItem) => menuItem.n_acte === item.n_acte);
    setSelectedItems(filteredItems);
  };

  const handleScroll = () => {
    const menuElements = document.querySelectorAll(".menu-item");

    const marginTopOffset = 50; // Compense la marge en haut
    const letterElements = document.querySelectorAll(".group-letter h2");
    let letterHeightOffset = 0;

    // Récupérer la position de défilement et la hauteur totale du menu
    const scrollPosition = menuRef.current.scrollTop;
    const maxScroll = menuRef.current.scrollHeight - menuRef.current.clientHeight;

    // Si on arrive en bas du menu, forcer la sélection de la dernière lettre
    if (scrollPosition >= maxScroll - 10) {
      // Tolérance de 10px pour être sûr d'atteindre la fin
      const lastItem = menuElements[menuElements.length - 1];
      const lastLetter = lastItem.getAttribute("data-letter");
      setActiveLetter(lastLetter);

      // Scroll alphabet column based on the last letter
      const lastLetterIndex = alphabet.indexOf(lastLetter);
      if (lastLetterIndex !== -1) {
        const alphabetContainer = alphabetRef.current;
        const letterElement = alphabetContainer.children[lastLetterIndex];
        if (letterElement) {
          // Faire défiler vers la dernière lettre, en bas du conteneur
          alphabetContainer.scrollTo({
            top: alphabetContainer.scrollHeight - alphabetContainer.clientHeight,
            behavior: "smooth",
          });
        }
      }
      return;
    }

    // Logique normale pour les autres items
    for (let i = 0; i < menuElements.length; i++) {
      const element = menuElements[i];
      const boundingRect = element.getBoundingClientRect();

      // Si une lettre est présente avant cet item, ajuster le décalage pour cette lettre
      const prevLetter = letterElements[i - 1];
      if (prevLetter) {
        letterHeightOffset = prevLetter.offsetHeight;
      }

      if (boundingRect.top >= marginTopOffset + letterHeightOffset && boundingRect.top < window.innerHeight / 2) {
        const letter = element.getAttribute("data-letter");
        setActiveLetter(letter);

        // Scroll alphabet column based on the letter
        const letterIndex = alphabet.indexOf(letter);
        if (letterIndex !== -1) {
          const alphabetContainer = alphabetRef.current;
          const letterElement = alphabetContainer.children[letterIndex];
          if (letterElement) {
            const letterTopPosition = letterElement.offsetTop;
            alphabetContainer.scrollTo({
              top: letterTopPosition - alphabetContainer.clientHeight / 2,
              behavior: "smooth",
            });
          }
        }
        break;
      }
    }
  };

  useEffect(() => {
    const menuElement = menuRef.current;
    if (menuElement) {
      menuElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (menuElement) {
        menuElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [menuItems]);

  useEffect(() => {
    handleScrollToLetter("A");
  }, []);

  const handleScrollToLetter = (letter) => {
    const element = document.querySelector(`.menu-item[data-letter='${letter}']`);
    if (element) {
      const menuTop = menuRef.current.offsetTop;

      // Récupère la hauteur de la lettre en gros, si elle existe
      const letterElement = document.querySelector(`.group-letter h2`);
      const letterHeight = letterElement ? letterElement.offsetHeight : 0;

      // Ajoute le décalage pour compenser la hauteur de la lettre et la marge en haut
      const marginTopOffset = 50 + letterHeight; // Ajuster cette valeur selon tes besoins
      const elementTopPosition = element.offsetTop - menuTop - marginTopOffset;

      menuRef.current.scrollTo({
        top: elementTopPosition,
        behavior: "smooth",
      });
      setActiveLetter(letter);
    }
  };

  return (
    <div className="sidebar-container">
      <div className="alphabet-column" ref={alphabetRef}>
        {alphabet.map((letter) => (
          <React.Fragment key={letter}>
            <div
              className={`alphabet-letter ${activeLetter === letter ? "active" : ""}`}
              onClick={() => handleScrollToLetter(letter)}
            >
              {letter}
            </div>
            {letter !== alphabet[alphabet.length - 1] && <div className="letter-separator"></div>}
          </React.Fragment>
        ))}
      </div>
      <div className="menu-column" ref={menuRef}>
        {uniqueMenuItems.length > 0 ? (
          uniqueMenuItems.map((item, index) => {
            const prevItem = uniqueMenuItems[index - 1];
            const showLetter = index === 0 || item.letter !== prevItem.letter;

            return (
              <React.Fragment key={item.n_acte}>
                {showLetter && (
                  <div className="group-letter">
                    <h2
                      style={{
                        borderTop: index > 0 && item.letter !== prevItem.letter ? "3px solid #016954" : "",
                      }}
                    >
                      {item.letter}
                    </h2>
                  </div>
                )}
                <div
                  className={`menu-item ${activeItem?.n_acte === item.n_acte ? "active" : ""}`}
                  onClick={() => handleMenuItemClick(item)}
                  data-letter={item.letter}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.actes_mef }} />
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
