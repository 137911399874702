import React, { useEffect } from "react";
import "./essentiel.css";
import EMOL_PROP from "./emol_prop.js";

const EMOL = ({
  menuItems,
  activeItem,
  setActiveItem,
  setSelectedItems,
  selectedItems,
  simulateurResponses,
  setSimulateurResponses,
}) => {
  useEffect(() => {
    if (!activeItem && menuItems.length > 0) {
      setActiveItem(menuItems[0], menuItems[1]);
      setSelectedItems([menuItems[0], menuItems[1]]);
    } else if (Array.isArray(activeItem)) {
      setActiveItem(activeItem[0], activeItem[1]);
      setSelectedItems([activeItem[0], activeItem[1]]);
    }
  }, [activeItem, menuItems, setActiveItem, setSelectedItems]);

  // console.log(activeItem);

  return (
    <div className="emol_global_card">
      <div className="emol_global_title">
        {activeItem ? <span dangerouslySetInnerHTML={{ __html: activeItem.actes_mef }} /> : <></>}
      </div>

      {activeItem && activeItem.n_acte !== 10 ? (
        <div className="emol_prop_title">
          {activeItem.article.includes("Aucun numéro") ? activeItem.article : `N°${activeItem.article}`} du Tableau 5
        </div>
      ) : (
        <></>
      )}

      <EMOL_PROP
        selectedItems={selectedItems}
        simulateurResponses={simulateurResponses}
        setSimulateurResponses={setSimulateurResponses}
      />
    </div>
  );
};

export default EMOL;
