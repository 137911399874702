import React, { useState } from "react";
import "./essentiel.css";

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="modalFiscaIntro-overlay">
      <div className="modalFiscaIntro-content">
        <div className="modalFiscaIntro-close-button" onClick={onClose}>
          &times;
        </div>
        {children}
      </div>
    </div>
  );
};

const ModalComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="fisca_intro_global">
      <div className="fisca_intro_header">
        <div style={{ fontSize: 18, textTransform: "uppercase", fontWeight: "bold", color: "#306a36" }}>
          Principes généraux :
        </div>
        La fiscalité des actes s’articule principalement autour de trois perceptions :
        <button onClick={toggleModal}>{isModalOpen ? "...moins" : "... plus"}</button>
      </div>

      <Modal show={isModalOpen} onClose={toggleModal}>
        <div className="fisca_intro_green_card">
          <span className="fisca_intro_title">Les Droits d’Enregistrement</span>
          <p>
            <b> Les droits liquidés sur les actes notariés peuvent être fixes</b>, proportionnels ou progressifs suivant
            la nature de l’acte.
          </p>
          <table className="fisca_intro_tableau">
            <tbody>
              <tr>
                <th>
                  <b>Droits fixes</b>
                  <br />
                  Article 679 et 680 du CGI
                </th>
                <td className="fisca_intro_tableau_variante1">
                  S’appliquent pour tous les actes qui ne contiennent pas de dispositions susceptibles par leur nature
                  de donner ouverture à un droit proportionnel ou progressif.
                </td>
              </tr>
              <th>Article 60 Ann. IV du CGI</th>
              <td className="fisca_intro_tableau_variante1">
                Un droit fixe, dit des actes innomés, est également exigé pour tout acte présenté volontairement à la
                formalité (Article 680 du CGI).
              </td>
              <tr className="fisca_intro_tableau_variante">
                <td>
                  <b>Droits proportionnels</b>
                  <br />
                  Article 677, 678, 683 et suivants du CGI
                </td>
                <td className="fisca_intro_tableau_variante1">
                  S’appliquent essentiellement aux mutations. Ils frappent également les actes constatant un partage
                  ainsi que certaines opérations concernant les sociétés.
                </td>
              </tr>
              <tr>
                <td>
                  <b>Droits progressifs</b>
                  <br />
                  Article 677 et 777CGI
                </td>
                <td className="fisca_intro_tableau_variante1">
                  S’appliquent aux mutations à titre gratuit, par décès ou entre vifs.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="fisca_intro_green_card">
          <span className="fisca_intro_title">La Taxe de Publicité Foncière</span>
          <p>Au sens du CGI, il y a lieu de distinguer son application et dans sa liquidation :</p>
          <table className="fisca_intro_tableau">
            <tbody>
              <tr className="fisca_intro_tableau_variante3">
                <td>
                  <span style={{ fontWeight: "bold", marginRight: "10px" }}>1.</span>
                  <strong>La formalité fusionnée</strong> où la TPF tient lieu de droits d’enregistrement ; la formalité
                  fusionnée a pour effet de réaliser en une seule opération l’enregistrement et la publication au
                  service de la publicité foncière.
                </td>
              </tr>
              <tr className="fisca_intro_tableau_variante2">
                <td>
                  <span style={{ fontWeight: "bold", marginRight: "10px" }}>2.</span> Des situations où la <strong>formalité de publicité foncière est complémentaire de celle de l’enregistrement</strong> effectuée au service de l’enregistrement (présentation obligatoire ou sur état) : <u>la TPF est perçue en plus des droits d’enregistrement</u>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="fisca_intro_green_card">
          <span className="fisca_intro_title">La Contribution de Sécurité Immobilière</span>
          <table className="fisca_intro_tableau_variante4">
            L'exécution des formalités auprès des services de la publicité foncière donne lieu au paiement d'une
            contribution de sécurité immobilière.
          </table>
        </div>
        <div>
          <p>
            Remarque :
            <strong>
              <u>Principe de liquidation des droits d'enregistrement</u>
            </strong>
          </p>
          <p>
            <strong>
              Une même opération juridique ne peut être frappée deux fois du <u>droit d'enregistrement</u>.
            </strong>
          </p>
          Par conséquent :
          <ul>
            <li>
              Les actes rectificatifs, complémentaires ou interprétatifs à un acte principal sont soumis au droit fixe
              des actes innomés
            </li>
            <li>Il conviendra d’analyser tout acte contenant plusieurs dispositions</li>
          </ul>
          <table className="fisca_intro_tableau_variante5">
            <tbody>
              <tr>
                <td
                  style={{
                    padding: "15px",
                  }}
                >
                  <b>Article 671 du CGI</b>
                  <br />
                  Lorsque, dans un acte quelconque, soit civil, soit judiciaire ou extrajudiciaire, il y a plusieurs
                  dispositions indépendantes ou ne dérivant pas nécessairement les unes des autres, il est dû pour
                  chacune d’elles, et selon son espèce, une taxe ou un droit particulier. La quotité en est déterminée
                  par l’article du présent code dans lequel la disposition se trouve classée, ou auquel elle se
                  rapporte.
                </td>
              </tr>
              <tr className="fisca_intro_tableau_variante">
                <td
                  style={{
                    padding: "15px",
                  }}
                >
                  <b>Article 672 du CGI</b>
                  <br />
                  Sont affranchies de la pluralité édictée par l’article 671, dans les actes civils, les dispositions
                  indépendantes et non sujettes à une imposition proportionnelle ou progressive. Lorsqu’un acte contient
                  plusieurs dispositions indépendantes donnant ouverture, les unes à une imposition proportionnelle ou
                  progressive, les autres à une imposition fixe, il n’est rien perçu sur ces dernières dispositions,
                  sauf application de l’imposition fixe la plus élevée comme minimum de perception, si le montant des
                  impositions proportionnelles ou progressives exigibles est inférieur.
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ marginTop: "20px" }}>
            <p>
              Pour les conventions soumises à plusieurs formalités :
              <ul>
                <li>
                  <strong>Si l’acte est exclu de la formalité fusionnée, donc soumis à la double formalité :</strong>
                  une fiscalité est appliquée par formalité
                </li>
                <li>
                  <strong>
                    Si le dépôt au Service de la publicité foncière est constitué par deux formalités, l’une publiée,
                    l’autre inscrite :
                  </strong>
                  une fiscalité est également appliquée par formalité
                </li>
              </ul>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalComponent;