import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from "react";
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 450,
    width:"auto",
    fontSize:14,
    textAlign:"justify",
    backgroundColor: "rgb(249 249 249)",
    padding:10,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    color:"rgb(0 0 0)"
  },
});

export const ToolTipSearch = (props) => {
    return (
      <CustomWidthTooltip title={props.title} placement="right">
        {props.children}
      </CustomWidthTooltip>
    );
}
