import React, { useEffect, useState } from "react";
import "./essentiel.css";
import TextfieldEssentiel from "../../components/textfields/textfield.essentiel";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import roundDecimal from "../../utils/roundDecimal";
import { useSnackbar } from "notistack";
import ReactGA from "react-ga4";

const EMOL_PROP = ({ selectedItems, simulateurResponses, setSimulateurResponses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userInputs, setUserInputs] = useState({});
  const [simulateurObject] = useState({
    data: {
      emol_prop: null,
      emol_fixe: null,
      input: {
        base_calcul: null,
      },
    },
  });

  useEffect(() => {
    setUserInputs([]);
  }, [selectedItems]);

  const formatMontant = (value) => {
    return parseInt(value).toLocaleString("en-EN").replace(/,/g, " ") + " €";
  };
  const formatDecimals = (value) => {
    return value.toLocaleString("en-EN").replace(".", ",");
  };

  async function check(data) {
    localStorage.setItem("content", JSON.stringify(data));
    return data;
  }

  const handleChange = async (index, value) => {
    setUserInputs((prevInputValues) => ({
      ...prevInputValues,
      [index]: parseInt(value),
    }));
  };

  const handleCalculerClick = (emol, emol_type, userInput, index) => {
    calculate(emol, emol_type, userInput, index);
  };

  function calculate(emol_id, emol_type, base, index) {
    if (emol_type === "p") {
      simulateurObject["data"]["emol_prop"] = emol_id;
      simulateurObject["data"]["emol_fixe"] = null;
    } else {
      simulateurObject["data"]["emol_prop"] = null;
      simulateurObject["data"]["emol_fixe"] = emol_id;
    }

    simulateurObject["data"]["input"]["base_calcul"] = base;

    if (isNaN(simulateurObject.data.input.base_calcul) || simulateurObject.data.input.base_calcul <= 0) {
      enqueueSnackbar("Veuillez renseigner un montant valide afin de procéder au calcul", {
        variant: "error",
      });
      return;
    }

    check(simulateurObject["data"]).then((r) => {
      var config = {
        method: "post",
        url: `${API_BASE_URL}/calcul_emol`,
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user"))?.jwt,
        },
        data: r,
      };
      axios(config)
        .then((response) => {
          if (response.status === 200 && response.data != null) {
            localStorage.setItem("usageId", response.data.usage_id);

            if (response.data.emoluments_acte !== undefined) {
              setSimulateurResponses((prevResponseValues) => ({
                ...prevResponseValues,
                [index]:
                  roundDecimal(parseFloat(response.data.emoluments_acte), 2)
                    .toLocaleString("en-EN")
                    .replace(/,/g, " ")
                    .replace(".", ",") + " €",
              }));
            }
          } else {
            enqueueSnackbar("Une erreur est survenue", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Tous les champs n'ont pas été correctement renseignés.", {
            variant: "error",
          });
        })
        .catch((error) => {
          if (error.response.status === 401 && error.response.data != null) {
            if (error.response.data.error === "Other_user_connected") {
              localStorage.removeItem("user");
              localStorage.setItem("other_user_connected", "other_user_connected");
              window.location.href = "/login";
            }
          }
        });
    });
  }

  return (
    <div className="emol_prop_container">
      {selectedItems.map((item, index) => {
        const essaie = item.dispositions_sp_et_assiette_des_emols || "";
        const sautEssaie = essaie.split("\n");
        const userInput = userInputs[index] || "";
        const simulateurResponse = simulateurResponses[index] || "";

        return (
          <span className="emol_prop_card" key={index}>
            {item.emol_fixe_text ? (
              <>
                {item.dispositions_sp_et_assiette_des_emols_mef ? (
                  <>
                    <div className="emol_prop_description">
                      <span dangerouslySetInnerHTML={{ __html: item.dispositions_sp_et_assiette_des_emols_mef }} />
                    </div>
                  </>
                ) : item.dispositions_sp_et_assiette_des_emols ? (
                  <>
                    <div className="emol_prop_description">{item.dispositions_sp_et_assiette_des_emols}</div>
                  </>
                ) : (
                  <></>
                )}

                <div className="emol_prop_tab">
                  {item.n_acte === 10 ? (
                    <div className="emol_prop_title">
                      {item.article.includes("Aucun numéro") ? item.article : `N°${item.article}`} du Tableau 5
                    </div>
                  ) : (
                    <></>
                  )}
                  <table className="emol_prop_table">
                    <tbody>
                      <tr className="emol_prop_tr">
                        <th className="emol_fix_tbl" scope="row">
                          Émolument d’acte fixe
                        </th>
                        <td className="emol_fix_tbl">
                          {item.emol_fixe_content.montant !== 0 ? (
                            <>{formatDecimals(item.emol_fixe_content.montant)} € HT</>
                          ) : item.n_acte !== 18 && item.n_acte !== 78 ? (
                            <>{item.emol_fixe_text} HT</>
                          ) : (
                            <>{item.emol_fixe_text}</>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                {item.dispositions_sp_et_assiette_des_emols_mef ? (
                  <>
                    <div className="emol_prop_description">
                      <span dangerouslySetInnerHTML={{ __html: item.dispositions_sp_et_assiette_des_emols_mef }} />
                    </div>
                  </>
                ) : item.dispositions_sp_et_assiette_des_emols ? (
                  <>
                    <div className="emol_prop_description">
                      {sautEssaie.map((ligne, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && <br />}
                          {ligne}
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {item.emol_prop === 39 ? (
                  <>
                    <div className="emol_prop_tab">
                      <table className="emol_prop_table">
                        <thead className="emol_prop_thead">
                          <tr className="emol_prop_tr">
                            <th className="emol_prop_th" style={{ width: "70%", backgroundColor: "#b19e7e79" }}>
                              Tranches
                            </th>
                            <th
                              className="emol_prop_th"
                              scope="col"
                              style={{ borderRight: "none", backgroundColor: "#b19e7e79" }}
                            >
                              Taux
                            </th>
                          </tr>
                        </thead>
                        <tbody className="emol_prop_tbody">
                          <tr className="emol_prop_tr">
                            <th className="emol_prop_th" scope="row" style={{ textAlign: "start" }}>
                              Part à la charge du vendeur
                            </th>
                            <td className="emol_prop_td" style={{ borderRight: "none" }}>
                              5 %
                            </td>
                          </tr>

                          <tr className="emol_prop_tr">
                            <th className="emol_prop_th" scope="row" style={{ textAlign: "start" }}>
                              Part à la charge de l'acquéreur
                            </th>
                            <td className="emol_prop_td" style={{ borderRight: "none" }}>
                              12 %
                            </td>
                          </tr>
                          <tr className="emol_prop_tr">
                            <th className="emol_prop_th" style={{ textAlign: "end" }}>
                              Total
                            </th>
                            <td className="emol_prop_td" style={{ borderRight: "none" }}>
                              17 %
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="emol_prop_calcul">
                      <TextfieldEssentiel
                        type={"number"}
                        placeholder={"Indiquer la base de calcul"}
                        value={userInput}
                        setter={index}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="emol_prop_result">
                      <text>Résultat (HT) : </text>
                      <div>{userInput ? formatDecimals(userInput * 0.17) + " €" : ""}</div>
                    </div>
                  </>
                ) : null}

                {item.emol_prop_content.max_tranche_1 !== 0 ? (
                  item.emol_prop_content.id !== 40 ? (
                    <>
                      <div className="emol_prop_tab">
                        {item.n_acte === 10 ? (
                          <div className="emol_prop_title">N°{item.article} du Tableau 5</div>
                        ) : (
                          <></>
                        )}
                        <table className="emol_prop_table">
                          <thead className="emol_prop_thead">
                            <tr className="emol_prop_tr">
                              <th className="emol_prop_th" style={{ backgroundColor: "#b19e7e79" }} scope="col">
                                Tranches
                              </th>
                              <th className="emol_prop_th" style={{ backgroundColor: "#b19e7e79" }} scope="col">
                                Taux
                              </th>
                              <th className="emol_prop_th" style={{ backgroundColor: "#b19e7e79" }} scope="col">
                                Ajouter
                              </th>
                            </tr>
                          </thead>
                          <tbody className="emol_prop_tbody">
                            <tr className="emol_prop_tr">
                              <th className="emol_prop_th" scope="row">
                                De 0 à {formatMontant(item.emol_prop_content.max_tranche_1)}
                              </th>
                              <td className="emol_prop_td">
                                {formatDecimals((item.emol_prop_content.taux_tranche_1 * 100).toFixed(3))} %
                              </td>
                            </tr>
                            <tr className="emol_prop_tr">
                              <th className="emol_prop_th" scope="row">
                                De {formatMontant(item.emol_prop_content.max_tranche_1 + 1)} à
                                {formatMontant(item.emol_prop_content.max_tranche_2)}
                              </th>
                              <td className="emol_prop_td">
                                {formatDecimals((item.emol_prop_content.taux_tranche_2 * 100).toFixed(3))} %
                              </td>
                              <td className="emol_prop_td" style={{ borderRight: "none" }}>
                                {formatDecimals(item.emol_prop_content.complement_tranche_1.toFixed(2))} €
                              </td>
                            </tr>
                            {/*vérification de l'existance d'un maximum pour la tranche 3'*/}
                            {item.emol_prop_content.complement_tranche_3 ? (
                              <>
                                <tr className="emol_prop_tr">
                                  <th className="emol_prop_th" scope="row">
                                    De {formatMontant(item.emol_prop_content.max_tranche_2 + 1)} à
                                    {formatMontant(item.emol_prop_content.max_tranche_3)}
                                  </th>
                                  <td className="emol_prop_td">
                                    {formatDecimals((item.emol_prop_content.taux_tranche_3 * 100).toFixed(3))} %
                                  </td>
                                  <td className="emol_prop_td" style={{ borderRight: "none" }}>
                                    {formatDecimals(item.emol_prop_content.complement_tranche_2.toFixed(2))} €
                                  </td>
                                </tr>
                                <tr className="emol_prop_tr">
                                  <th className="emol_prop_th" scope="row">
                                    Au delà de {formatMontant(item.emol_prop_content.max_tranche_3 + 1)}
                                  </th>
                                  <td className="emol_prop_td">
                                    {formatDecimals((item.emol_prop_content.taux_tranche_4 * 100).toFixed(3))} %
                                  </td>
                                  <td className="emol_prop_td" style={{ borderRight: "none" }}>
                                    {formatDecimals(item.emol_prop_content.complement_tranche_3.toFixed(2))} €
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                <tr className="emol_prop_tr">
                                  <th className="emol_prop_th" scope="row">
                                    Au delà de {formatMontant(item.emol_prop_content.max_tranche_2 + 1)}
                                  </th>
                                  <td className="emol_prop_td">
                                    {formatDecimals((item.emol_prop_content.taux_tranche_3 * 100).toFixed(3))} %
                                  </td>
                                  <td className="emol_prop_td" style={{ borderRight: "none" }}>
                                    {formatDecimals(item.emol_prop_content.complement_tranche_2.toFixed(2))} €
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="emol_prop_calcul">
                        <TextfieldEssentiel
                          type={"number"}
                          placeholder={"Indiquer la base de calcul"}
                          value={userInput}
                          setter={index}
                          handleChange={handleChange}
                        />
                        <button
                          className="emol_prop_button"
                          onClick={() => {
                            ReactGA.event({
                              category: "essentiel",
                              action: "button calcul emolProp",
                              label: "essentiel_calcul_emolProp",
                            });
                            handleCalculerClick(item.emol_prop, "p", userInput, index);
                          }}
                        >
                          Calculer
                        </button>
                      </div>
                      <div className="emol_prop_result">
                        <text>Résultat (HT) : </text>
                        <div>{simulateurResponse} </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="emol_prop_tab">
                        <table className="emol_prop_table">
                          <tbody>
                            <tr className="emol_prop_tr">
                              <th className="emol_fix_tbl" scope="row">
                                Émolument d’acte proportionnel
                              </th>
                              <td className="emol_fix_tbl">
                                <>{item.emol_prop_text}</>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="emol_prop_calcul">
                        <TextfieldEssentiel
                          type={"number"}
                          placeholder={"Indiquer la base de calcul"}
                          value={userInput}
                          setter={index}
                          handleChange={handleChange}
                        />
                        <button
                          className="emol_prop_button"
                          onClick={() => {
                            ReactGA.event({
                              category: "essentiel",
                              action: "button calcul emolProp",
                              label: "essentiel_calcul_emolProp",
                            });
                            handleCalculerClick(item.emol_prop, "p", userInput, index);
                          }}
                        >
                          Calculer
                        </button>
                      </div>
                      <div className="emol_prop_result">
                        <text>Résultat : </text>
                        <div>{simulateurResponse}</div>
                      </div>
                    </>
                  )
                ) : item.emol_prop_text && item.emol_prop !== 39 ? (
                  <div className="emol_prop_text">{item.emol_prop_text}</div>
                ) : (
                  <></>
                )}
              </>
            )}
          </span>
        );
      })}
    </div>
  );
};

export default EMOL_PROP;
