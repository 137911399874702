import React from "react";
import FichesFamille from "./fiches_famille";
import FichesImmo from "./fiches_immo";
import FichesGenerales from "./fiches_generales";
import ESSENTIEL from "../../assets/images/ESSENTIEL.png";
import VideoESSENTIEL from "../../assets/videos/L_ESSENTIEL_720p.mp4";
import "./informations.css";
import LateralMenu from "../../layouts/lateralMenu/lateralMenu";

function Fiches(props) {
  const handleClick = () => {
    window.open("https://editionslangloys.com/produit/lessentiel/", "_blank");
  };

  let newDate = new Date();
  let year = newDate.getFullYear();
  const currentDate = `${year}`;

  return (
    <div className="globalcontainer">
      <LateralMenu />
      <div className="containerInformations">
        <div className="cardInformations">
          <h1 className="cardTitleInformations">ESSENTIEL Taxe des actes - Fiches pratiques</h1>

          <h2 className="SubCardTitle">
            À votre disposition en téléchargement, quelques fiches pratiques issues de notre ouvrage “ESSENTIEL Taxe des
            actes 2024 - dernier onglet
          </h2>
          <div className="containerGlobalFiches">
            <div className="blocksContainer">
              <div className="block1">
                <FichesGenerales />
                <FichesImmo />
                <FichesFamille />
              </div>
            </div>

            <div className={"headerCard"}>
              <div className={"imageCard1"}>
                <div className={"textContent1"}>
                  L'ESSENTIEL Taxe des actes {currentDate} est disponible sur notre boutique en ligne
                </div>
                <img className={"imageExperts"} src={ESSENTIEL} alt="avis d'experts"></img>
                <button className={"callToAction"} onClick={handleClick}>
                  Découvrir
                </button>
              </div>

              <video controls className="imageCard1">
                <source src={VideoESSENTIEL} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fiches;
