import React, { useEffect, useState } from "react";
import Colors from "../../useclass/colors";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import useIsMountedRef from "../../utils/mountedRef";

const Categoriesblock = (props) => {
  const dmtg = [55, 56, 109, 110];
  const others = [42, 103, 104, 105, 106];
  const deactivated = [107, 108];
  const styles = {
    container: {
      width: "100%",
      marginBottom: 50,
    },
    containerTitle: {
      color: Colors.primaryBlue,
      width: "90%",
      fontSize: 22,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 10,
      marginBottom: 10,
    },
    containerBody: {
      width: "88%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    containerItem: {
      display: "grid",
      gridTemplateColumns: "32px 12px 1fr",
      marginTop: 15,
      marginBottom: 15,
      lineHeight: 1.5,
    },
    bar: {
      backgroundColor: Colors.primaryBlue,
      width: 2,
      height: 30,
      marginTop: "auto",
      marginBottom: "auto",
    },
    star: {
      marginTop: "auto",
      marginBottom: "auto",
      cursor: "pointer",
    },
    bodyText: {
      marginTop: "auto",
      marginBottom: "auto",
      cursor: "pointer",
    },
  };

  const isMountedRef = useIsMountedRef();
  const [allFavoris, setAllFavoris] = useState(localStorage.getItem("favoris"));

  useEffect(() => {
    if (allFavoris !== null && allFavoris.length > 0) {
      try {
        setAllFavoris(JSON.parse(allFavoris));
      } catch (e) {}
    }
  }, [isMountedRef]);

  function returnStar(item) {
    if (allFavoris == null) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.847"
          height="19.011"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 512 512"
        >
          <path
            fill="none"
            stroke="#5E91B6"
            strokeLinejoin="round"
            strokeWidth="32"
            d="M480 208H308L256 48l-52 160H32l140 96l-54 160l138-100l138 100l-54-160Z"
          />
        </svg>
      );
    }
    if (allFavoris.hasOwnProperty(item.id)) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.847"
          height="19.011"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 512 512"
        >
          <path
            fill="#5E91B6"
            d="M394 480a16 16 0 0 1-9.39-3L256 383.76L127.39 477a16 16 0 0 1-24.55-18.08L153 310.35L23 221.2a16 16 0 0 1 9-29.2h160.38l48.4-148.95a16 16 0 0 1 30.44 0l48.4 149H480a16 16 0 0 1 9.05 29.2L359 310.35l50.13 148.53A16 16 0 0 1 394 480Z"
          />
        </svg>
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.847"
          height="19.011"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 512 512"
        >
          <path
            fill="none"
            stroke="#5E91B6"
            strokeLinejoin="round"
            strokeWidth="32"
            d="M480 208H308L256 48l-52 160H32l140 96l-54 160l138-100l138 100l-54-160Z"
          />
        </svg>
      );
    }
  }

  function onClickFavoris(id) {
    var config = {
      method: "get",
      url: `${API_BASE_URL}/favoris/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.getItem("user"))?.jwt,
      },
    };
    axios(config).then((response) => {
      if (response.status === 200 && response.data != null) {
        localStorage.setItem("favoris", response.data);
        if (response.data !== null) setAllFavoris(JSON.parse(response.data));
        else setAllFavoris(response.data);
      }
    })
    .catch((error) => {
      if (error.response.status === 401 && error.response.data != null) {
        if (error.response.data.error == "Other_user_connected" ) {
          localStorage.removeItem("user")
          localStorage.setItem("other_user_connected", "other_user_connected")
          window.location.href = "/login"
        }
      }
    
    });
  }

    //   // permet de supprimer l'affichage des simulateurs que l'on souhaite
    //   for ( var i = 0 ; i < props.body.length ; i++) {
    //     if (props.body[i].id == 112) {
    //         props.body.splice(i, 1);
    //     }
    // }

  return (
    <div style={styles.container}>
      <div style={styles.containerTitle}>{props.title}</div>

      <div style={styles.containerBody}>
        {props.body.map((item) => (
          <div style={styles.containerItem}>
            <div style={styles.star} onClick={(e) => onClickFavoris(item.id)}>
              {returnStar(item)}
            </div>
            <div style={styles.bar}></div>
            <div
              style={styles.bodyText}
              onClick={(e) => {
                if (dmtg.includes(item.id)) {
                  window.location = window.location + "/dmtg/" + item.id;
                } else if (others.includes(item.id)) {
                  window.location = window.location + "/others/" + item.id;
                } else if (!deactivated.includes(item.id)) {
                  window.location = window.location + "/simulateur/" + item.id;
                }
              }}
            >
              {item.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categoriesblock;
