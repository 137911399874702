import React, { createRef, useEffect } from "react";

import "./select.css";
import useIsMountedRef from "../../utils/mountedRef";

const SelectDepartement = (props) => {
  const refSelect = createRef();
  const isMountedRef = useIsMountedRef();

  function EventChange(refPassed, value) {
    props.handleChange(props.setter, value); // launch handleChange in parent simu
  }

  useEffect(() => {
    EventChange(refSelect, props.defaultValue);
  }, [isMountedRef]);

  return (
    <select
      className={"select_custom"}
      ref={refSelect}
      defaultValue={props.defaultValue || "Sélectionnez un département"}
      onChange={(e) => {
        EventChange(refSelect, e.target.value);
      }}
    >
      <option></option>
      {props.items.map((item) => {
        return (
          <option value={item[props.optionValue]}>
            {item.id === 998 || item.id === 999 ? "" : item[props.nameField]}
          </option>
        );
      })}
    </select>
  );
};

export default SelectDepartement;
