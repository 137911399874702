import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SnackbarProvider} from 'notistack';
import {Provider} from 'react-redux';
import { configureStore } from './store';
import './index.css';
import ReactGA from "react-ga4";


ReactGA.initialize("G-5SPP6561QL");


const styles = {
    success: {backgroundColor: 'purple'},
    error: {backgroundColor: 'blue'},
    warning: {backgroundColor: 'green'},
    info: {backgroundColor: 'yellow'},
};

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider
            classes={{
                variantSuccess: styles.success,
                variantError: styles.error,
                variantWarning: styles.warning,
                variantInfo: styles.info,
            }}
        >
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
