import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { useSnackbar } from "notistack";
import useIsMountedRef from "../utils/mountedRef";
import InfoPopup from "../components/PopUp/popUp";
import "./Home.css";
import LateralMenu from "../layouts/lateralMenu/lateralMenu";
// import ImageSlider from "../components/slider/ImagesSlider";
import Image1 from "../assets/images/Donation_bien_immo.png";
import Image2 from "../assets/images/Donation_somme_argent.png";
import Image3 from "../assets/images/Declaration_succession.png";
import Image4 from "../assets/images/Attestation_propriete.png";
import Image5 from "../assets/images/Notoriete.png";
import ReactGA from "react-ga4";

function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const [categoriesOther, setCategoriesOther] = useState([]);
  const [setSimulateurs] = useState([]);
  const [allFavoris, setAllFavoris] = useState(localStorage.getItem("favoris"));
  const [categories, setCategories] = useState([]);
  const isMountedRef = useIsMountedRef();
  const dmtg = [55, 56, 109, 110];
  const others = [42, 103, 104, 105, 106];
  const [setPopupIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const slides = [
    {
      title: "slide 0",
    },
    {
      title: "Nouveau design",
    },
    {
      title: "Le tarif des notaires",
    },
  ];

  const openPopup = () => {
    setPopupIsOpen(true);
    // Autres actions à effectuer lors de l'ouverture de la popup
  };

  const closePopup = () => {
    //remettre sur false après les tests
    setShowPopup(false);
  };

  useEffect(() => {
    var config = {
      method: "get",
      url: `${API_BASE_URL}/categories`,
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.getItem("user"))?.jwt,
      },
    };
    axios(config)
      .then((response) => {
        // response.data renvoie directement le token sans json il faut donc stocker le token en async storage et faire la securité sur les autres pages si l'utilisateurs 'nest pas connecté
        if (response.status === 200 && response.data != null) {
          if (isMountedRef.current) {
            setCategories(response.data);
          }
        } else {
          enqueueSnackbar("Une erreur est survenue", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401 && error.response.data != null) {
          if (error.response.data.error == "Other_user_connected") {
            localStorage.removeItem("user");
            localStorage.setItem("other_user_connected", "other_user_connected");
            window.location.href = "/login";
          }
          if (error.response.data.error == "Never connected" || error.response.data.error == "Unauthorized") {
            localStorage.removeItem("user");
            window.location.href = "/login";
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    var others = {
      method: "get",
      url: `${API_BASE_URL}/categories/autres`,
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.getItem("user"))?.jwt,
      },
    };
    axios(others)
      .then((response) => {
        // response.data renvoie directement le token sans json il faut donc stocker le token en async storage et faire la securité sur les autres pages si l'utilisateurs 'nest pas connecté
        if (response.status === 200 && response.data != null) {
          if (isMountedRef.current) {
            setCategoriesOther(response.data.subcategories);
          }
        } else {
          enqueueSnackbar("Une erreur est survenue", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401 && error.response.data != null) {
          if (error.response.data.error == "Other_user_connected") {
            localStorage.removeItem("user");
            localStorage.setItem("other_user_connected", "other_user_connected");
            window.location.href = "/login";
          }
          if (error.response.data.error == "Never connected" || error.response.data.error == "Unauthorized") {
            localStorage.removeItem("user");
            window.location.href = "/login";
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    var simulateurs = {
      method: "get",
      url: `${API_BASE_URL}/simulateurs`,
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.getItem("user"))?.jwt,
      },
    };
    axios(simulateurs)
      .then((response) => {
        // response.data renvoie directement le token sans json il faut donc stocker le token en async storage et faire la securité sur les autres pages si l'utilisateurs 'nest pas connecté
        if (response.status === 200 && response.data != null) {
          if (isMountedRef.current) {
            setSimulateurs(response.data);
            //console.log(simulateurs);
          }
        } else {
          enqueueSnackbar("Une erreur est survenue", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401 && error.response.data != null) {
          if (error.response.data.error == "Other_user_connected") {
            localStorage.removeItem("user");
            localStorage.setItem("other_user_connected", "other_user_connected");
            window.location.href = "/login";
          }
          if (error.response.data.error == "Never connected" || error.response.data.error == "Unauthorized") {
            localStorage.removeItem("user");
            window.location.href = "/login";
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    if (allFavoris !== null && allFavoris.length > 0) {
      try {
        setAllFavoris(JSON.parse(allFavoris));
      } catch (e) {}
    }
  }, [isMountedRef]);

  const images = [
    {
      src: Image1,
      alt: "Donation de bien immobilier",
      caption: "Donation de bien immobilier",
      overlayColor: "rgba(58, 20, 57, 0.7)",
    },
    {
      src: Image2,
      alt: "Donation de sommes d'argent",
      caption: "Donation de sommes d'argent",
      overlayColor: "rgba(102, 14, 56, 0.7)",
    },
    {
      src: Image3,
      alt: "Notoriété après décès",
      caption: "Notoriété après décès",
      overlayColor: "rgba(108, 31, 80, 0.7)",
    },
    {
      src: Image4,
      alt: "Attestation de propriété immobilière après décès",
      caption: "Attestation de propriété immobilière après décès",
      overlayColor: "rgba(132, 48, 71, 0.7)",
    },
    {
      src: Image5,
      alt: "Déclaration de succession",
      caption: "Déclaration de succession",
      overlayColor: "rgba(168, 108, 108, 0.7)",
    },
  ];

  return (
    <div className="home-global-container">
      <LateralMenu />

      <div className="containerHome">
        {showPopup && <InfoPopup onClose={closePopup} slides={slides} />}
        <div className="containerCardsHome">
          <div className="cardHome">
            <div className="cardHeaderHome">
              <div className="logoHome">
                <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.87 27.84" width={58} height={58}>
                  <path d="M10.52,0H1.72C.77,0,0,.77,0,1.72v8.81c0,.94.77,1.71,1.72,1.71h8.81c.94,0,1.72-.77,1.72-1.71V1.72c0-.95-.77-1.72-1.72-1.72ZM11.24,10.52c0,.39-.32.71-.72.71H1.72c-.39,0-.72-.32-.72-.71V1.72c0-.39.32-.72.72-.72h8.81c.4,0,.72.32.72.72,0,0,0,8.81,0,8.81Z" />
                  <polygon points="6.62 2.98 5.62 2.98 5.62 5.62 2.98 5.62 2.98 6.62 5.62 6.62 5.62 9.26 6.62 9.26 6.62 6.62 9.26 6.62 9.26 5.62 6.62 5.62 6.62 2.98" />
                  <path d="M26.15,0h-8.81c-.94,0-1.72.77-1.72,1.72v8.81c0,.94.77,1.71,1.72,1.71h8.81c.94,0,1.72-.77,1.72-1.71V1.72c0-.95-.77-1.72-1.72-1.72ZM26.87,10.52c0,.39-.32.71-.72.71h-8.81c-.4,0-.72-.32-.72-.71V1.72c0-.39.32-.72.72-.72h8.81c.4,0,.72.32.72.72v8.81Z" />
                  <polygon points="23.61 3.55 21.75 5.41 19.88 3.55 19.18 4.25 21.04 6.12 19.18 7.98 19.88 8.69 21.75 6.82 23.61 8.69 24.32 7.98 22.45 6.12 24.32 4.25 23.61 3.55" />
                  <path d="M10.52,15.6H1.72c-.95,0-1.72.77-1.72,1.71v8.81c0,.95.77,1.72,1.72,1.72h8.81c.94,0,1.72-.77,1.72-1.72v-8.81c0-.95-.77-1.71-1.72-1.71ZM11.24,26.12c0,.4-.32.72-.72.72H1.72c-.39,0-.72-.32-.72-.72v-8.81c0-.39.32-.71.72-.71h8.81c.4,0,.72.32.72.71,0,0,0,8.81,0,8.81Z" />
                  <rect x="2.98" y="21.22" width="6.28" height="1" />
                  <path d="M26.15,15.6h-8.81c-.94,0-1.72.77-1.72,1.71v8.81c0,.95.77,1.72,1.72,1.72h8.81c.94,0,1.72-.77,1.72-1.72v-8.81c0-.95-.77-1.71-1.72-1.71ZM26.87,26.12c0,.4-.32.72-.72.72h-8.81c-.4,0-.72-.32-.72-.72v-8.81c0-.39.32-.71.72-.71h8.81c.4,0,.72.32.72.71v8.81Z" />
                  <rect x="18.61" y="21.22" width="6.28" height="1" transform="translate(-8.99 21.74) rotate(-45)" />
                  <circle cx="20.26" cy="20.23" r=".83" />
                  <circle cx="23.24" cy="23.21" r=".83" />
                </svg>
              </div>

              <h2 className="cardTitleHome">Simulateurs de frais</h2>
            </div>

            <div className="cardBodyHome">
              <ul>
                {categories.map((value) => {
                  if (value.categorie_id !== 5) {
                    return (
                      <li className="listElemHome" onClick={() => (window.location = `/categorie/${value.url_name}`)}>
                        {value.categorie_name}
                      </li>
                    );
                  }
                  return <></>;
                })}
              </ul>
            </div>
          </div>

          <div className="cardHome">
            <div className="cardHeaderHome">
              <div className="logoHome">
                <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.48 87.03" width={66} height={58}>
                  <path d="M93.46,48.98c.08-.86-.36-1.71-1.18-2.1l-21.12-9.94V12.95s-.02-.1-.02-.14c.08-.86-.36-1.71-1.18-2.1L47.63.19s-.06-.02-.08-.03c-.07-.03-.15-.06-.22-.08-.06-.02-.12-.03-.18-.05-.07-.01-.14-.02-.21-.03-.07,0-.13-.01-.2-.01s-.13,0-.2.01c-.07,0-.14.01-.21.03-.06.01-.12.03-.18.05-.07.02-.15.05-.22.08-.03.01-.06.02-.08.03l-22.32,10.51c-.82.39-1.27,1.24-1.18,2.1,0,.05-.02.09-.02.14v23.98L1.2,46.87c-.82.39-1.27,1.24-1.18,2.1,0,.05-.02.1-.02.15v25.31c0,.81.47,1.55,1.2,1.89l22.32,10.51c.07.03.14.05.21.07.05.02.1.04.16.06.17.04.35.07.52.07s.35-.03.52-.07c.06-.01.11-.04.16-.06.07-.02.14-.04.21-.07l21.43-10.09,21.43,10.09c.07.03.14.05.21.07.05.02.1.04.16.05.17.05.35.07.52.07s.35-.03.53-.07c.05-.01.1-.04.16-.05.07-.02.14-.04.21-.07l22.32-10.51c.73-.35,1.2-1.08,1.2-1.89v-25.31s-.01-.1-.02-.14ZM69.06,57.32l-17.78-8.38,17.78-8.37,17.78,8.37-17.78,8.38ZM24.42,57.32l-17.78-8.38,17.78-8.37,17.78,8.37-17.78,8.38ZM46.74,4.4l17.78,8.37-17.78,8.38-17.78-8.38,17.78-8.37ZM44.65,45.47l-18.14-8.54v-20.68l18.14,8.54v20.68ZM48.83,24.79l18.14-8.54v20.68l-18.14,8.54s0-20.68,0-20.68ZM48.83,52.42l18.14,8.54v20.68l-18.14-8.54s0-20.68,0-20.68ZM4.19,52.42l18.14,8.54v20.68l-18.14-8.54s0-20.68,0-20.68ZM44.65,73.1l-18.14,8.54v-20.68l18.14-8.54v20.68ZM89.29,73.1l-18.14,8.54v-20.68l18.14-8.54v20.68Z" />
                </svg>
              </div>
              <h2 className="cardTitleHome">Calculateurs divers</h2>
            </div>
            <div className="cardBodyHome">
              <ul>
                {categoriesOther.length > 0 ? (
                  categoriesOther["0"].simulateur.map((value) => {
                    if (value.id !== 107 && value.id !== 108) {
                      return (
                        <li
                          className="listElemHome"
                          onClick={() => {
                            if (dmtg.includes(value.id)) {
                              window.location = `/categorie/autres/dmtg/${value.id}`;
                            } else if (others.includes(value.id)) {
                              window.location = `/categorie/autres/others/${value.id}`;
                            }
                          }}
                        >
                          {value.name}
                        </li>
                      );
                    }
                    return <></>;
                  })
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="containerTarifDesNotaires">
          <div className="tarifDesNotairesColumn1">
            <span
              className="tarifDesNotairesTitle"
              onClick={() => {
                ReactGA.event({
                  category: "HomePage",
                  action: "Clic essentiel titre",
                  label: "homepage_essentiel",
                });
                window.location = `/tarif_des_notaires`;
              }}
            >
              L’ESSENTIEL DE LA TAXE DES ACTES
            </span>
            <p className="tarifDesNotairesTxt">
              Toute la taxe des actes, les droits et la fiscalité accompagnée d'une présentation innovante du tarif des
              notaires. Trouvez toute l'information dont vous avez besoin facilement et intuitivement.
            </p>
            <span
              className="tarifDesNotairesButton"
              onClick={() => {
                ReactGA.event({
                  category: "HomePage",
                  action: "Clic essentiel button",
                  label: "homepage_essentielButton",
                });
                window.location = `/tarif_des_notaires`;
              }}
            >
              Accéder ➔
            </span>
          </div>
          <div className="tarifDesNotairesColumn2">
            <span className="tarifDesNotairesColumn21">
              <span
                className="tarifDesNotairesOnglet"
                onClick={() => {
                  ReactGA.event({
                    category: "HomePage",
                    action: "Clic essentiel button",
                    label: "homepage_essentielButton",
                  });
                  window.location = `/tarif_des_notaires`;
                }}
              >
                Émoluments
              </span>
              <span
                className="tarifDesNotairesOnglet"
                onClick={() => {
                  ReactGA.event({
                    category: "HomePage",
                    action: "Clic essentiel button",
                    label: "homepage_essentielButton",
                  });
                  window.location = `/tarif_des_notaires`;
                }}
              >
                Débours
              </span>
            </span>
            <span className="tarifDesNotairesColumn22">
              <span
                className="tarifDesNotairesOnglet"
                onClick={() => {
                  ReactGA.event({
                    category: "HomePage",
                    action: "Clic essentiel button",
                    label: "homepage_essentielButton",
                  });
                  window.location = `/tarif_des_notaires`;
                }}
              >
                Fiscalité
              </span>
            </span>
          </div>
        </div>

        <span style={{ position: "relative" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
              fontSize: 40,
              fontWeight: "bold",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "#031e29",
              zIndex: 8,
              width: "80%",
            }}
          >
            - NOUVEAUTÉ 2ème Semestre 2025 -
          </span>
          <span style={{ pointerEvents: "none", opacity: 0.3 }}>
            <div className="containerAnalysePat">
              <div className="tarifDesNotairesColumn1">
                <span className="tarifDesNotairesTitle" onClick={() => (window.location = `/AnalysePatrimoniale`)}>
                  Analyse patrimoniale
                </span>
                <p className="tarifDesNotairesTxt">
                  {/* Outil d’aide à la décision patrimoniale qui transforme des problématiques concrètes et parfois
                complexes, en parcours clairs sous forme d'arbre de choix, pour vous guider pas à pas vers les
                meilleures décisions. */}
                </p>
                {/* <span className="analysePatButton" onClick={() => (window.location = `/AnalysePatrimoniale`)}>
                Accéder ➔
              </span> */}
              </div>
              <div className="analysePatColumn2">
                <span className="tarifDesNotairesColumn21">
                  <span className="analysePatOnglet" onClick={() => (window.location = `/AnalysePatrimoniale`)}>
                    Immobilier
                  </span>
                  <span className="analysePatOnglet" onClick={() => (window.location = `/AnalysePatrimoniale`)}>
                    Droit des affaires
                  </span>
                </span>
                <span className="tarifDesNotairesColumn22">
                  <span className="analysePatOnglet" onClick={() => (window.location = `/AnalysePatrimoniale`)}>
                    Famille
                  </span>
                </span>
              </div>
            </div>
          </span>
        </span>

        {/* <span style={{ display: "flex", justifyContent: "center", marginBottom: 20, fontSize: 20 }}>
          - NOUVEAUTÉ FIN 2025 -
        </span>
        <span style={{ pointerEvents: "none", opacity: 0.5 }}>
          <div className="containerPratiquesHome">
            <div className="tarifDesNotairesColumn1">
              <span className="tarifDesNotairesTitle" onClick={() => (window.location = `/tarif_des_notaires`)}>
                Les Pratiques
              </span>
              <p className="tarifDesNotairesTxt">
                Des actes courants et complexes analysés et illustrés pour identifier tous les éléments qui impactent la
                taxe. Grâce à une méthode pratique et intuitive, vous vous poserez les bonnes questions pour bien
                comprendre la taxe et ne rien oublier.
              </p>
              <span className="pratiquesHomeButton" onClick={() => (window.location = `/tarif_des_notaires`)}>
                Accéder ➔
              </span>
            </div>
            <div className="pratiquesHomeColumn2">
              <ImageSlider images={images} />
            </div>
          </div>
        </span> */}
      </div>
    </div>
  );
}

export default Home;
