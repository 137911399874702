import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./essentiel.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function FichesPratiques(props) {
  const pdfsByTheme = [
    {
      theme: "Fiches Pratiques Générales",
      documents: [
        {
          pdfUrl: "/static/media/Les_debours.4626eecbbde6f30bc2a3.pdf",
          title: "Les débours",
        },
        {
          pdfUrl: "/static/media/Specificites_alsace_moselle.ea9faa0adc84c9e8552c.pdf",
          title: "Les spécificités des frais d'actes notariés en Alsace-Moselle",
        },
        {
          pdfUrl: "/static/media/Les_tarifs_BALO.2f2a46f737534850c542.pdf",
          title: "Les tarifs BALO",
        },
        {
          pdfUrl: "/static/media/Les_tarifs_BODACC.6b1d2ebeafbcd8c65a7a.pdf",
          title: "Les tarifs BODACC",
        },
      ],
    },
    {
      theme: "Fiches Pratiques Immobilier",
      documents: [
        {
          pdfUrl: "/static/media/Fiscalite_SAFER.88917b4b994609e03fd8.pdf",
          title: "La fiscalité des SAFER",
        },
        {
          pdfUrl: "/static/media/Check_list_plus_values.3e5de256db288cab1a8e.pdf",
          title: "Check-list sur les plus-values",
        },
        {
          pdfUrl: "/static/media/Ce_qu_il_ne_faut_pas_oublier_lors_d_une_vente_(check-list).6a6923913d999751a7a2.pdf",
          title: "Check-list lors d'une vente",
        },
        {
          pdfUrl:
            "/static/media/Diagnostic_immobilier_le_dossier_de_diagnostic_technique_(DDT).5c9a893fcfb5ad65c932.pdf",
          title: "Les diagnostics immobiliers",
        },
        {
          pdfUrl: "/static/media/Droits_de_mutation_a_titre_onereux_(DMTO).ebf72add71fc8a4eab62.pdf",
          title: "Les DMTO au 1er Juin 2024",
        },
        {
          pdfUrl: "/static/media/Le_bail_professionnel_et_le_bail_commercial.7c0de260e96410b0f346.pdf",
          title: "Le bail professionnel et le bail commercial",
        },
        {
          pdfUrl: "/static/media/La_fiscalite_immobiliere_quel_taux_et_quel_droit_appliquer.502055a105b9292e9a21.pdf",
          title: "La fiscalité immobilière, quel taux et quel droit appliquer ?",
        },
        {
          pdfUrl: "/static/media/La_location_pour_les_particuliers_quel_bail.f8eec69b87ff10170393.pdf",
          title: "La location pour les particuliers, quel bail ?",
        },
      ],
    },
    {
      theme: "Fiches Pratiques Famille",
      documents: [
        {
          pdfUrl:
            "/static/media/Ce_qu_il_ne_faut_pas_oublier_lors_d_une_succession_(check-list).432d6de5ea4ee29fea44.pdf",
          title: "Check-list lors d'une succession",
        },
        {
          pdfUrl:
            "/static/media/Rappel_des_droits_applicables_aux_successions_et_aux_donations.14b9c8fe4291d1a1e12b.pdf",
          title: "Droits applicables aux donations et successions - Rappel",
        },
        {
          pdfUrl: "/static/media/Synthese_des_exonerations_et_abattements_sur_les_successions.403f1abecb5b1b9f6aa1.pdf",
          title: "Exonérations et abattements sur les successions",
        },
        {
          pdfUrl: "/static/media/Synthese_des_exonerations_et_abattements_sur_les_donations.f4490b0589748cb34163.pdf",
          title: "Exonérations et abattements sur les donations",
        },
        {
          pdfUrl: "/static/media/Fiscalite_de_l_assurance_vie_droits_de_succession.2f41bf399316b64a4fb2.pdf",
          title: "La fiscalité de l'assurance vie",
        },
        {
          pdfUrl:
            "/static/media/Rappel_sur_les_regles_de_repartition_d_usufruit_et_nue-propriete.b0564a0b465203fb7334.pdf",
          title: "Règles de répartition usufruit nue propriété - Rappel",
        },
      ],
    },
  ];

  const allPdfs = pdfsByTheme.flatMap((themeGroup) => themeGroup.documents);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentPdf, setCurrentPdf] = useState(null); // État pour le PDF sélectionné
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour la modale
  const [zoom, setZoom] = useState(1);

  const openModal = (pdfUrl) => {
    setCurrentPdf(pdfUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentPdf(null);
    setIsModalOpen(false);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allPdfs.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + allPdfs.length) % allPdfs.length);
  };

  const zoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Zoom maximal de 300%
  };

  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Zoom minimal de 50%
  };

  const pictoFiche = (
    <svg
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 11.6 16.1"
      style={{ enableBackground: "new 0 0 11.6 16.1", width: "19px", height: "19px", paddingRight: "10px" }}
      xmlSpace="preserve"
    >
      <path
        fill="#ffffff"
        d="M10.3,3.1H8.6V1.3C8.6,1.1,8.5,1,8.3,1C8.2,1,8,1.1,8,1.3v2.1c0,0.2,0.1,0.3,0.3,0.3h2c0.2,0,0.3-0.1,0.3-0.3S10.5,3.1,10.3,3.1z"
      />
      <path
        fill="#005344"
        d="M11,3L8.6,0.8C8.5,0.7,8.4,0.6,8.2,0.6H1.6c-0.6,0-1,0.4-1.1,1v12.9c0,0.6,0.5,1.1,1.1,1.1H10c0.6,0,1.1-0.5,1.1-1.1h0.1V3.4C11.2,3.2,11.1,3.1,11,3z M10.3,3.7h-2C8.1,3.7,8,3.6,8,3.4V1.3C8,1.1,8.2,1,8.3,1c0.2,0,0.3,0.1,0.3,0.3v1.8h1.7c0.2,0,0.3,0.1,0.3,0.3S10.5,3.7,10.3,3.7z"
      />
    </svg>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        // backgroundColor: "#e3e7d580",
        paddingBottom: "30px",
      }}
    >
      {/* Carrousel en haut */}
      <div
        className="carousel-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "100%",
          margin: "20px 0",
          backgroundColor: "#034034",
        }}
      >
        {/* Flèche gauche */}
        <button
          onClick={prevSlide}
          style={{
            fontFamily: "system-ui",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: "10px",
            fontSize: "24px",
            cursor: "pointer",
            backgroundColor: "#011b16",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
            color: "white",
            border: "none",
            zIndex: 20,
          }}
        >
          &#8592;
        </button>

        {/* Fiches du carrousel */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            position: "relative",
            height: 250,
            padding: "50px 10px",
            overflow: "hidden", // Pour masquer les éléments en dehors du conteneur
          }}
        >
          {/* Map sur toutes les fiches pour précharger les documents */}
          {allPdfs.map((pdf, index) => {
            const isCurrent = currentIndex === index;
            const isPrevious = (currentIndex - 1 + allPdfs.length) % allPdfs.length === index;
            const isNext = (currentIndex + 1) % allPdfs.length === index;

            // On n'affiche que la fiche courante, précédente et suivante
            if (!isCurrent && !isPrevious && !isNext) return null;

            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column", // Permet de placer le titre sous la fiche
                  justifyContent: "center",
                  alignItems: "center",
                  width: isCurrent ? "auto" : "150px",
                  height: isCurrent ? "auto" : "200px",
                  transform: isCurrent ? "translateX(0)" : isPrevious ? `translateX(-100%)` : `translateX(100%)`,
                  opacity: isCurrent ? 1 : 0.5,
                  transition: "transform 0.6s ease-in-out, opacity 0.4s ease-in-out", // Transition fluide
                  position: isCurrent ? "relative" : "absolute", // Garder la fiche actuelle centrée
                  left: isPrevious ? "20%" : isNext ? "60%" : "0", // Ajustement pour les fiches à gauche et à droite
                  zIndex: isCurrent ? 2 : 1,
                  cursor: "pointer",
                }}
                onClick={() => openModal(pdf.pdfUrl)} // Ouvrir la modale au clic
              >
                <Document file={pdf.pdfUrl}>
                  <Page
                    pageNumber={1}
                    height={isCurrent ? 270 : 180}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                </Document>
                {isCurrent && (
                  <div
                    style={{
                      marginTop: "10px",
                      fontWeight: "bold",
                      fontSize: "1.2em",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {pdf.title}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* Flèche droite */}
        <button
          onClick={nextSlide}
          style={{
            fontFamily: "system-ui",
            position: "absolute",
            right: "10px",
            fontSize: "24px",
            cursor: "pointer",
            backgroundColor: "#011b16",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
            color: "white",
            border: "none",
            zIndex: 20,
          }}
        >
          &#8594;
        </button>
      </div>

      {/* Liste en deux colonnes par thème en dessous */}
      {pdfsByTheme.map((themeGroup, index) => (
        <div key={index} className="pdf-list-block">
          <h2
            className="pdf-list-title"
            // style={{
            //   borderTop: index === 0 ? "none" : "1px solid #026954", // Pas de bordure supérieure pour le premier élément
            //   paddingTop: index === 0 ? "0" : "10px", // Pas de padding en haut pour le premier élément
            // }}
          >
            {themeGroup.theme}
          </h2>
          <div className="pdf-list">
            {themeGroup.documents.map((pdf, docIndex) => (
              <div
                key={docIndex}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  textTransform: allPdfs[currentIndex].pdfUrl === pdf.pdfUrl ? "uppercase" : "",
                  fontWeight: allPdfs[currentIndex].pdfUrl === pdf.pdfUrl ? "bold" : "normal",
                  color: allPdfs[currentIndex].pdfUrl === pdf.pdfUrl ? "#09997c" : "#000000",
                  textDecoration: allPdfs[currentIndex].pdfUrl === pdf.pdfUrl ? "underline" : "",
                  padding: "5px",
                  justifyContent: "flex-start",
                  width: "90%", // Largeur égale à la colonne
                }}
                onClick={() => openModal(pdf.pdfUrl)} // Ouvrir la modale au clic
              >
                {pictoFiche}
                <span>{pdf.title}</span>
              </div>
            ))}

            {isModalOpen && (
              <div className="modal-backdrop" onClick={closeModal}>
                <div className="modal-content-pratiques" onClick={(e) => e.stopPropagation()}>
                  <button className="close-btn-pratiques" onClick={closeModal}>
                    X
                  </button>

                  <div className="zoom-controls">
                    <button onClick={zoomOut} className="zoom-button">
                      -
                    </button>
                    <span>{Math.round(zoom * 100)}%</span>
                    <button onClick={zoomIn} className="zoom-button">
                      +
                    </button>
                  </div>

                  <div
                    style={{
                      overflowX: "auto",
                      overflowY: "auto",
                      height: "80vh",
                      width: "80vw",
                      margin: "auto",
                      display: "grid",
                      placeItems: "center",
                      minWidth: `calc(100% * var(--zoom-factor))`,
                      minHeight: `calc(100% * var(--zoom-factor))`,
                      position: "relative",
                    }}
                  >
                    <Document file={currentPdf}>
                      <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} scale={zoom} />
                    </Document>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default FichesPratiques;
