import produce from 'immer';

import {
    UPDATEDMTGRESULTSOBJECT
} from '../actions/dmtg_results.action';

const initialState = {};

const DMTGResultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATEDMTGRESULTSOBJECT: {
            return {
                ...state,
                data: action.payload.results
            };
        }

        default: {
            return state;
        }
    }
};

export default DMTGResultsReducer;
