import React, {createRef} from 'react';

import "./textfield.css"

const TextfieldLong = (props) => {

    const refTextfield = createRef();

    function EventChange(refPassed, value) {
        props.handleChange(props.setter, value)
    }

    return (
        <textarea className={"input_email"}
                  defaultValue={props.defaultValue}
                  ref={refTextfield}
                  placeholder={props.placeholder}
                  onChange={((e) => {
                      EventChange(refTextfield, e.target.value)
                  })}
                //   style={{
                //       fontStyle: 'Roboto-Regular',
                //       fontSize: 14,
                //   }}
        />

    );
};

export default TextfieldLong;
