import React  from 'react';
// import AuthGuard from '../components/AuthGuard';
// import DashboardLayout from '../layouts/DashboardLayout';

const routes = [];
function importAllFile(r) {
    const files = r.keys().map(r);
    files.forEach((elem) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(elem)) {
            if (key !== 'default') {
                routes.push(value);
            }
        }
    });
}
importAllFile(require.context('./content', false, /\.(js)$/));

const AllRoutes = {
    path: '/admin',
    // guard: AuthGuard,
    // layout: DashboardLayout,
    routes
};
export default AllRoutes;
