import React, {useEffect, useState} from 'react';
import Colors from "../../useclass/colors"
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { useSnackbar } from 'notistack';


const CardResetPasswordErrorToken = (props) => {
        const styles = {
            container: {
                //height: 100,
                width: "90%",
                height: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 20,
                backgroundColor: Colors.white,
                boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
                borderRadius: 7,
            },
            containerItems: {
                textAlign: "center",
            },
            userLogo: {
                marginTop: 25,
                marginBottom: 20
            },
            textfieldLogin: {
                height: 45,

                width: "70%",
                marginBottom: 15,
                textAlign: "center",
                borderRadius: 10,
                border: "1px lightgrey solid"
            },
            buttonLogin: {
                paddingTop: 14,
                paddingBottom: 10,
                fontSize: 18,
                width: "72%",
                marginBottom: 20,
                backgroundColor: Colors.primaryBlue,
                borderRadius: 7,
                color: Colors.white,
                border: "none",
                cursor: "pointer",
                boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            },
            forgotPassword: {
                textDecoration: "underline",
                fontSize: 14
            },
            containerForgotPassword: {
                textAlign: "right",
                marginRight: 15,
                marginTop: 10
            },
            containerInput: {
                display: "block",
                position: "relative",
                zIndex: 2,

            },
            logoIdentifiant: {
                position: "absolute",
                left: "18%",
                marginTop: 13,
            },
            logoIdentifiantEye: {
                position: "absolute",
                right: "18%",
                marginTop: 20,
                cursor: "pointer",
            },
            errorMessage: {
                color: "red",
                marginTop: 20,
            },
        }

        return (
            <div style={styles.container}>
                <div style={styles.containerItems}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="74.319" height="74.319" viewBox="0 0 74.319 74.319"

                         style={styles.userLogo}>
                        <path id="Icon_material-account-circle" data-name="Icon material-account-circle"
                              d="M40.16,3a37.16,37.16,0,1,0,37.16,37.16A37.173,37.173,0,0,0,40.16,3Zm0,11.148A11.148,11.148,0,1,1,29.012,25.3,11.133,11.133,0,0,1,40.16,14.148Zm0,52.767a26.757,26.757,0,0,1-22.3-11.965C17.975,47.554,32.728,43.5,40.16,43.5c7.395,0,22.184,4.05,22.3,11.445a26.757,26.757,0,0,1-22.3,11.965Z"
                              transform="translate(-3 -3)" fill="#5E91B6" />
                    </svg>

                   <p style={styles.errorMessage}>Le lien de réinitialisation du mot de passe a expiré. Merci de faire une nouvelle demande.</p>
                </div>
            </div>
        )
            ;
    }

;


export default CardResetPasswordErrorToken;
