import React, {useEffect, useState} from 'react';
import Colors from "../../useclass/colors"
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { useSnackbar } from 'notistack';
import {useLocation} from "react-router-dom";


const CardResetPassword = (props) => {
        const styles = {
            container: {
                //height: 100,
                width: "90%",
                height: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 20,
                backgroundColor: Colors.white,
                boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
                borderRadius: 7,
            },
            containerItems: {
                textAlign: "center",
            },
            userLogo: {
                marginTop: 25,
                marginBottom: 20
            },
            textfieldLogin: {
                height: 45,

                width: "70%",
                marginBottom: 15,
                textAlign: "center",
                borderRadius: 10,
                border: "1px lightgrey solid"
            },
            buttonLogin: {
                paddingTop: 14,
                paddingBottom: 10,
                fontSize: 18,
                width: "72%",
                marginBottom: 20,
                backgroundColor: Colors.primaryBlue,
                borderRadius: 7,
                color: Colors.white,
                border: "none",
                cursor: "pointer",
                boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            },
            forgotPassword: {
                textDecoration: "underline",
                fontSize: 14
            },
            containerForgotPassword: {
                textAlign: "right",
                marginRight: 15,
                marginTop: 10
            },
            containerInput: {
                display: "block",
                position: "relative",
                zIndex: 2,

            },
            logoIdentifiant: {
                position: "absolute",
                left: "18%",
                marginTop: 13,
            },
            logoIdentifiantEye: {
                position: "absolute",
                right: "18%",
                marginTop: 20,
                cursor: "pointer",
            },
            errorMessage: {
                color: "red",
                marginTop: 20,
            },
            textNewMdp:{
                fontSize:20,
                marginBottom:30
            }
        }
        const [login, setLogin] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");
        const [password, setPassword] = useState("");
        const { enqueueSnackbar, closeSnackbar } = useSnackbar();

        const [passwordHidden, setPasswordHidden] = useState(true);
        const [confirmePasswordHidden, setConfirmPasswordHidden] = useState(true);
        const [message, setMessage] = useState('');

    const params = new URLSearchParams(useLocation().search);


        const handleChange = async (setter, value) => {
            setter(value);
        };

        const handleChangePasswordHidden = async (setter) => {
            if (passwordHidden === true)
                setter(false);
            else setter(true)
        };
        const handleChangeConfirmPasswordHidden = async (setter) => {
            if (confirmePasswordHidden === true)
                setter(false);
            else setter(true)
        };

        function resetPassword() {
            if (password !== confirmPassword) {
                setMessage("Les mots de passes ne correspondent pas.")
                return;
            } else {
                setMessage("")
            }
            const regex = /^(?=.*\d)(?=.*[A-Z])[a-zA-Z\d]{6,}$/;
            if (!regex.test(password)) {
                setMessage("Votre mot de passe doit contenir minimum 6 caractères, une majuscule et un chiffre.")
                return
            }
            const object = {
                'new_password': password
            };
            var config = {
                method: 'post',
                url: `${API_BASE_URL}/users/changePasswordReset`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': params.get('token')
                },
                data: object
            };
            axios(config)
                .then((response) => {
                    if (response.status === 200 && response.data != null) {
                        enqueueSnackbar('Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.', {
                            variant: 'success'
                        });
                        window.location = "/"
                    }else {
                        enqueueSnackbar('Une erreur est survenue, merci de renouveler votre demande.', {
                            variant: 'error'
                        });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar('Une erreur est survenue, merci de renouveler votre demande.', {
                        variant: 'error'
                    });
                })
            ;
        }


        return (
            <div style={styles.container}>
                <div style={styles.containerItems}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="74.319" height="74.319" viewBox="0 0 74.319 74.319"

                         style={styles.userLogo}>
                        <path id="Icon_material-account-circle" data-name="Icon material-account-circle"
                              d="M40.16,3a37.16,37.16,0,1,0,37.16,37.16A37.173,37.173,0,0,0,40.16,3Zm0,11.148A11.148,11.148,0,1,1,29.012,25.3,11.133,11.133,0,0,1,40.16,14.148Zm0,52.767a26.757,26.757,0,0,1-22.3-11.965C17.975,47.554,32.728,43.5,40.16,43.5c7.395,0,22.184,4.05,22.3,11.445a26.757,26.757,0,0,1-22.3,11.965Z"
                              transform="translate(-3 -3)" fill="#5E91B6" />
                    </svg>

                    <form>
                        <div style={styles.containerInput}>
                            <div style={styles.textNewMdp}> 
                        Merci de renseigner votre nouveau mot de passe ci-dessous.
                        </div>
                            {
                                passwordHidden === true ?
                                    <input type={"password"}
                                           style={styles.textfieldLogin}
                                           placeholder={"Nouveau mot de passe"}
                                           value={password}
                                           onChange={(e) => handleChange(setPassword, e.target.value)}

                                    />
                                    :
                                    <input type={"text"}
                                           style={styles.textfieldLogin}
                                           placeholder={"Nouveau mot de pass"}
                                           value={password}
                                           onChange={(e) => handleChange(setPassword, e.target.value)}

                                    />
                            }

                            <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="26" viewBox="0 0 21.667 26"
                                 style={styles.logoIdentifiant}>
                                <path id="Icon_map-locksmith" data-name="Icon map-locksmith"
                                      d="M24.27,11.553H23.1V6.878A6.63,6.63,0,0,0,16.939.72H11.8A6.516,6.516,0,0,0,5.767,6.878v4.676H4.472a1.162,1.162,0,0,0-.872.9v13.79c0,.324.505.478.872.478h19.8c.368,0,1-.154,1-.478V12.452a1.324,1.324,0,0,0-1-.9Zm-7.284,13H11.759l1.1-5.4a2.8,2.8,0,0,1-1.1-2.224,2.654,2.654,0,0,1,2.614-2.651,2.579,2.579,0,0,1,2.614,2.562,3.012,3.012,0,0,1-1.1,2.328Zm2.322-13H9.558V6.878a2.215,2.215,0,0,1,2.3-2.366h5.032a2.314,2.314,0,0,1,2.421,2.366Z"
                                      transform="translate(-3.6 -0.72)" fill="#5E91B6" />
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="18.373" height="11.483"
                                 viewBox="0 0 18.373 11.483"
                                 style={styles.logoIdentifiantEye}
                                 onClick={(e) => {
                                     handleChangePasswordHidden(setPasswordHidden)
                                 }}>
                                <path id="Icon_metro-eye" data-name="Icon metro-eye"
                                      d="M11.757,7.712a10.289,10.289,0,0,0-9.186,5.741,10.22,10.22,0,0,0,18.373,0A10.289,10.289,0,0,0,11.757,7.712Zm4.529,3.045a8.736,8.736,0,0,1,2.681,2.7,8.736,8.736,0,0,1-2.681,2.7,8.418,8.418,0,0,1-9.059,0,8.736,8.736,0,0,1-2.681-2.7,8.736,8.736,0,0,1,2.681-2.7c.07-.045.141-.088.213-.131a4.593,4.593,0,1,0,8.633,0q.107.064.213.131Zm-4.529.974a1.722,1.722,0,1,1-1.722-1.722A1.722,1.722,0,0,1,11.757,11.731Z"
                                      transform="translate(-2.571 -7.712)" fill="#5E91B6" />
                            </svg>


                        </div>

                        <div style={styles.containerInput} id="login">
                            
                            {
                                confirmePasswordHidden === true ?
                                    <input type={"password"}
                                           style={styles.textfieldLogin}
                                           placeholder={"Confimer nouveau mot de passe"}
                                           value={confirmPassword}
                                           onChange={(e) => handleChange(setConfirmPassword, e.target.value)}

                                    />
                                    :
                                    <input type={"text"}
                                           style={styles.textfieldLogin}
                                           placeholder={"Confirmer nouveau mot de passe"}
                                           value={confirmPassword}
                                           onChange={(e) => handleChange(setConfirmPassword, e.target.value)}

                                    />
                            }

                            <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="26" viewBox="0 0 21.667 26"
                                 style={styles.logoIdentifiant}>
                                <path id="Icon_map-locksmith" data-name="Icon map-locksmith"
                                      d="M24.27,11.553H23.1V6.878A6.63,6.63,0,0,0,16.939.72H11.8A6.516,6.516,0,0,0,5.767,6.878v4.676H4.472a1.162,1.162,0,0,0-.872.9v13.79c0,.324.505.478.872.478h19.8c.368,0,1-.154,1-.478V12.452a1.324,1.324,0,0,0-1-.9Zm-7.284,13H11.759l1.1-5.4a2.8,2.8,0,0,1-1.1-2.224,2.654,2.654,0,0,1,2.614-2.651,2.579,2.579,0,0,1,2.614,2.562,3.012,3.012,0,0,1-1.1,2.328Zm2.322-13H9.558V6.878a2.215,2.215,0,0,1,2.3-2.366h5.032a2.314,2.314,0,0,1,2.421,2.366Z"
                                      transform="translate(-3.6 -0.72)" fill="#5E91B6" />
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="18.373" height="11.483"
                                 viewBox="0 0 18.373 11.483"
                                 style={styles.logoIdentifiantEye}
                                 onClick={(e) => {
                                         handleChangeConfirmPasswordHidden(setConfirmPasswordHidden)
                                 }}>
                                <path id="Icon_metro-eye" data-name="Icon metro-eye"
                                      d="M11.757,7.712a10.289,10.289,0,0,0-9.186,5.741,10.22,10.22,0,0,0,18.373,0A10.289,10.289,0,0,0,11.757,7.712Zm4.529,3.045a8.736,8.736,0,0,1,2.681,2.7,8.736,8.736,0,0,1-2.681,2.7,8.418,8.418,0,0,1-9.059,0,8.736,8.736,0,0,1-2.681-2.7,8.736,8.736,0,0,1,2.681-2.7c.07-.045.141-.088.213-.131a4.593,4.593,0,1,0,8.633,0q.107.064.213.131Zm-4.529.974a1.722,1.722,0,1,1-1.722-1.722A1.722,1.722,0,0,1,11.757,11.731Z"
                                      transform="translate(-2.571 -7.712)" fill="#5E91B6" />
                            </svg>


                        </div>


                        <div>
                            <button style={styles.buttonLogin} id="connexion"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        resetPassword()
                                    }}
                                    type={"submit"}>

                                    <text id="Connexion" transform="translate(31.055 17.5)" fill="#fff" fontSize="17"
                                          fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700">
                                        <tspan x="0" y="0">Réinitialiser le mot de passe</tspan>
                                    </text>

                            </button>
                        </div>
                      </form>
                    {message && <p style={styles.errorMessage}>{message}</p>}


                </div>
            </div>
        )
            ;
    }

;


export default CardResetPassword;
