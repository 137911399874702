import React, {createRef, useState} from 'react';
import './button.css'


const ButtonTextSolo = (props) => {

    const refButtonTextsolo = createRef();
    let [currentRef, setCurrentRef] = useState(null)

    function EventClick() {
        props.setter()
    }

    return (
        <div className={"item_ctn"}>
            <div className={"common item_static item_large"} ref={refButtonTextsolo} onClick={((e) => {
                EventClick()
            })}>
                {props.text}
            </div>
        </div>
    );
};
export default ButtonTextSolo;
