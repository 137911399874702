import React, { useEffect, createRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "./textfield.css"


const TextfieldRemarques = (props) => {

    function EventChange(value) {
        // console.log("my vall " +  value)
        props.handleChange(value) // launch handleChange in parent simu
    }

    const useStyles = makeStyles({
        textarea: {
            resize: "both"
        }
    });

    return (
        <div style={{
            width: "100%",
            marginBottom: 10
        }}>

            <TextField
                label="Remarques liées au dossier, nom, références…"
                multiline
                variant="outlined"
                inputProps={{ className: useStyles.textarea }}
                style={{
                    width: "60%",
                    marginLeft: "20%",
                    minHeight: 75,
                    zIndex: 0,
                }}
                onChange={((e) => {
                    EventChange(e.target.value)
                    //props.editable ? EventChange(refTextfield, e.target.value) : <></>
                })}
            />
        </div>
    );
};

export default TextfieldRemarques;