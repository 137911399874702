import React, { useState, useEffect } from "react";
import ONGLETS from "./onglet";
import MENU_ESSENTIEL from "./menu";
import EMOL from "./emol";
import EMOL_FORM from "./emol_form";
import LateralMenu from "../../layouts/lateralMenu/lateralMenu";
import MENU_FISCA from "./menu_fisca";
import FISCA from "./fisca";
import MENU_DROITSFIXES from "./menu_droitsFixes";
import DROITSFIXES from "./droits_fixes";
import DEBOURS from "./debours";
import FichesPratiques from "./fichesPratiques";
import "./essentiel.css";
import ModalComponent from "./modalIntroFisca";

const ESSENTIEL = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeFiscaItem, setActiveFiscaItem] = useState(null);
  const [selectedFiscaItems, setSelectedFiscaItems] = useState([]);
  const [activeFixesItem, setActiveFixesItem] = useState(null);
  const [selectedFixesItems, setSelectedFixesItems] = useState([]);
  const [menuFixesItems] = useState([]);
  // const [activeTab, setActiveTab] = useState("ÉMOLUMENTS D’ACTES");
  const [simulateurResponses, setSimulateurResponses] = useState({});
  const [menuItems] = useState([]);

  // useEffect(() => {
  //   if (activeTab === "ÉMOLUMENTS D’ACTES") {
  //     if (!activeItem && menuItems.length > 0) {
  //       setActiveItem(menuItems[0], menuItems[1]);
  //       setSelectedItems([menuItems[0]], [menuItems[1]]);
  //     }
  //   }
  // }, [activeTab, menuItems, activeItem]);

  const hashToTabMap = {
    emolActes: "ÉMOLUMENTS D’ACTES",
    emolForm: "ÉMOLUMENTS DE FORMALITÉS",
    fisca: "Fiscalité",
    droitsFixes: "Droits fixes (rappel)",
    debours: "Débours",
    fichesPratiques: "Fiches pratiques"
  };

  const initialHash = window.location.hash.substring(1); // Obtenir le fragment d'URL initial
  const initialTab = hashToTabMap[initialHash] || "ÉMOLUMENTS D’ACTES"; // Utiliser le fragment si présent, sinon la valeur par défaut
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      const tab = hashToTabMap[hash];
      if (tab) {
        setActiveTab(tab);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };

    // Ajout de l'écouteur pour surveiller les changements de hash
    window.addEventListener("hashchange", handleHashChange);

    // Nettoyage lors du démontage du composant
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [setActiveTab]);
  

  const renderContent = () => {
    switch (activeTab) {
      case "ÉMOLUMENTS D’ACTES":
        return (
          <>
            <MENU_ESSENTIEL
              setActiveItem={setActiveItem}
              setSelectedItems={setSelectedItems}
              setSimulateurResponses={setSimulateurResponses}
              activeItem={activeItem}
            />
            <EMOL
              activeItem={activeItem}
              selectedItems={selectedItems}
              simulateurResponses={simulateurResponses}
              setSimulateurResponses={setSimulateurResponses}
              menuItems={menuItems}
              setActiveItem={setActiveItem}
              setSelectedItems={setSelectedItems}
            />
          </>
        );
      case "ÉMOLUMENTS DE FORMALITÉS":
        return <EMOL_FORM />;
      case "Fiscalité":
        return (
          <>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <ModalComponent />
              <span style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", borderTop: "1px solid #016954" }}>
                <MENU_FISCA
                  setActiveFiscaItem={setActiveFiscaItem}
                  setSelectedFiscaItems={setSelectedFiscaItems}
                  activeFiscaItem={activeFiscaItem}
                />
                <FISCA
                  menuItems={menuItems}
                  activeFiscaItem={activeFiscaItem}
                  setActiveFiscaItem={setActiveFiscaItem}
                  selectedFiscaItems={selectedFiscaItems}
                  setSelectedFiscaItems={setSelectedFiscaItems}
                />
              </span>
            </span>
          </>
        );
      case "Droits fixes (rappel)":
        return (
          <>
            <MENU_DROITSFIXES
              setActiveFixesItem={setActiveFixesItem}
              setSelectedFixesItems={setSelectedFixesItems}
              activeFixesItem={activeFixesItem}
            />
            <DROITSFIXES
              menuFixesItems={menuFixesItems}
              activeFixesItem={activeFixesItem}
              setActiveFixesItem={setActiveFixesItem}
              selectedFixesItems={selectedFixesItems}
              setSelectedFixesItems={setSelectedFixesItems}
            />
          </>
        );
      case "Débours":
        return <DEBOURS />;
      case "Fiches pratiques":
        return <FichesPratiques />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="page">
        <LateralMenu setActiveTab={setActiveTab} />
        <div className="essentiel">
          <ONGLETS activeTab={activeTab} setActiveTab={setActiveTab} />
          <span className="essentiel_container">{renderContent()}</span>
        </div>
      </div>
    </>
  );
};

export default ESSENTIEL;
