import { styles } from "../../../stylesheets/simulateurs.css.js";
import React, { useCallback, useEffect, useState } from "react";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldSearch from "../../../components/textfields/textfiel.search";
import axios from "axios";
import { useForceUpdate } from "../../../config";
import useIsMountedRef from "../../../utils/mountedRef";
import { SHA256 } from "crypto-js";
import SparkMD5 from "spark-md5";
import MemoSepteo from "../../../assets/docs/memo_d_utilisation_septeo.pdf";
import ReactGA from "react-ga4";
import "./genapi.css";

const crypto = require("crypto-js");
const qs = require("querystring");

export const urlGenapiLogin = "https://login-public.genapicloud.com/";
export const urlGenapi = "https://api-public.genapicloud.com/";
export var bearerToken = "";
export async function generateMD5(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const spark = new SparkMD5.ArrayBuffer();
      spark.append(reader.result);
      resolve(spark.end());
    };
    reader.onerror = () => {
      reject(new Error("Error reading Blob as ArrayBuffer"));
    };
    reader.readAsArrayBuffer(blob);
  });
}

export function GenapiModule(props) {
  const [selectGenapi, setSelectGenapi] = useState(0);
  const [search, setSearch] = useState("");
  const [searchOk, setSearchOk] = useState(false);
  const [displayFolder, setDisplayFolders] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [allFoldersClient, setAllFoldersClient] = useState([]);
  const isMountedRefGetToken = useIsMountedRef();
  var forceUpdate = useForceUpdate();
  var [tenantId, setTenantId] = useState(JSON.parse(localStorage.getItem("user")).tenantid ?? "");

  const handleChange = async (setter, value) => {
    if (setter === setSelectGenapi) {
      setSelectGenapi(parseInt(value));
      if (value == 0) {
        props.setCurrentFolderSelected(false);
        setAllClients([]);
        setAllFoldersClient([]);
      }
    }
  };

  const handleChangeSearch = async (value) => {
    setSearch(value);
  };

  const getToken = useCallback(() => {
    axios.defaults.withCredentials = true;
    let config = {
      method: "post",
      url: `${urlGenapiLogin}Authorization/OAuth/Token`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        tenantId: tenantId,
        //Dev 'Authorization': 'Basic Y2xpZW50ZXh0LXBhcnRlbmFpcmUtZjQyOGFmZDctZWY2MC00MzBlLWI3ZjEtMzA3ZDY0ZjM5NWZmOiVTP3E3NDN1M0N4ZXVmdkxvWVVyQm9kbz85ajF3eT4lemlKcmk2S3c='
        Authorization:
          "Basic Y2xpZW50ZXh0LXBhcnRlbmFpcmUtODliODYxMjctY2IwZS00MWIzLThjNmYtMDI0NWY0YTBlM2UwOjchNEdIR0xDSlV1VDxvP1VIWCV6THtBXTxyZD5YdGo8Xy1QYmVTWlM=",
      },
      data: qs.stringify({
        grant_type: "client_credentials",
        scope:
          "actes.client:read actes.document:read actes.immeuble:read actes.partenaire:read actes.sousdossier.links:read actes.sousdossier:read actes.client.links:read actes.sousdossier.document:write actes.document:write",
      }),
    };
    axios
      .request(config)
      .then((response) => {
        localStorage.setItem("genapiToken", response.data.access_token);
        bearerToken = response.data.access_token;
      })
      .catch((error) => {
        setTenantId("");
      });
    }, [isMountedRefGetToken, tenantId]);

  function onSearch() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${urlGenapi}actes/api/v1/clients/Search?search=${search}`,
      headers: {
        tenantId: tenantId,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setAllClients(response.data.items);
        setSearchOk(true);
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function onClickClient(id) {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${urlGenapi}actes/api/v1/clients/${id}/sousdossiers`,
      headers: {
        tenantId: tenantId,
        Authorization: `Bearer ${bearerToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response.data.items !== undefined && response.data.items !== null) {
          var arrayContentItems = [];
          response.data.items.map((data) => {
            var isIn = false;
            arrayContentItems.map((dataContent) => {
              if (dataContent.id == data.id) {
                isIn = true;
              }
            });
            if (!isIn) {
              arrayContentItems.push(data);
            }
          });
        }
        setAllFoldersClient(arrayContentItems);
        setDisplayFolders(true);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function onClickFolder(object) {
    props.setCurrentFolderSelected(object);
    forceUpdate();
  }

  useEffect(() => {
    getToken();
  }, [getToken]);

  function showAccessGenapi() {
    if (tenantId == "") {
      return (
        <div style={styles.cardRowSepteoMessage}>
          <div style={styles.cardElemVerticalyCenter}>
            Cette fonctionnalité n'est pas disponible si vous n'avez pas activé les droits nécessaires dans votre
            interface Septeo.
            <button
              style={styles.buttonSepteoMemo}
              onClick={() => {
                ReactGA.event({
                  category: "Simulateurs",
                  action: "Clic MemoSepteo simulateurs",
                  label: "lien MemoSepteo simulateurs",
                });
                window.open(MemoSepteo);
              }}
            >
              Cliquez-ici pour connaître la démarche à suivre
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Souhaitez-vous transférer le document de synthèse dans le dossier client Septeo ?
          </div>
          <ButtonYesno
            handleChange={handleChange}
            setter={setSelectGenapi}
            fieldUndefined={false}
            defaultValue={0}
            onClick={() => {
              ReactGA.event({
                category: "Simulateurs",
                action: "Clic sur passerelle Septeo",
                label: "lien passerelle Septeo",
              });
            }}
          />
        </div>
      );
    }
  }

  return (
    <div className="SepteoContainer">
      <div className="cardSepteoSpecial">
        <div className="septeoTitle">
          Connexion avec votre interface Septeo
          <svg
            id="Calque_1"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32.16 7.69"
            width="100px"
            height="25px"
          >
            <path
              strokeWidth="0px"
              fill="#ec623e"
              class="cls-2"
              d="M26.62,1.81c.25,0,.35.17.35.33s-.08.27-.28.47l-.86.86h1.07l.33-.33c.24-.23.51-.56.51-1,0-.61-.44-1.08-1.11-1.08h-1.53c-.7,0-1.27.57-1.27,1.27v1.37c0,.7.57,1.27,1.27,1.28h2.39v-.77h-2.39c-.28,0-.51-.23-.51-.51v-1.37c0-.28.23-.51.51-.51h1.53Z"
            />
            <path
              strokeWidth="0px"
              fill="#ec623e"
              class="cls-2"
              d="M22.78,4.21c-.28,0-.51-.23-.51-.51v-1.89h.74v-.77h-.75V0h-.76v3.69c0,.7.57,1.27,1.27,1.27h.52v-.75h-.51Z"
            />
            <path
              strokeWidth="0px"
              fill="#ec623e"
              class="cls-2"
              d="M17.7,6.02h-.76v-3.7c0-.7.57-1.27,1.27-1.28h1.37c.7,0,1.27.57,1.27,1.28v1.37c0,.7-.57,1.27-1.27,1.28h-1.14v-.76h1.14c.28,0,.51-.23.51-.51v-1.37c0-.28-.23-.51-.51-.51h-1.37c-.28,0-.51.23-.51.51v3.69Z"
            />
            <path
              strokeWidth="0px"
              fill="#ec623e"
              class="cls-2"
              d="M10.87,3.39c.25,0,.35.17.35.33s-.08.27-.28.47l-3.51,3.51h1.08l2.98-2.98c.23-.23.51-.56.51-1,0-.61-.44-1.08-1.11-1.08h-2.41c-.22,0-.41-.18-.41-.41s.18-.41.41-.41h3.23v-.77h-3.23c-.65,0-1.17.53-1.17,1.17s.53,1.17,1.17,1.17h2.4Z"
            />
            <path
              strokeWidth="0px"
              fill="#ec623e"
              class="cls-2"
              d="M15.35,1.81c.25,0,.35.17.35.33s-.08.27-.28.47l-.86.86h1.07l.33-.33c.24-.23.51-.56.51-1,0-.61-.44-1.08-1.11-1.08h-1.56c-.7,0-1.27.57-1.27,1.27v1.37c0,.7.57,1.27,1.27,1.28h2.39v-.77h-2.39c-.28,0-.51-.23-.51-.51v-1.37c0-.28.23-.51.51-.51h1.56Z"
            />
            <path
              strokeWidth="0px"
              fill="#ec623e"
              class="cls-2"
              d="M30.89,4.97h-1.37c-.7,0-1.27-.57-1.27-1.28v-1.37c0-.7.57-1.27,1.27-1.27h1.37c.7,0,1.27.57,1.27,1.27v1.37c0,.71-.57,1.28-1.27,1.28M29.51,1.81c-.28,0-.51.23-.51.51v1.37c0,.28.23.51.51.51h1.37c.28,0,.51-.23.51-.51v-1.37c0-.28-.23-.51-.51-.51h-1.37Z"
            />
            <g>
              <path
                strokeWidth="0px"
                fill="#2e3861"
                class="cls-1"
                d="M4.39,1.85L.23,6.02h.59l3.87-3.87s.01-.04,0-.05l-.25-.25s-.04-.01-.05,0Z"
              />
              <path
                strokeWidth="0px"
                fill="#2e3861"
                class="cls-1"
                d="M.31,2.98L3.28,0h-.59L.01,2.69s-.01.04,0,.05l.25.25s.04.01.05,0Z"
              />
              <path
                strokeWidth="0px"
                fill="#2e3861"
                class="cls-1"
                d="M4.98,2.44l-3.57,3.58h.58l3.29-3.28s.01-.04,0-.05l-.25-.25s-.04-.01-.05,0h0Z"
              />
              <path
                strokeWidth="0px"
                fill="#2e3861"
                class="cls-1"
                d="M5.58,3.03l-2.98,2.99h.59l2.69-2.7s.01-.04,0-.05l-.25-.25s-.03,0-.04,0Z"
              />
              <path
                strokeWidth="0px"
                fill="#2e3861"
                class="cls-1"
                d="M.6,3.32l.25.25s.04.01.05,0L4.46,0h-.59L.6,3.28s-.01.03,0,.04Z"
              />
              <path
                strokeWidth="0px"
                fill="#2e3861"
                class="cls-1"
                d="M1.2,3.92l.25.25s.04.01.05,0L5.65,0h-.59L1.2,3.87s-.01.04,0,.05Z"
              />
            </g>
          </svg>
        </div>
        <div>{showAccessGenapi()}</div>
        {selectGenapi ? (
          <TextfieldSearch handleChange={handleChangeSearch} title="Rechercher un client" onClickSearch={onSearch} />
        ) : (
          <></>
        )}
        <div style={searchOk && selectGenapi === 1 ? styles.cardTable : { display: "none" }}>
          <div style={styles.cardRow}>Sélectionnez votre client.</div>
          <div style={styles.tableContainer}>
            <table style={styles.tableSizing}>
              <thead style={styles.tableHeaders}>
                <tr style={styles.rowSizing}>
                  <th style={styles.cellSpacing1}>Civilité</th>
                  <th style={styles.cellSpacing2}>Dénomination</th>
                  <th style={styles.cellSpacing3}>Email</th>
                  <th style={styles.cellSpacing4}>Adresse</th>
                </tr>
              </thead>
              <tbody style={styles.cellBody}>
                {allClients.map((client) => {
                  if (client.adresse.commune == null) {
                    client.adresse.commune = { nom: " " };
                  }
                  if (client.civilite == null) {
                    client.civilite = { intitule: "" };
                  }

                  return (
                    <tr
                      key={client.id}
                      onClick={(e) => {
                        onClickClient(client.id);
                      }}
                      style={styles.rowSizing}
                    >
                      <td style={styles.cellSpacing1}>{client.civilite.intitule}</td>
                      <td style={styles.cellSpacing2}>{client.denomination}</td>
                      <td style={styles.cellSpacing3}>{client.email}</td>
                      <td style={styles.cellSpacing4}>
                        {client.adresse.numero !== undefined ? `${client.adresse.numero} ` : <></>}
                        {client.adresse.voie !== undefined ? ` ${client.adresse.voie} ` : <></>}
                        {client.adresse.codePostal !== undefined ? ` ${client.adresse.codePostal} ` : <></>}
                        {client.adresse.commune.nom !== undefined ? ` ${client.adresse.commune.nom} ` : <></>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {
          // Pour afficher le tableau des sous dossiers
        }
        <div style={displayFolder && selectGenapi === 1 ? styles.cardFolders : { display: "none" }}>
          <div style={styles.cardRow}>
            Sélectionnez le dossier dans lequel vous souhaitez déposer la synthèse.
            <span style={styles.selectedRow}>
              Dossier client Septeo sélectionné : {props.currentFolderSelected.intitule}
            </span>
          </div>
          <div style={styles.tableContainer}>
            <table style={styles.tableSizing}>
              <thead style={styles.tableHeaders}>
                <tr>
                  <th>Nom</th>
                </tr>
              </thead>
              <tbody style={styles.cellBody}>
                {allFoldersClient.map((folder) => {
                  return (
                    <tr
                      onClick={(e) => {
                        onClickFolder(folder);
                      }}
                      style={props.currentFolderSelected === folder ? styles.selectedRow : { cursor: "pointer" }}
                    >
                      <td>{folder.intitule}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

//export default GenapiModule;
