import React, {useEffect, useState} from "react";
import {styles} from "../../stylesheets/simulateurs.css";
import "../../stylesheets/simulateursBis.css";
import Successions_Donataire from "./successions.donataire";

const Successions = (props) => {

    // Ici props contient donc :
    // props.resultKey => key du tableau "results" nécessaire pour setter la bonne valeur
    // props.functionSetResults => pour lancer la fonction du main depuis le component
    // ----

    // States avec les différentes valeurs à stocker
    const [donatairesList, setDonatairesList] = useState([])
    const [soloResults, setSoloResults] = useState([{}])

    // Hook prenant pour callback le state "numberOfComponentRequired". A chacune des modifications apportées à "numberOfComponentRequired",
    // ce hook sera activé et génèrera le state "results" adapté pour stocker toute la data des components enfants.
    useEffect(async () => {
        if (soloResults.length < props.donataires) {
            let handle = []
            let handleDonataires = []
            for (let i = 0; i <= (soloResults.length - 1); i++) {
                handle.push(soloResults[i])
                handleDonataires.push(donatairesList[i])
            }
            for (let i = soloResults.length; i < props.donataires; i++) {
                handle.push({})
                handleDonataires.push({})
            }
            await setSoloResults(handle)
            await setDonatairesList(handle)
        } else if ((soloResults.length > props.donataires) && (props.donataires > 0)) {
            soloResults.splice(props.donataires)
            donatairesList.splice(props.donataires)
            let formatedData = {
                donateur: props.resultKey + 1,
                donataires: soloResults,
            }
            // Call de FunctionSetResults pour setter le props "results" de la page App.js (main)
            props.functionSetResults(props.resultKey, formatedData)

            let formatedDataDonataires = {
                donateur: props.resultKey + 1,
                donataires: donatairesList
            }
            // Call de FunctionSetResults pour setter le props "results" de la page App.js (main)
            props.FunctionSetAllDonatairesList(props.resultKey, formatedDataDonataires)
        }
    }, [props.donataires]);


    // Function permettant d'avoir la main sur la modification du nombre de components demandé et d'y ajouter une limite
    // pour éviter la génération abusive.


    // Fonction call depuis le component enfant qui set la key du state "results" modifiée.
    function FunctionSetSoloResults(key, value) {
        soloResults[key] = value
        let formatedData = {
            donateur: props.resultKey + 1,
            donataires: soloResults,
        }
        // Call de FunctionSetResults pour setter le props "results" de la page App.js (main)
        props.functionSetResults(props.resultKey, formatedData)
    }

    function FunctionSetDonatairesList(key, value) {
        donatairesList[key] = value
        let formatedData = {
            donateur: props.resultKey + 1,
            donataires: donatairesList
        }

        // Call de FunctionSetResults pour setter le props "results" de la page App.js (main)
        props.FunctionSetAllDonatairesList(props.resultKey, formatedData)
    }


    // Possibilité également de mettre tous les states (value1, value2....) dans un seul state sous format:
    //
    // state: {
    //      value1: {},
    //      value2: {},
    //      ...
    // }
    //
    // Dans ce cas penser à changer le callback du useEffect qui prend plus bas [value1, value2]
    // ---


    return (
        <div>
            <div style={styles.cardFlexSuccession}>
                {
                    // Mapping pour afficher le nombre de components demandé avec en passage de props les éléments nécessaires
                    // à faire parvenir à l'enfant pour setter le state "results"
                    Object.keys(soloResults).map(function (item, i) {
                        return (
                            <Successions_Donataire
                                soloResultKey={i}
                                donateur={props.resultKey + 1}
                                functionSetSoloResults={FunctionSetSoloResults}
                                functionSetDonatairesList={FunctionSetDonatairesList}
                                simulateurid={props.simulateurid}
                                donataires={props.donataires}
                                montant_brut={props.montant_brut}
                                total_rapportable={props.total_rapportable}
                                montant_passif={props.montant_passif}
                                montant_net={props.montant_net}
                                total_succession={props.total_succession}
                                montantRevenantHeritier={props.montantRevenantHeritier}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Successions;
