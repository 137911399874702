import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import "./SchemaFiscalite.css";

const QuestionStep1 = ({ onNext }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleNext = (answer) => {
    setSelectedOption(answer);
    onNext(answer);
  };

  return (
    <>
      <div className="schema_title">De quel type de bien s’agit-il ?</div>
      <div className="schema_option_block">
        <span
          className={`schema_option_button ${selectedOption === "Option A" ? "selected" : ""}`}
          onClick={() => handleNext("Option A")}
        >
          <b>Immeubles neufs</b>
          (Achevés depuis moins de 5 ans)
        </span>
        <span
          className={`schema_option_button ${selectedOption === "Option B" ? "selected" : ""}`}
          onClick={() => handleNext("Option B")}
        >
          <b>Immeubles autres que neufs</b>
          (Achevés depuis plus de 5 ans)
        </span>
        <span
          className={`schema_option_button ${selectedOption === "Option C" ? "selected" : ""}`}
          onClick={() => handleNext("Option C")}
        >
          <b>Terrain à bâtir</b> (ou bien assimilé ex. ruine…)
        </span>
        <span
          className={`schema_option_button ${selectedOption === "Option D" ? "selected" : ""}`}
          onClick={() => handleNext("Option D")}
        >
          <b>Terrain non constructible</b>
        </span>
      </div>
    </>
  );
};

QuestionStep1.propTypes = {
  onNext: PropTypes.func.isRequired,
};

const QuestionStep2 = ({ onNext }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
  }, [selectedOption]);

  const handleNext = (answer) => {
    setSelectedOption(answer);
    onNext(answer);
  };

  return (
    <>
      <div className="fleches">
        <svg
          id="Calque_1"
          data-name="Calque 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 99.5 65.3"
          height="80px"
        >
          <g>
            <line className="fleche2" x1="3.64" y1="60.57" x2="50" y2=".3" />
            <polygon className="fleche1" points="8.29 60.6 4.39 59.59 2.42 56.09 0 65.3 8.29 60.6" />
          </g>
          <g>
            <line className="fleche2" x1="95.86" y1="60.57" x2="49.5" y2=".3" />
            <polygon className="fleche1" points="97.08 56.09 95.11 59.59 91.21 60.6 99.5 65.3 97.08 56.09" />
          </g>
        </svg>
      </div>
      <div className="schema_title">Le vendeur est-il assujetti à la TVA ?</div>
      <div className="schema_option_block">
        <span
          onClick={() => handleNext("Option E")}
          className={`schema_option_button ${selectedOption === "Option E" ? "selected" : ""}`}
        >
          <b>OUI</b>
          C'est un marchand de biens ou un promoteur
        </span>
        <span
          onClick={() => handleNext("Option F")}
          className={`schema_option_button ${selectedOption === "Option F" ? "selected" : ""}`}
        >
          <b>NON</b>
          C'est un particulier
        </span>
      </div>
    </>
  );
};

QuestionStep2.propTypes = {
  onNext: PropTypes.func.isRequired,
};

const QuestionStep3 = ({ onNext }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
  }, [selectedOption]);

  const handleNext = (answer) => {
    setSelectedOption(answer);
    onNext(answer);
  };

  return (
    <>
      <div className="fleches">
        <svg
          id="Calque_1"
          data-name="Calque 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 99.5 65.3"
          height="80px"
        >
          <g>
            <line className="fleche2" x1="3.64" y1="60.57" x2="50" y2=".3" />
            <polygon className="fleche1" points="8.29 60.6 4.39 59.59 2.42 56.09 0 65.3 8.29 60.6" />
          </g>
          <g>
            <line className="fleche2" x1="95.86" y1="60.57" x2="49.5" y2=".3" />
            <polygon className="fleche1" points="97.08 56.09 95.11 59.59 91.21 60.6 99.5 65.3 97.08 56.09" />
          </g>
        </svg>
      </div>
      <div className="schema_title">L’acheteur est-il assujetti à la TVA ?</div>
      <div className="schema_option_block">
        <span
          onClick={() => handleNext("Option G")}
          className={`schema_option_button ${selectedOption === "Option G" ? "selected" : ""}`}
        >
          <b>OUI</b>
          C'est un marchand de biens ou un promoteur
        </span>
        <span
          onClick={() => handleNext("Option H")}
          className={`schema_option_button ${selectedOption === "Option H" ? "selected" : ""}`}
        >
          <b>NON</b>
          C'est un particulier
        </span>
      </div>
    </>
  );
};

QuestionStep3.propTypes = {
  onNext: PropTypes.func.isRequired,
};

const FinalStep = ({ answers }) => {

  const navigateToPage = (pageUrl) => {
    window.location.href = pageUrl;
  };

  const resultsMap = {
    // Immeubles neufs (Achevés depuis moins de 5 ans)
    "Option A, Option E, Option G": [
      { label1: "TVA sur prix total" },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 1 à 10 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/12"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 11 à 24 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/13"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 25 à 99 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/14"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 100 à 249 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/15"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 250 à 499 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/16"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - plus de 500 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/17"),
      },
    ],
    "Option A, Option E, Option H": [
      { label1: "TVA sur prix total" },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 1 à 10 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/12"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 11 à 24 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/13"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 25 à 99 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/14"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 100 à 249 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/15"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - 250 à 499 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/16"),
      },
      {
        label2: "Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies) - plus de 500 lots",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/17"),
      },
    ],
    "Option A, Option F, Option G": [
      { label1: "Pas de TVA (Hors champ)" },
      {
        label2: "DMTO (taux selon département) - Vente hors copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/1"),
      },
      {
        label2: "DMTO (taux selon département) - Vente en copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/2"),
      },
      {
        label2: "Si engagement de revendre dans les 5 ans, Droit d'enregistrement réduit 0,71498% (CGI art 1115)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/6"),
      },
    ],
    "Option A, Option F, Option H": [
      { label1: "Pas de TVA (Hors champ)" },
      {
        label2: "DMTO (taux selon département) - Vente hors copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/1"),
      },
      {
        label2: "DMTO (taux selon département) - Vente en copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/2"),
      },
    ],
    // Immeubles autres que neufs (Achevés depuis plus de 5 ans)
    "Option B, Option E, Option G": [
      {
        label1:
          "Exonération de TVA, mais option possible TVA sur prix total si TVA déduite lors de l'acquisition. Sinon TVA sur marge.",
      },
      {
        label2: "DMTO (taux selon département) - Vente hors copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/1"),
      },
      {
        label2: "DMTO (taux selon département) - Vente en copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/2"),
      },
      {
        label2: "Si engagement de revendre dans les 5 ans, Droit d'enregistrement réduit 0,71498% (CGI art 1115)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/6"),
      },
    ],
    "Option B, Option E, Option H": [
      {
        label1:
          "Exonération de TVA, mais option possible TVA sur prix total si TVA déduite lors de l'acquisition. Sinon TVA sur marge.",
      },
      {
        label2: "DMTO (taux selon département) - Vente hors copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/1"),
      },
      {
        label2: "DMTO (taux selon département) - Vente en copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/2"),
      },
    ],
    "Option B, Option F, Option G": [
      { label1: "Pas de TVA (Hors champ)" },
      {
        label2: "DMTO (taux selon département) - Vente hors copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/1"),
      },
      {
        label2: "DMTO (taux selon département) - Vente en copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/2"),
      },
      {
        label2: "Si engagement de revendre dans les 5 ans, Droit d'enregistrement réduit 0,71498% (CGI art 1115)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/6"),
      },
    ],
    "Option B, Option F, Option H": [
      { label1: "Pas de TVA (Hors champ)" },
      {
        label2: "DMTO (taux selon département) - Vente hors copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/1"),
      },
      {
        label2: "DMTO (taux selon département) - Vente en copropriété",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/2"),
      },
    ],
    // Terrain à bâtir (ou bien assimilé ex. ruine…)
    "Option C, Option E, Option G": [
      { label1: "TVA sur prix total si TVA déduite lors de l'acquisition. Sinon TVA sur marge." },
      {
        label2: "Si TVA sur le prix total, Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/6"),
      },
      {
        label2: "Si TVA sur marge, DMTO 5,80665%",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/3"),
      },
      {
        label2: "Si engagement de revendre dans les 5 ans, Droit d'enregistrement réduit 0,71498% (CGI art 1115)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/6"),
      },
      {
        label2: "Si engagement de construire dans les 4 ans, exo. et droit fixe de 125 € (CGI art 1594 0-G)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/5"),
      },
    ],
    "Option C, Option E, Option H": [
      { label1: "TVA sur prix total si TVA déduite lors de l'acquisition. Sinon TVA sur marge." },
      {
        label2: "Si TVA sur marge, DMTO (taux selon département)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/3"),
      },
      {
        label2: "Si TVA sur prix total, Droit d'enregistrement réduit 0,71498% (CGI art 1594 F quinquies)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/5"),
      },
    ],
    "Option C, Option F, Option G": [
      { label1: "Pas de TVA (Hors champ)" },
      {
        label2: "Si aucun engagement, DMTO à 5,80665%",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/3"),
      },
      {
        label2: "Si engagement de revendre dans les 5 ans, Droit d'enregistrement réduit 0,71498% (CGI art 1115)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/6"),
      },
      {
        label2: "Si engagement de construire dans les 4 ans, exo. et droit fixe de 125 € (CGI art 1594 0-G)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/5"),
      },
    ],
    "Option C, Option F, Option H": [
      { label1: "Pas de TVA (Hors champ)" },
      { label2: "DMTO à 5,80665%", onClick: () => navigateToPage("/categorie/immobilier/simulateur/3") },
    ],
    // Terrain non constructible
    "Option D, Option E, Option G": [
      { label1: "Exonération de TVA (CGI art 261, 5-1°), taxable sur le prix total si option (CGI art 260, 5° bis)" },
      {
        label2: "Si aucun engagement, DMTO (taux selon département)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/3"),
      },
      {
        label2: "Si engagement de revendre dans les 5 ans, Droit d'enregistrement réduit à 0,71498% (CGI art 1115) ",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/6"),
      },
      {
        label2: "Si engagement de construire dans les 4 ans, Exo. et droit fixe de 125 € (CGI art 1594 0-G)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/5"),
      },
    ],
    "Option D, Option E, Option H": [
      { label1: "Exonération de TVA (CGI art 261, 5-1°), taxable sur le prix total si option (CGI art 260, 5° bis)" },
      {
        label2: "Si TVA sur marge, DMTO (taux selon département)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/3"),
      },
    ],
    "Option D, Option F, Option G": [
      { label1: "Pas de TVA (Hors champ)" },
      {
        label2: "Si aucun engagement, DMTO à 5,80665%",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/3"),
      },
      {
        label2: "Si engagement de revendre dans les 5 ans, Droit d'enregistrement réduit 0,71498% (CGI art 1115)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/6"),
      },
      {
        label2: "Si engagement de construire dans les 4 ans, exo. et droit fixe de 125 € (CGI art 1594 0-G)",
        onClick: () => navigateToPage("/categorie/immobilier/simulateur/5"),
      },
    ],
    "Option D, Option F, Option H": [
      { label1: "Pas de TVA (Hors champ)" },
      { label2: "DMTO à 5,80665%", onClick: () => navigateToPage("/categorie/immobilier/simulateur/3") },
    ],
  };

  const filteredAnswers = answers.filter((answer) => answer);
  const allAnswersDefined = filteredAnswers.length === answers.length;

  if (allAnswersDefined) {
    const answersString = filteredAnswers.join(", ");
    const result = resultsMap[answersString];

    return (
      <>
        <div className="fleches">
          <svg
            id="Calque_1"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 7.41 65.17"
            height="80px"
          >
            <line className="fleche2" x1="3.72" y1="59.2" x2="3.27" y2="0" />
            <polygon className="fleche1" points="7.41 56.36 3.72 57.97 0 56.42 3.77 65.17 7.41 56.36" />
          </svg>
        </div>
        <div className="schema_option_block_result">
          {result && result.length > 0 ? (
            <div className="schema-selected-options-results-block">
              <span className="result_title_tva">Quel taux de TVA appliquer / régime du vendeur ?</span>
              <span className="schema-selected-options-results-tva">
                {result.map((option, index) => (
                  option.label1 && (
                    <span className="schema-selected-options-results-button-tva" onClick={option.onClick} key={index}>
                      {option.label1}
                    </span>
                  )
                ))}
              </span>
              <span className="result_title_DE_block">
                <span>
                  <span className="result_title_DE">Quel droit d'enregistrement appliquer ?</span>
                </span>
              </span>
              <span className="result_subtitle_DE">
                Cliquez sur le cas qui vous correspond pour calculer une provision sur frais
              </span>
              {result.map((option, index) => (
                option.label2 && (
                  <span className="schema-selected-options-results" key={index}>
                    <span className="schema-selected-options-results-button" onClick={option.onClick}>
                      {option.label2}
                    </span>
                  </span>
                )
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

FinalStep.propTypes = {
  answers: PropTypes.array.isRequired,
};

const SchemaFiscalite = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const handleNextStep = (answer) => {
    setAnswers([...answers, answer]);
    setCompletedSteps([...completedSteps, renderStep(currentStep)]);
    setCurrentStep(currentStep + 1);
    forceUpdate();
  };

  const renderStep = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        return <QuestionStep1 onNext={handleNextStep} />;
      case 2:
        return <QuestionStep2 onNext={handleNextStep} />;
      case 3:
        return <QuestionStep3 onNext={handleNextStep} />;
      case 4:
        return <FinalStep answers={answers} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setCompletedSteps([...completedSteps, renderStep(currentStep)]);
  }, [currentStep]);

  return (
    <div className="schema_global_composent">
      <span className="schema_main_title">La fiscalité immobilière, quel taux et quel droit appliquer ?</span>
      <div>
        {completedSteps.map((step, index) => (
          <div key={index}>{step}</div>
        ))}
      </div>
    </div>
  );
};

export default SchemaFiscalite;
