import React, { useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";

// Simulation d'un prêt immo
// Id simulateur 104

function Simulateur_104(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(1)
    const [nombre_mensualites, setMensualites] = useState(0)
    const [taux_interet, setInterets] = useState(0)
    const [capital, setCapital] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "inputs": {}
            };

            if (setter === setMensualites) {
                Object.assign(object['inputs'], { 'nombre_mensualites': parseInt(value) })
                Object.assign(object['inputs'], { 'taux_interet': parseFloat(taux_interet) })
                Object.assign(object['inputs'], { 'capital': parseInt(capital) })
                object.departementid = parseInt(departement)
            }

            if (setter === setInterets) {
                Object.assign(object['inputs'], { 'nombre_mensualites': parseInt(nombre_mensualites) })
                Object.assign(object['inputs'], { 'taux_interet': parseFloat(value) })
                Object.assign(object['inputs'], { 'capital': parseInt(capital) })
                object.departementid = parseInt(departement)
            }

            if (setter === setCapital) {
                Object.assign(object['inputs'], { 'nombre_mensualites': parseInt(nombre_mensualites) })
                Object.assign(object['inputs'], { 'taux_interet': parseFloat(taux_interet) })
                Object.assign(object['inputs'], { 'capital': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div className="cardSimulateur">
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                <div className="cardRow">
                    <div style={styles.cardElemVerticalyCenter}>
                        Indiquer le nombre de mensualités :
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le nombre"}
                        setter={setMensualites}
                    />
                </div>

                <div className="cardRow">
                    <div style={styles.cardElemVerticalyCenter}>
                        Taux d’intérêt :
                        <ToolTipPerso title={"Indiquer le TAEG ou le taux d'intérêt avec assurance (Exemple : 2.3)"} />
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le taux en %"}
                        setter={setInterets}
                    />
                </div>

                <div className="cardRow">
                    <div style={styles.cardElemVerticalyCenter}>
                        Capital emprunté :
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setCapital}
                    />
                </div>

            </div>

        </div>
    )
}

export default Simulateur_104;
