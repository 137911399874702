import React, { useEffect, createRef } from "react";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import "./textfield.css"
import {IconButton, InputAdornment, InputBase, Paper} from "@material-ui/core";
import {Search} from "@mui/icons-material";


const TextfieldSearch = (props) => {

    function EventChange(value) {
        // console.log("my vall " +  value)
        props.handleChange(value) // launch handleChange in parent simu
    }

    const useStyles = makeStyles({
        textarea: {
            resize: "both"
        }
    });

    function search() {
        props.onClickSearch()
    }

    return (
        <div style={{
            width: "100%",
            marginBottom: 10
        }}>

            <TextField
                label={props.title}
                variant="outlined"
                InputProps={{ className: useStyles.textarea,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Search onClick={event => search() } style={{cursor: "pointer"}} />
                        </InputAdornment>
                    ) }}
                style={{
                    width: "60%",
                    marginLeft: "20%",
                    minHeight: 75,
                    zIndex: 0,
                }}
                onChange={((e) => {
                    EventChange(e.target.value)
                    //props.editable ? EventChange(refTextfield, e.target.value) : <></>
                })}
                onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        // Do code here
                        search();
                        ev.preventDefault();
                    }
                }}
            />
        </div>
    );
};

export default TextfieldSearch;