import React, { useState, useEffect } from "react";
import TextBig from "../../components/texts/text.big";
import TextSmall from "../../components/texts/text.small";
import "../connect/Login.css";
import CardResetPassword from "../../components/cards/card.resetPassword";
import jwt_decode from "jwt-decode";
import {useLocation} from "react-router-dom";
import CardResetPasswordErrorToken from "../../components/cards/card.resetPasswordErrorToken";

function ForgotPassword(props) {
    const params = new URLSearchParams(useLocation().search);
    const [isError, setIsError] = useState(false);

    function checkJWT() {
        const decodedToken = params.get('token');
                let currentDate = new Date();
                // JWT exp is in seconds
                if (decodedToken.exp * 1000 - 86400 < currentDate.getTime()) {
                    return false;
                } else {
                    return true;
                }
    }
    useEffect(() => {
          setIsError(!checkJWT())
    })

    return (

        <div className="container">
            <TextBig title={"Rénitialisation de votre mot de passe"}  />
            <div className="containerCards">

                <div className="itemConnect">
                    {!isError  ?  <CardResetPassword/> : <CardResetPasswordErrorToken/>}

                </div>


            </div>
            <TextSmall
                text={
                    "Pour plus d’informations, contactez-nous par e-mail sur info@langloys.com ou par téléphone au 04.91.25.13.38"
                }
            />

        </div>
    );
}

export default ForgotPassword;
