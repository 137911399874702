import React, {createRef} from 'react';

import "./textfield.css"

const TextfieldAbattement = (props) => {


    return (
        <input type={props.type} 
        className={"input_abattement"} 
        readOnly
        placeholder={props.placeholder}
        style={{ backgroundColor: props.placeholder.replace(/[\s€]/g, '') < 0 ? 'pink' : 'rgba(176, 176, 176, 0.426)'}}
        />

    );
};

export default TextfieldAbattement;
