import React, {createRef} from 'react';

import "./textfield.css"

const TextfieldProfile = (props) => {
    const refTextfield = createRef();

    function EventChange(refPassed, value) {
        props.handleChange(props.setter, value) // launch handleChange in parent simu
    }


    return (
        <>
            <div
                className={"label_input"}>
                {props.label}
            </div>
            <input
                type={props.type}
                className={"input_field"}
                ref={refTextfield}
                onChange={((e) => {
                    props.editable ? EventChange(refTextfield, e.target.value) : <></>
                })}
                value={props.value}
            />
        </>
    );
};

export default TextfieldProfile;
