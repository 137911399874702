import { combineReducers } from 'redux';

// import reducers
import SimulateurReducer from "./simulateur.reducer";
import DMTGResultsReducer from "./dmtg_results.reducer";


const rootReducer = combineReducers({
    simulateur: SimulateurReducer,
    dmtgResults: DMTGResultsReducer,
    //another: anotherReducer,
});

export default rootReducer;
