import React, { useEffect } from "react";
import "./essentiel.css";

const DROITSFIXES = ({
  menuFixesItems,
  activeFixesItem,
  setActiveFixesItem,
  setSelectedFixesItems,
  selectedFixesItems,
}) => {
  useEffect(() => {
    if (!activeFixesItem && menuFixesItems.length > 0) {
      setActiveFixesItem(menuFixesItems[0], menuFixesItems[1]);
      setSelectedFixesItems([menuFixesItems[0], menuFixesItems[1]]);
    } else if (Array.isArray(activeFixesItem)) {
      setActiveFixesItem(activeFixesItem[0], activeFixesItem[1]);
      setSelectedFixesItems([activeFixesItem[0], activeFixesItem[1]]);
    }
  }, [activeFixesItem, menuFixesItems, setActiveFixesItem, setSelectedFixesItems]);

  // console.log("activeFixesItem")
  // console.log(activeFixesItem)
  // console.log("selectedFixesItems")
  // console.log(selectedFixesItems)

  return (
    <span>
      <div className="droits_fixes_intro">
        <b>État :</b> Obligatoirement soumis au paiement sur état sans qu’une copie certifiée n’ait à être déposée
        <br />
        <b>ENR :</b> Obligatoirement présenté au Service des Impôts des Entreprises <br />
        <b>Ex :</b> Exemptée de la formalité d’enregistrement
      </div>
      <div className="droits_fixes_card">
        <div className="emol_global_title">
          {activeFixesItem ? <span dangerouslySetInnerHTML={{ __html: activeFixesItem.actes_mef }} /> : <></>}
        </div>
        <div className="emol_prop_container">
          {selectedFixesItems.map((item, index) => (
            <span key={index} className="emol_prop_card">
              {item.sous_actes ? (
                <div className="droits_fixes_description">
                  <ul>
                    <li> {item.sous_actes}</li>
                  </ul>
                </div>
              ) : (
                <></>
              )}
              <div className="emol_prop_tab">
                <table className="emol_prop_table">
                  <thead className="emol_fisca_thead">
                    <tr className="emol_prop_tr">
                      <th className="droits_fixes_th" scope="col">
                        État
                      </th>
                      <th className="droits_fixes_th" scope="col">
                        ENR
                      </th>
                      <th className="droits_fixes_th" scope="col">
                        EX
                      </th>
                      <th className="droits_fixes_th" scope="col">
                        Montant
                      </th>
                    </tr>
                  </thead>
                  <tbody className="emol_prop_tbody">
                    <tr className="emol_prop_tr">
                      <th className="droits_fixes_th" scope="row">
                        {item.etat || ""}
                      </th>
                      <td className="droits_fixes_td">{item.enr || ""}</td>
                      <td className="droits_fixes_td">{item.ex || ""}</td>
                      <td className="droits_fixes_td">
                        {item.montant && !isNaN(item.montant) ? `${parseInt(item.montant).toFixed()} €` : item.montant}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </span>
          ))}
        </div>
      </div>
    </span>
  );
};

export default DROITSFIXES;
