import React, { useEffect, useRef, useState } from 'react';

const TopMenu = () => {
    const styles = {
        container: {
            width: "100vw",
            minHeight: 45,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: "white",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: "none",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "start",
        },
        ctnBurger: {
            marginTop: "auto",
            paddingTop: 5,
            paddingBottom: 5,
            marginBottom: "auto",
            marginLeft: 25,
            zIndex: 10,
            marginRight: 50,
        },
        elem: {
            marginTop: "auto",
            marginBottom: "auto",
            minWidth: 150,
            maxWidth: 200,
            textAlign: "center",
            cursor: "pointer"
        },
        ctnSearchBar: {
            marginTop: "auto",
            marginBottom: "auto",
            minWidth: 400,
            maxWidth: 200,
            textAlign: "center",
            display: "block",
            marginLeft: 10
        },
        searchBar: {
            width: "100%",
            height: 25,
            borderRadius: 5,
            border: "lightgrey 1px solid",
        },
        searchlogo: {
            position: "absolute",
            right: "0%",
            top: 4
        },
        modal: {
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgb(89 138 173)",
            width: 300,
            borderBottomRightRadius: 10,
            boxShadow: "5px 5px 2px rgba(0,0,0, 0.2)",
            // height: 365,
            display: "flex",
            flexDirection: "column",
            color: "white",
            zIndex: -5
        },
        modalBody: {
            width: "90%",
            marginLeft: "auto"
        },
        arrowModal: {
            marginRight: 10
        },
        modalBodyCtn: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "noWrap",
            alignItems: "center",
            marginTop: 0,
            marginBottom: 0
        },
        modalBodyHome: {
            marginLeft: 20,
            marginTop: 60,
            marginBottom: 10,
            cursor: "pointer"
        },
        modalBodyTxt: {
            marginTop: 10,
            marginBottom: 10,
            cursor: "pointer"
        },
        modalBottom: {
            width: "100%",
            marginTop: 20,
            borderTop: "1px white solid",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly"
        },
        txtDisconnect: {
            textDecoration: "underline",
            marginTop: 5,
            marginBottom: 15,
            cursor: "pointer"
        },
        txtAccount: {
            fontWeight: "bold",
            marginTop: 15,
            marginBottom: 5,
            color: "white",
            textDecoration: "underline",
            texteDecorationColor: "white",
        }

    };

    const [stateOpenTopMenu, setStateOpenTopMenu] = useState(false);

    const handleChangeStateOpenTopMenu = async (setter, currentValue) => {
        if (currentValue === true) {
            setter(false);
        }
        if (currentValue === false) {
            setter(true);
        }
    };

    function disconnect() {
        localStorage.removeItem("user")
        localStorage.removeItem("remarque")
        localStorage.removeItem("currentEmol")
        localStorage.removeItem("chart")
        window.location = "/"
    }
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setStateOpenTopMenu(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div style={styles.container} ref={wrapperRef}>

            <div style={styles.ctnBurger}>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="24" viewBox="0 0 35.643 26.494"
                    onClick={(event) => handleChangeStateOpenTopMenu(setStateOpenTopMenu, stateOpenTopMenu)}
                    style={{ cursor: "pointer" }}>
                    <g id="Groupe_95" data-name="Groupe 95" transform="translate(-63.934 -18.422)">
                        <g id="Icon_feather-menu" data-name="Icon feather-menu"
                            transform="translate(66.934 21.422)">
                            <path id="Tracé_4" data-name="Tracé 4" d="M4.5,18H34.143"
                                transform="translate(-4.5 -7.753)"
                                fill="none" stroke="#0d373b" strokeLinecap="round" strokeLinejoin="round"
                                strokeWidth="6" />
                            <path id="Tracé_5" data-name="Tracé 5" d="M4.5,9H34.143" transform="translate(-4.5 -9)"
                                fill="none" stroke="#0d373b" strokeLinecap="round" strokeLinejoin="round"
                                strokeWidth="6" />
                            <path id="Tracé_6" data-name="Tracé 6" d="M4.5,27H34.143"
                                transform="translate(-4.5 -6.506)"
                                fill="none" stroke="#0d373b" strokeLinecap="round" strokeLinejoin="round"
                                strokeWidth="6" />
                        </g>
                    </g>
                </svg>

                {stateOpenTopMenu === true ?
                    <div style={styles.modal}>
                        <div style={styles.modalBody}>
                            <div style={styles.modalBodyCtn}>
                                <p style={styles.modalBodyHome} onClick={event => window.location = '/'}>Accueil</p>
                            </div>

                            <div style={styles.modalBodyCtn}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11.471" viewBox="0 0 9 11.471"
                                    style={styles.arrowModal}>
                                    <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright"
                                        d="M13.5,9l9,5.736-9,5.736Z" transform="translate(-13.5 -9)" fill="#fbfbfb" />
                                </svg>
                                <p style={styles.modalBodyTxt} onClick={event => window.location = "/categorie/immobilier"}>Actes immobiliers</p>
                            </div>

                            <div style={styles.modalBodyCtn}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11.471" viewBox="0 0 9 11.471"
                                    style={styles.arrowModal}>
                                    <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright"
                                        d="M13.5,9l9,5.736-9,5.736Z" transform="translate(-13.5 -9)" fill="#fbfbfb" />
                                </svg>
                                <p style={styles.modalBodyTxt} onClick={event => window.location = "/categorie/famille"}>Actes de la famille</p>
                            </div>

                            <div style={styles.modalBodyCtn}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11.471" viewBox="0 0 9 11.471"
                                    style={styles.arrowModal}>
                                    <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright"
                                        d="M13.5,9l9,5.736-9,5.736Z" transform="translate(-13.5 -9)" fill="#fbfbfb" />
                                </svg>
                                <p style={styles.modalBodyTxt} onClick={event => window.location = "/categorie/prets"}>Actes de prêt, garanties et
                                    mainlevées</p>
                            </div>

                            <div style={styles.modalBodyCtn}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11.471" viewBox="0 0 9 11.471"
                                    style={styles.arrowModal}>
                                    <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright"
                                        d="M13.5,9l9,5.736-9,5.736Z" transform="translate(-13.5 -9)" fill="#fbfbfb" />
                                </svg>
                                <p style={styles.modalBodyTxt} onClick={event => window.location = "/categorie/affaires"}>Actes d'affaires</p>
                            </div>
                        </div>

                        <div style={styles.modalBottom}>
                            <div>
                                <a href={"/moncompte"}>
                                    <p style={styles.txtAccount}>Mon compte</p>
                                </a>
                                <p style={styles.txtDisconnect} onClick={((e) => {
                                    disconnect()
                                })}>Se déconnecter</p>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" width="56.304" height="54.771"
                                viewBox="0 0 56.304 54.771">
                                <path id="Icon_material-account-circle" data-name="Icon material-account-circle"
                                    d="M31.152,3C15.612,3,3,15.269,3,30.385S15.612,57.771,31.152,57.771,59.3,45.5,59.3,30.385,46.692,3,31.152,3Zm0,8.216A8.322,8.322,0,0,1,39.6,19.431a8.449,8.449,0,0,1-16.891,0A8.322,8.322,0,0,1,31.152,11.216Zm0,38.887a20.423,20.423,0,0,1-16.891-8.818c.084-5.45,11.261-8.435,16.891-8.435,5.6,0,16.807,2.985,16.891,8.435A20.423,20.423,0,0,1,31.152,50.1Z"
                                    transform="translate(-3 -3)" fill="#fbfbfb" />
                            </svg>


                        </div>

                    </div>
                    :
                    <></>
                }
            </div>

            <div style={styles.elem} onClick={() => window.location = "/"}>
                Accueil
            </div>

            <div style={styles.elem}>
                Éditions Langloÿs
            </div>

            <div style={styles.ctnSearchBar}>
                {/*  <input
                    style={styles.searchBar}
                />*/}
                {/*   <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19.003" viewBox="0 0 19 19.003"
                     style={styles.searchlogo}>
                    <path id="Icon_awesome-search" data-name="Icon awesome-search"
                          d="M18.742,16.43l-3.7-3.7a.89.89,0,0,0-.631-.26h-.6a7.716,7.716,0,1,0-1.336,1.336v.6a.89.89,0,0,0,.26.631l3.7,3.7a.887.887,0,0,0,1.258,0l1.05-1.05A.9.9,0,0,0,18.742,16.43ZM7.719,12.47a4.75,4.75,0,1,1,4.75-4.75A4.748,4.748,0,0,1,7.719,12.47Z"
                          fill="#0d373b"/>
                </svg>
                */}


            </div>

        </div>
    );
};

export default TopMenu;
