import React, { useState } from 'react';
import Colors from "../../useclass/colors"
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { useSnackbar } from 'notistack';


const CardConnect = (props) => {
    const styles = {
        container: {
            //height: 100,
            width: "90%",
            height: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            backgroundColor: Colors.white,
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            borderRadius: 7,
        },
        containerItems: {
            textAlign: "center",
        },
        userLogo: {
            marginTop: 25,
            marginBottom: 20
        },
        textfieldLogin: {
            height: 45,

            width: "70%",
            marginBottom: 15,
            textAlign: "center",
            borderRadius: 10,
            border: "1px lightgrey solid"
        },
        buttonLogin: {
            paddingTop: 14,
            paddingBottom: 10,
            fontSize: 18,
            width: "72%",
            marginBottom: 20,
            backgroundColor: "#0c5573",
            borderRadius: 7,
            color: Colors.white,
            border: "none",
            cursor: "pointer",
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
        },
        forgotPassword: {
            textDecoration: "underline",
            fontSize: 14
        },
        containerForgotPassword: {
            textAlign: "right",
            marginRight: 15,
            marginTop: 10
        },
        containerInput: {
            display: "block",
            position: "relative",
            zIndex: 2,

        },
        logoIdentifiant: {
            position: "absolute",
            left: "18%",
            marginTop: 13,
        },
        logoIdentifiantEye: {
            position: "absolute",
            right: "18%",
            marginTop: 20,
            cursor: "pointer",
        }
    }
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [passwordHidden, setPasswordHidden] = useState(true);


    const handleChange = async (setter, value) => {
        setter(value);
    };

    const handleChangePasswordHidden = async (setter) => {
        if (passwordHidden === true)
            setter(false);
        else setter(true)
    };

    function Login() {
        const object = {
            'login': login,
            'password': password
        };

        var data = '{"login":"' + login + '", "password": "' + password + '"}';
        /*   enqueueSnackbar('Exemple de message de connexion réussi', {
               variant: 'success'
           });*/
        var config = {
            method: 'post',
            url: `${API_BASE_URL}/login`,
            headers: {
                'Content-Type': 'text/plain',
            },
            data: object
        };
        axios(config)
            .then((response) => {
                // response.data renvoie directement le token sans json il faut donc stocker le token en async storage et faire la securité sur les autres pages si l'utilisateurs 'nest pas connecté
                //console.log(response.data)
                if (response.status === 200 && response.data != null) {
                    enqueueSnackbar('Vous êtes connecté', {
                        variant: 'success'
                    });

                    localStorage.setItem("user", JSON.stringify(response.data));
                    localStorage.setItem("favoris", response.data.favoris)


                    window.location = "/"
                } else if (response.status === 401) {
                    enqueueSnackbar('Vous n\'êtes pas abonné à notre service', {
                        variant: 'error'
                    });
                } else {
                    enqueueSnackbar('Merci de renseigner tous les champs', {
                        variant: 'error'
                    });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Identifiants incorrects', {
                    variant: 'error'
                });
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data != null) {
                  if (error.response.data.error == "Other_user_connected" ) {
                    localStorage.removeItem("user")
                    localStorage.setItem("other_user_connected", "other_user_connected")
                    window.location.href = "/login";
                  }
                }
              
              })
            ;
    }

    // let icon = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"25.73\" height=\"22.027\" viewBox=\"0 0 25.73 22.027\">" +
    //     "<g id=\"Groupe_138\" data-name=\"Groupe 138\" transform=\"translate(-237.945 -682.734)\">" +
    //     "<path id=\"Tracé_1\" data-name=\"Tracé 1\" d=\"M25.872,5.625H9.146A2.748,2.748,0,0,0,6.4,8.373a.836.836,0,1,0,1.673,0A1.078,1.078,0,0,1,9.146,7.3H25.872a1.078,1.078,0,0,1,1.075,1.075V23.9a1.078,1.078,0,0,1-1.075,1.075H9.146A1.078,1.078,0,0,1,8.071,23.9a.836.836,0,1,0-1.673,0,2.748,2.748,0,0,0,2.748,2.748H25.872A2.748,2.748,0,0,0,28.62,23.9V8.373A2.748,2.748,0,0,0,25.872,5.625Z\" transform=\"translate(234.556 677.609)\" fill=\"#fff\" stroke=\"#fff\" strokeWidth=\"1\"/>" +
    //     "<path id=\"Tracé_2\" data-name=\"Tracé 2\" d=\"M13.9,21.59a.844.844,0,0,0,0,1.183l.006.006a.864.864,0,0,0,.585.233.807.807,0,0,0,.591-.245l4.934-4.922a1.174,1.174,0,0,0,0-1.744l-5.054-5.042a.831.831,0,0,0-.591-.245.817.817,0,0,0-.591.245.833.833,0,0,0,0,1.183l3.895,3.835H4.282a.836.836,0,0,0,0,1.673H17.7Z\" transform=\"translate(235 676.828)\" fill=\"#fff\" stroke=\"#fff\" strokeWidth=\"1\"/>" +
    //     "</g></svg>"


    return (
        <div style={styles.container}>
            <div style={styles.containerItems}>
                <svg xmlns="http://www.w3.org/2000/svg" width="74.319" height="74.319" viewBox="0 0 74.319 74.319"

                    style={styles.userLogo}>
                    <path id="Icon_material-account-circle" data-name="Icon material-account-circle"
                        d="M40.16,3a37.16,37.16,0,1,0,37.16,37.16A37.173,37.173,0,0,0,40.16,3Zm0,11.148A11.148,11.148,0,1,1,29.012,25.3,11.133,11.133,0,0,1,40.16,14.148Zm0,52.767a26.757,26.757,0,0,1-22.3-11.965C17.975,47.554,32.728,43.5,40.16,43.5c7.395,0,22.184,4.05,22.3,11.445a26.757,26.757,0,0,1-22.3,11.965Z"
                        transform="translate(-3 -3)" fill="#0c5573" />
                </svg>

                <form>
                    <div style={styles.containerInput}>
                        <input type={"text"}
                            style={styles.textfieldLogin}
                            placeholder={"Identifiant"}
                            value={login}
                            onChange={(e) => handleChange(setLogin, e.target.value)}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="24.762"
                            viewBox="0 0 21.667 24.762"
                            style={styles.logoIdentifiant}>
                            <path id="Icon_awesome-user" data-name="Icon awesome-user"
                                d="M10.833,12.381A6.191,6.191,0,1,0,4.643,6.191,6.19,6.19,0,0,0,10.833,12.381Zm4.333,1.548h-.808a8.419,8.419,0,0,1-7.051,0H6.5a6.5,6.5,0,0,0-6.5,6.5v2.012a2.322,2.322,0,0,0,2.321,2.321H19.346a2.322,2.322,0,0,0,2.321-2.321V20.429A6.5,6.5,0,0,0,15.167,13.929Z"
                                fill="#0c5573" />
                        </svg>

                    </div>

                    <div style={styles.containerInput} id="login">
                        {
                            passwordHidden === true ?
                                <input type={"password"}
                                    style={styles.textfieldLogin}
                                    placeholder={"Mot de passe"}
                                    value={password}
                                    onChange={(e) => handleChange(setPassword, e.target.value)}

                                />
                                :
                                <input type={"text"}
                                    style={styles.textfieldLogin}
                                    placeholder={"Mot de passe"}
                                    value={password}
                                    onChange={(e) => handleChange(setPassword, e.target.value)}

                                />
                        }

                        <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="26" viewBox="0 0 21.667 26"
                            style={styles.logoIdentifiant}>
                            <path id="Icon_map-locksmith" data-name="Icon map-locksmith"
                                d="M24.27,11.553H23.1V6.878A6.63,6.63,0,0,0,16.939.72H11.8A6.516,6.516,0,0,0,5.767,6.878v4.676H4.472a1.162,1.162,0,0,0-.872.9v13.79c0,.324.505.478.872.478h19.8c.368,0,1-.154,1-.478V12.452a1.324,1.324,0,0,0-1-.9Zm-7.284,13H11.759l1.1-5.4a2.8,2.8,0,0,1-1.1-2.224,2.654,2.654,0,0,1,2.614-2.651,2.579,2.579,0,0,1,2.614,2.562,3.012,3.012,0,0,1-1.1,2.328Zm2.322-13H9.558V6.878a2.215,2.215,0,0,1,2.3-2.366h5.032a2.314,2.314,0,0,1,2.421,2.366Z"
                                transform="translate(-3.6 -0.72)" fill="#0c5573" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg" width="18.373" height="11.483"
                            viewBox="0 0 18.373 11.483"
                            style={styles.logoIdentifiantEye}
                            onClick={(e) => {
                                handleChangePasswordHidden(setPasswordHidden)
                            }}>
                            <path id="Icon_metro-eye" data-name="Icon metro-eye"
                                d="M11.757,7.712a10.289,10.289,0,0,0-9.186,5.741,10.22,10.22,0,0,0,18.373,0A10.289,10.289,0,0,0,11.757,7.712Zm4.529,3.045a8.736,8.736,0,0,1,2.681,2.7,8.736,8.736,0,0,1-2.681,2.7,8.418,8.418,0,0,1-9.059,0,8.736,8.736,0,0,1-2.681-2.7,8.736,8.736,0,0,1,2.681-2.7c.07-.045.141-.088.213-.131a4.593,4.593,0,1,0,8.633,0q.107.064.213.131Zm-4.529.974a1.722,1.722,0,1,1-1.722-1.722A1.722,1.722,0,0,1,11.757,11.731Z"
                                transform="translate(-2.571 -7.712)" fill="#0c5573" />
                        </svg>


                    </div>


                    <div>
                        <button style={styles.buttonLogin} id="connexion"
                            onClick={(e) => {
                                e.preventDefault()
                                Login()
                            }}
                            type={"submit"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="118.055" height="22.027"
                                viewBox="0 0 118.055 22.027">
                                <text id="Connexion" transform="translate(31.055 17.5)" fill="#fff" fontSize="17"
                                    fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700">
                                    <tspan x="0" y="0">Connexion</tspan>
                                </text>
                                <g id="Groupe_138" data-name="Groupe 138" transform="translate(-237.945 -682.734)">
                                    <path id="Tracé_1" data-name="Tracé 1"
                                        d="M25.872,5.625H9.146A2.748,2.748,0,0,0,6.4,8.373a.836.836,0,1,0,1.673,0A1.078,1.078,0,0,1,9.146,7.3H25.872a1.078,1.078,0,0,1,1.075,1.075V23.9a1.078,1.078,0,0,1-1.075,1.075H9.146A1.078,1.078,0,0,1,8.071,23.9a.836.836,0,1,0-1.673,0,2.748,2.748,0,0,0,2.748,2.748H25.872A2.748,2.748,0,0,0,28.62,23.9V8.373A2.748,2.748,0,0,0,25.872,5.625Z"
                                        transform="translate(234.556 677.609)" fill="#fff" stroke="#fff"
                                        strokeWidth="1" />
                                    <path id="Tracé_2" data-name="Tracé 2"
                                        d="M13.9,21.59a.844.844,0,0,0,0,1.183l.006.006a.864.864,0,0,0,.585.233.807.807,0,0,0,.591-.245l4.934-4.922a1.174,1.174,0,0,0,0-1.744l-5.054-5.042a.831.831,0,0,0-.591-.245.817.817,0,0,0-.591.245.833.833,0,0,0,0,1.183l3.895,3.835H4.282a.836.836,0,0,0,0,1.673H17.7Z"
                                        transform="translate(235 676.828)" fill="#fff" stroke="#fff" strokeWidth="1" />
                                </g>
                            </svg>
                        </button>
                    </div>
                    <p style={{cursor: "pointer",textDecoration:"underline"}}><a onClick={() => props.setShowForgotPasswordModal(true)} >J'ai oublié mes identifiants</a></p>
                    {

                        //href = "mailto:support@langloys.com?subject=j'ai oublié mes identifiants&body=Merci d'indiquer vos nom et prénom, nom de votre étude, pour que nous puissions vous transmettre vos identifiants de connexion."

                    } </form>

                

                {/* <div
                    style={styles.containerForgotPassword}
                >
                    <a
                        style={styles.forgotPassword}
                    >
                        Mot de passe oublié
                    </a>
                </div> */}

            </div>
        </div>
    )
        ;

    }
    
    ;


export default CardConnect;
