import React from "react";
import ImagePratiques from "../../assets/images/2024_PRATIQUES.png";
import VideoPratiques from "../../assets/videos/LE_PRATIQUE_720p.mp4";
import "./informations.css";
import LateralMenu from "../../layouts/lateralMenu/lateralMenu";

function Pratiques(props) {
  const handleClick = () => {
    window.open("https://editionslangloys.com/produit/le-pratique-volumes-1-et-2/", "_blank");
  };

  let newDate = new Date();
  let year = newDate.getFullYear();
  const currentDate = `${year}`;

  return (
    <div className="globalcontainer">
      <LateralMenu />
      <div className={"containerInformations"}>
        <div className={"cardInformations"}>
          <h1 className={"cardTitleInformations"}>Manuels PRATIQUE sur la taxe Vol I et II</h1>

          <div className={"pratiques-headerCard"}>
            <div className={"pratiques-imageCard1"}>
              <div className={"avis-textContent1"}>19 actes analysés et approfondis pour vous.</div>
              <img className={"imageExperts"} src={ImagePratiques} alt="pratiques 2024"></img>

              <div className={"avis-textContent1"}>
                Les Manuels PRATIQUE Vol I et II {currentDate} sont disponibles sur notre boutique en ligne
              </div>
              <button className={"callToAction"} onClick={handleClick}>
                Découvrir
              </button>
            </div>

            <div className={"pratiques-imageCard2"}>
              <video controls className="videoPratique">
                <source src={VideoPratiques} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pratiques;
